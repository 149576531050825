<template>

    <div class="content">
      <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Laboratorium</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered mb-3">
              <thead>
                <tr class="table-light">
                  <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Prioritas Pemeriksaan</h4>
                      <p v-if="dataDokter.appdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                      <p v-else>Non CITO</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Pasien Sedang Berpuasa</h4>
                      <p>{{dataDokter.appdl_fasting=='Y'?'Ya':'Tidak'}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Catatan Permintaan</h4>
                      <p>{{dataDokter.appdl_catatan||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Catatan Pemeriksaan Parsial</h4>
                      <p>{{dataDokter.appdl_pemeriksaan_parsial||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="req-list-resume mb-3">
              <div class="row g-3">
                <template v-for="(v,k) in row.appl_data">
                  <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                    <div class="card shadow-0 border">
                      <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                      </div>
                      <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.dubData||[])" >
                          <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)" :key="k1+'labdatas'">
                            <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                            <template v-for="v2,k2 in (v1.data||[])">
                              <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                <span v-if="v2.notes">, {{v2.notes}}</span>
                                <div v-if="isPoliAdj">
                                  <small class="" v-if="v2.tanggal">
                                  <b>Tangal Periksa : </b>{{v2.tanggal | moment("DD MMMM YYYY")}}</small>
                                  
                                  <b-form-checkbox
                                    :id="v2.id+'id'"
                                    v-model="v2.status"
                                    :name="v2.id+'id'"
                                    value="Y"
                                    unchecked-value="N"
                                  >
                                    Pemeriksaan selesai
                                  </b-form-checkbox>

                                </div>
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col-md-4 col-lg-3" v-if="row.appl_hasil_lainnya">
                  <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                    </div>
                    <div class="card-body py-2">
                        <div class="req-list">
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle">{{row.appl_hasil_lainnya||"-"}}</span>
                              <div v-if="isPoliAdj">
                                <small class="" v-if="row.appl_hasil_lainnya_date">
                                <b>Tangal Periksa : </b>{{row.appl_hasil_lainnya_date | moment("DD MMMM YYYY")}}</small>
                                
                                <b-form-checkbox
                                  :id="'lainnya'"
                                  v-model="row.appl_hasil_lainnya_status"
                                  :name="'lainnya'"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Pemeriksaan selesai
                                </b-form-checkbox>

                              </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <span v-if="!selectedLabInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
              </div>
              <div class="text-center mt-3" v-if="canEditLab">
                <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue"
                  data-toggle="modal" data-target="#labItemsChecklist"><i
                    class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Laboratorium</a>
              </div>
            </div>
            <div class="card border mb-0">
              <div @click="showPatient = !showPatient" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="card-title font-weight-semibold">Lihat Informasi Pasien</h6>
                  <i class="icon-chevron-down"></i>
                </div>
              </div>
              <div v-if="showPatient" id="infoPatient">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>No. Rekam Medis</h4>
                            <p>{{row.ap_code||"-"}} </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Nama Pasien</h4>
                            <p>{{row.ap_fullname||"-"}} </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Tgl. Lahir</h4>
                            <p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Tinggi Badan</h4>
                            <p>{{dataDokter.apod_ttv_height||"- "}}cm</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Berat Badan</h4>
                            <p>{{dataDokter.apod_ttv_weight||"- "}}cm</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Luas Permukaan Badan</h4>
                            <p v-if="row.ap_usia > 15">{{dataDokter.apod_ttv_bmi||"- "}}m<sup>2</sup></p>
                            <p v-else>{{dataDokter.apod_ttv_luas_permukaan_anak||"- "}}m<sup>2</sup></p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">UPLOAD HASIL LABORATORIUM</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-bordered table-striped table-sm patient-table">
                  <thead>
                    <tr>
                      <th>Hasil Pemeriksaan </th>
                      <th>Upload Hasil</th>
                      <th>Nilai Kritis</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (row.appl_hasil||[])" :key="k+'hasil'">
                      <td>
                        <b-form-textarea v-model="row.appl_hasil[k]['value']" type="text" name="name" class="form-control" />
                       <VValidate :name="'Hasil Pemeriksaan #'+(k+1)" v-model="row.appl_hasil[k]['value']"
                        :rules="{required:1, min:2}" />
                      </td>
                      <td>
                        <Uploader v-model="row.appl_hasil[k]['file']" isDocument type="docimage" />
                        <VValidate :name="'File #'+(k+1)" v-model="row.appl_hasil[k]['file']"
                            :rules="{required:1}" />
                      </td>
                      
                      <td>
                        <span class="d-flex mb-1">
                        <b-form-checkbox :value="true" :unchecked-value="false" v-model="row.appl_hasil[k]['isHaveKritis']" class="form-check-input-styled" name="radio-inline-left" /> Terdapat Nilai Kritis
                        </span>

                        <template v-if="row.appl_hasil[k]['isHaveKritis']">
                          <b-form-textarea v-model="row.appl_hasil[k]['nilai_kritis']" type="text" name="name"
                            class="form-control mb-1" />

                          <div class="input-group">
														<div class="input-group-prepend"><span class="input-group-text"><i
																	class="icon-alarm"></i></span></div>
														<vue-timepicker manual-input format="HH:mm" input-class="form-control"
															v-model="row.appl_hasil[k]['jam_keluar']">
														</vue-timepicker>
													</div>
                          
                          <VValidate class="d-block" :name="'Nilai Kritis #'+(k+1)"
														v-model="row.appl_hasil[k]['nilai_kritis']" :rules="{required:1}" />
                          <VValidate class="d-block" :name="'Jam Keluar Hasil #'+(k+1)"
														v-model="row.appl_hasil[k]['jam_keluar']" :rules="{required:1}" />
                        </template>
                      </td>
                      <td> 
                      <a href="javascript:;" class="list-icons-item"
                        @click="row.appl_hasil.splice(k,1)"
                        data-toggle="tooltip" data-placement="top" title="Delete"><i
                        class="icon-bin"></i></a>
                      </td>
                    </tr>
                    <tr v-if="!(row.appl_hasil||[]).length">
                        <td colspan="99" class="text-center">Tidak Ada Data</td>
                    </tr>
                  </tbody>
                </table>

                <div class="more_data">
                  <a href="javascript:;" @click="addNew"><i class="icon-plus-circle2"></i>
                    Tambah</a>
                </div>
              </div>

            </div>
          </div>
          <div class="card border shadow-0 mt-3" v-if="row.isEdit">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                <label for="addInfoTindakan">Keterangan Perubahan</label>
                <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                </b-textarea>

                    
                <VValidate 
                    :name="`Keterangan Perubahan`" 
                    v-model="row.arm_notes" 
                    :rules="{required: 1, min: 2, max:512}"
                />
                </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
      </b-form>
      </validation-observer>

      
      <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
        <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
            <li v-for="(v,k) in row.appl_data" :key="k" @click="changeTabLab(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
                :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
            </li>
          </ul>
        </div>
        <div class="modal-body p-0 mt-3">
          <div class="tab-content">
            <div v-for="(v,k) in row.appl_data" :key="k+'labform'"
              :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
              <template v-for="(v1,k1) in (v.dubData||[])">
                <div :key="k1+'sss'">
                  <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                    {{v1.sub}}</div>
                  <div class="row sc-row g-0">
                    <template v-for="(v2,k2) in (v1.data||[])">
                      <div class="col-md-4 col-lg-3" v-if="v2.id" :key="k2+'xdx'">
                        <div class="selection-control">
                        <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                        </b-form-checkbox>
                        <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="row ml-1 mt-2">
            <div class="col-md-8 col-lg-4">
              <div class="form-group row">
                <label>Lainnya</label>
                <b-form-input v-model="row.appl_hasil_lainnya" type="text" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedLabInput()">{{selectedLabInput()||"-"}}</span>
          <span v-else> - </span>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white">
          <div class="text-right">
            <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

// import GlobalVue from '@/libs/Global.vue'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
    // extends: GlobalVue,
    components:{ 
        VueTimepicker
    },
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        dataDokter: Object,
    },
    data(){
        return {
          showPatient: false,
          openLab: false,
          activeTabLab: 0,
        }
    },
    computed: {  
      canEditLab() {
        return this.rowReg.ar_is_penjunjang_lab == 'Y' && this.rowReg.ar_is_reg_penunjang != "Y"
      },
      isPoliAdj(){
        return this.$parent.isPoliAdj
      },
      selectedDoneLab(){
        let data = []
        for(let ik = 0; ik < (this.row.appl_data||[]).length; ik++){
          for(let jk = 0; jk < (this.row.appl_data[ik]['dubData']||[]).length; jk++){
            for(let kk = 0; kk < (this.row.appl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                if(this.row.appl_data[ik]['dubData'][jk]['data'][kk]['status'] == "Y"){
                  data.push(this.row.appl_data[ik]['dubData'][jk]['data'][kk]['id'])
                }
            }
          }
        }
        if(this.row.appl_hasil_lainnya_status == "Y"){
          data.push(99999)
        }
        return data
      },
    },
    methods: {
        changeTabLab(e) {
          this.activeTabLab = e
        },
        doOpenLab() {
          this.openLab = true
        },
        isShowHeadLab(i){
          let isData = 0
          for(let j = 0; j < (this.row.appl_data[i]['dubData']||[]).length; j++){
            for(let k = 0; k < (this.row.appl_data[i]['dubData'][j]['data']||[]).length; k++){  
              if(this.row.appl_data[i]['dubData'][j]['data'][k]['selected']){
                isData += 1
              }
            }
          }
          return isData
        },
        isShowHeadSubHeadLab(i,j){
          let isData = 0
          for(let k = 0; k < (this.row.appl_data[i]['dubData'][j]['data']||[]).length; k++){  
            if(this.row.appl_data[i]['dubData'][j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowLab(i,j,k){
          let isData = 0
          if(this.row.appl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        selectedLabInput(){
          let data = []
          for(let ik = 0; ik < (this.row.appl_data||[]).length; ik++){
            for(let jk = 0; jk < (this.row.appl_data[ik]['dubData']||[]).length; jk++){
              for(let kk = 0; kk < (this.row.appl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                  if(this.row.appl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                    data.push(this.row.appl_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
            }
          }
          if(this.row.appl_hasil_lainnya){
            data.push(this.row.appl_hasil_lainnya)
          }
          return data.join(", ")
        },

        addNew(){
            this.row.appl_hasil.push({
                value: null,
                dokter:null,
                
                isHaveKritis: false,
                nilai_kritis: null,
                jam_keluar: '',
                dilaporkan_oleh: null,
                dilaporkan_pada: null
            })
        },
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        back(){
          this.$router.back()
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save-lab'
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        toValidate(val){
            return {...val}
        },

        doSubmit(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   

            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                         setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                
                if(!(this.row.appl_hasil||[]).length){
                  return this.$swal({
                      icon: 'error',
                      title: 'Tidak ada Hasil Lab, Mohon Lengkapi Data Terlebih Dahulu'
                  }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }

                if(!(this.selectedDoneLab||[]).length){
                  return this.$swal({
                    icon: 'error',
                    title: 'Minimal isi 1 Tindakan Penunjang'
                  })
                }

                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data-lab'
                            if(this.isPoliAdj){
                              data.appl_selected_item = this.selectedDoneLab
                            }
                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.$parent.user.levelId == 1){
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uLab : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{})
                                        }   
                                        
                                        let dataSocket = {
                                          to : this.$parent.uLab,
                                          from : this.$parent.uLab,
                                          no_antrian: this.rowReg.ar_no_antrian_ppa_lab
                                        }
                                        this.$socket.emit('done_penunjang', dataSocket)
                                        
                                        let dataSocketAll = {
                                          to : 'ALLPJ',
                                          from : this.$parent.uLab,
                                          no_antrian: this.rowReg.ar_no_antrian_ppa_lab
                                        }
                                        this.$socket.emit('done_penunjang', dataSocketAll)
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },
    mounted() {
      document.body.classList.add('sidebar-xs')
      setTimeout(()=>{
          this.row.arm_notes = null
      },1500)
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}

</script>