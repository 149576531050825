<template>
  <div>
    <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
        <li class="nav-item" v-if="isShowLabTab"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Laboratorium</a></li>
        <li class="nav-item" v-if="isShowRadiologiTab"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Radiologi </a></li>
    </ul>
    <div class="tab-content card-body p-0 mt-3">
      <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane'">
        <div class="row" v-for="(vU,kU) in isParent.riwayatLab" :key="kU">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pemeriksaan Tanggal : {{vU.ar_reg_date | moment("DD MMMM YYYY") }}</h6>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="req-list-resume">
              <div class="row g-3">
                <template v-for="(v,k) in vU.applr_data">
                  <div class="col-md-6 col-lg-6" :key="k+'labData'" v-if="isShowHeadLabV3(vU,k)">
                    <div class="card shadow-0 border">
                      <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                      </div>
                      <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.dubData||[])" >
                          <div class="req-list" v-if="isShowHeadSubHeadLabV3(vU,k,k1)" :key="k1+'labdatas'">
                            <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                            <template v-for="v2,k2 in (v1.data||[])">
                              <div v-if="isShowLabV3(vU,k,k1,k2)" :key="k2+'labsubdatas'">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                <span v-if="v2.notes">, {{v2.notes}}</span>
                                <div>
                                  <small class="" v-if="v2.tanggal">
                                  <b>Tangal Periksa : </b>{{v2.tanggal | moment("DD MMMM YYYY")}}</small>
                                </div>
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col-md-6 col-lg-6" v-if="vU.applr_hasil_lainnya">
                  <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                    </div>
                    <div class="card-body py-2">
                        <div class="req-list">
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle font-weight-semibold">{{vU.applr_hasil_lainnya||"-"}}</span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <span v-if="!selectedLabInputV3(vU)" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-striped table-sm patient-table">
                    <thead>
                      <tr>
                        <th>Hasil Pemeriksaan </th>
                        <th> Hasil</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in (vU.applr_hasil||[])" :key="k+'hasil'">
                        <td>{{v.value}}<br/>
                        </td>
                        <td>
                            <div class="result_tab form-group mb-0">
                            <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                            </div>
                        </td>
                      </tr>
                      <tr v-if="!(vU.applr_hasil||[]).length">
                        <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane'">
        <div class="row" v-for="(vU,kU) in isParent.riwayatRadiologi" :key="kU">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pemeriksaan Tanggal : {{vU.ar_reg_date | moment("DD MMMM YYYY") }}</h6>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="req-list-resume">
              <div class="row g-3">
                <template v-for="(v,k) in vU.apprr_data">
                  <div class="col-md-6 col-lg-6" :key="k+'radioData'" v-if="isShowHeadRadiologiV3(vU,k)">
                    <div class="card shadow-0 border">
                      <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{
                          getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                      </div>
                      <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.data||[])">
                          <div v-if="isShowRadiologiV3(vU,k,k1)" :key="k1+'radiosubdatas'">
                            <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
                            <span v-if="v1.notes">, {{v1.notes}}</span>
                            <div>
                              <small class="" v-if="v1.tanggal">
                              <b>Tangal Periksa : </b>{{v1.tanggal | moment("DD MMMM YYYY")}}</small>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col-md-6 col-lg-6" v-if="vU.apprr_hasil_lainnya">
                  <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                    </div>
                    <div class="card-body py-2">
                        <div class="req-list">
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle font-weight-semibold">{{vU.apprr_hasil_lainnya||"-"}}</span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <span v-if="!selectedRadioInputV3(vU)" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
              </div>
            </div>
          </div>
          
          <div class="col-md-6">
            <div class="card">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-striped table-sm patient-table">
                    <thead>
                      <tr>
                        <th>Hasil Pemeriksaan </th>
                        <th> Hasil</th>
                        <th> Nama Dokter</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in (vU.apprr_hasil||[])" :key="k+'hasil'">
                        <td>{{v.value}}</td>
                        <td>
                            <div class="result_tab form-group mb-0">
                            <a class="btn_view" v-b-tooltip.hover.right title="Hasil Tindakan Radiologi" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                            
                            <a class="btn_view ml-1" v-b-tooltip.hover.right title="Hasil Bacaan Radiologi" href="javascript:;" @click="downloadReport(vU.apprr_arm_id,v)" ><i class="icon-file-download"></i></a>

                            </div>
                        </td>
                        <td>{{v.dokter||"-"}}</td>
                      </tr>
                      <tr v-if="!(vU.apprr_hasil||[]).length">
                        <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Config from '@/libs/Config'
const moment = require('moment')

export default{
  data(){
    return {
      activeTab : 1,
    }
  },
  computed: {    
    Config(){ return Config },
    isParent(){
      return this.$parent.$parent.$parent
    },
    isShowLabTab(){
      return (this.isParent.riwayatLab||[]).length
    },
    isShowRadiologiTab(){
      return (this.isParent.riwayatRadiologi||[]).length
    }
  },
  methods: {
    changeTab(tab){
      this.activeTab = tab
    },

    isShowHeadLabV3(dataInp,i){
      let isData = 0
      for(let j = 0; j < (dataInp.applr_data[i]['dubData']||[]).length; j++){
        for(let k = 0; k < (dataInp.applr_data[i]['dubData'][j]['data']||[]).length; k++){  
          if(dataInp.applr_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
        }
      }
      return isData
    },
    isShowHeadSubHeadLabV3(dataInp,i,j){
      let isData = 0
      for(let k = 0; k < (dataInp.applr_data[i]['dubData'][j]['data']||[]).length; k++){  
        if(dataInp.applr_data[i]['dubData'][j]['data'][k]['selected']){
          isData += 1
        }
      }
      return isData
    },
    isShowLabV3(dataInp,i,j,k){
      let isData = 0
      if(dataInp.applr_data[i]['dubData'][j]['data'][k]['selected']){
        isData += 1
      }
      return isData
    },
    
    selectedLabInputV3(dataInp){
      let data = []
      for(let ik = 0; ik < (dataInp.applr_data||[]).length; ik++){
        for(let jk = 0; jk < (dataInp.applr_data[ik]['dubData']||[]).length; jk++){
          for(let kk = 0; kk < (dataInp.applr_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
              if(dataInp.applr_data[ik]['dubData'][jk]['data'][kk]['selected']){
                data.push(dataInp.applr_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
          }
        }
      }
      if(dataInp.applr_hasil_lainnya){
        data.push(dataInp.applr_hasil_lainnya)
      }
      return data.join(", ")
    },


    isShowHeadRadiologiV3(dataInp,j){
      let isData = 0
      for(let k = 0; k < (dataInp.apprr_data[j]['data']||[]).length; k++){  
        if(dataInp.apprr_data[j]['data'][k]['selected']){
          isData += 1
        }
      }
      return isData
    },
    isShowRadiologiV3(dataInp,j,k){
      let isData = 0
      if(dataInp.apprr_data[j]['data'][k]['selected']){
        isData += 1
      }
      return isData
    },
    selectedRadioInputV3(dataInp){
      let data = []
      for(let i = 0; i < (dataInp.apprr_data||[]).length; i++){
        for(let j = 0; j < (dataInp.apprr_data[i]['data']||[]).length; j++){  
            if(dataInp.apprr_data[i]['data'][j]['selected']){
              data.push(dataInp.apprr_data[i]['data'][j]['text'])
            }
        }
      }
      if(dataInp.apprr_hasil_lainnya){
        data.push(dataInp.apprr_hasil_lainnya)
      }
      return data.join(", ")
    },
    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
  },
  mounted() {
    console.log(this.$parent.$parent.$parent)
  },
}
</script>