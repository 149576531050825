<template>
  <div class="content">
    <validation-observer ref="VFormLanjutan">
      <b-form @submit.prevent="doSubmitLanjutan">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 form-underlying">
                <div class="accordion no-slide-accordion" role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-0 bor_line_bottom" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'subjective')" variant="false"
                        class="text-left">
                        <div class="label_code mr-1">
                          <h2>S</h2>
                        </div>
                        <strong>Subjective</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2 p-0" v-model="cardKajianCollapse['subjective']" role="tabpanel">
                      <b-card-body>
                        <div class="wrap_line">
                          <div class="row">
                            <div class="col-md">
                              <div class="row">
                                <div class="col-md-7 col-lg-6">
                                  <div class="form-group">
                                    <label for="perKeluhan">Keluhan Utama <strong class="text-danger">*</strong>
                                    </label>
                                    <b-form-input v-model="row.aps_keluhan" :formatter="$parent.normalText" type="text"
                                      name="perKeluhan" id="perKeluhan" class="form-control"
                                      placeholder="Keluhan dari pasien" />
                                    <small class="text-info">*Mohon tuliskan informasi waktu mulai keluhan
                                      terjadi</small>
                                  </div>
                                  <VValidate name="Keluhan Utama" v-model="row.aps_keluhan"
                                    :rules="toValidate(mrValidation.aps_keluhan)" />
                                </div>
                                <div class="col-md-9 col-lg-6">
                                  <div class="form-group">
                                    <div>
                                      <label for="perRiwayat">Riwayat Penyakit</label>
                                      <b-form-textarea v-model="row.aps_anamnesa" :formatter="$parent.normalText"
                                        name="perRiwayat" id="perRiwayat" rows="1" class="form-control"
                                        placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
                                    </div>
                                    <VValidate name="Riwayat Penyakit" v-model="row.aps_anamnesa"
                                      :rules="toValidate(mrValidation.aps_anamnesa)" />
                                  </div>
                                </div>
                              </div>
                              <div class="row g-3">
                                <div class="col-lg-7">
                                  <div class="head_panel_red">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <h3>ALERGI </h3>
                                      <div>
                                        <b-form-radio-group @input="changeAlergi($event)" :options="Config.mr.yesNoOpt"
                                          v-model="row.aps_has_alergi" />
                                        <VValidate name="Alergi" v-model="row.aps_has_alergi"
                                          :rules="toValidate(mrValidation.aps_has_alergi)" />
                                      </div>
                                    </div>
                                  </div>
                                  <template v-if="row.aps_has_alergi == 'Y'">
                                    <table class="table table-sm table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="33%">Jenis</th>
                                          <th>Informasi Alergi</th>
                                          <th width="64"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in (row.aps_alergi||[])" :key="k+'aler'">
                                          <td>
                                            <v-select placeholder="Pilih Jenis" v-model="row.aps_alergi[k]['jenis']"
                                              :options="Config.mr.jenisAlergi" label="text" :clearable="true"
                                              :reduce="v=>v.value"></v-select>
                                            <VValidate :name="'Obat '+(k+1)" v-model="row.aps_alergi[k]['jenis']"
                                              :rules="{required:1}" />
                                          </td>
                                          <td>
                                            <vue-typeahead-bootstrap v-model="row.aps_alergi[k]['name']" :data="mAlergi"
                                              @input="searchAlergi(row.aps_alergi[k]['name'])"
                                              placeholder="Pilih Alergi" />
                                            <VValidate :name="'Obat '+(k+1)" v-model="row.aps_alergi[k]['name']"
                                              :rules="{required:1}" />
                                          </td>
                                          <td>
                                            <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip"
                                              title="Hapus"
                                              class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger">
                                              <i class="icon-trash"></i>
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="3" class="text-center">
                                            <a href="javascript:;" @click="addAlergi()"
                                              class="btn btn-sm alpha-info border-info">
                                              <i class="icon-plus2 mr-1"></i>Tambah </a>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </template>
                                  <template v-else>
                                    <table class="table table-sm table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="33%">Jenis</th>
                                          <th>Informasi Alergi</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <v-select placeholder="Pilih Jenis" disabled
                                              :options="Config.mr.jenisAlergi" label="text" :clearable="true"
                                              :reduce="v=>v.value"></v-select>
                                          </td>
                                          <td>
                                            <input disabled type="text" class="form-control"
                                              placeholder="cth. amoxicilin">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                </div>
                                <div class="col-lg-5">
                                  <div class="head_panel_blue">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <h3>OBAT RUTIN </h3>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.yesNoOpt"
                                          v-model="row.aps_has_obat_rutin" />
                                        <VValidate name="Alergi" v-model="row.aps_has_obat_rutin"
                                          :rules="toValidate(mrValidation.aps_has_obat_rutin)" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-2" v-if="row.aps_has_obat_rutin == 'Y'">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label>Keterangan </label>
                                        <b-form-textarea v-model="row.aps_keterangan_obat_rutin" rows="5" cols="3"
                                          class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                                      </div>
                                      <VValidate name="Keterangan" v-model="row.aps_keterangan_obat_rutin"
                                        :rules="toValidate(mrValidation.aps_keterangan_obat_rutin)" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-0 bor_line_bottom" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'objective')" variant="false"
                        class="text-left">
                        <div class="label_code mr-1">
                          <h2>O</h2>
                        </div>
                        <strong>Objective</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2 p-0" v-model="cardKajianCollapse['objective']" role="tabpanel">
                      <div class="wrap_line">
                        <div class="row">
                          <div class="col-md">
                            <div class="row">
                              <div class="col-md-10 col-lg-8">
                                <div class="form-group">
                                  <label>Objective <small class="txt_mandatory">*</small>
                                  </label>
                                  <b-form-input v-model="row.apo_desc" :formatter="$parent.normalText" type="text"
                                    name="name" class="form-control" />
                                  <VValidate :name="'Deskripsi'" v-model="row.apo_desc"
                                    :rules="toValidate(mrValidation.apo_desc)" />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="card mb-0">
                                  <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                                  </div>
                                  <div class="card-body p-3">
                                    <div class="row">
                                      <div class="col-md-6col-lg-4">
                                        <div class="form-group">
                                          <label>Tekanan Darah</label>
                                          <div class="input-group">
                                            <b-form-input :formatter="$parent.number"
                                              v-model="row.apo_ttv_tekanan_darah_min" type="text" name="name"
                                              class="form-control" placeholder="Systole" />
                                            <div class="input-group-append input-group-prepend">
                                              <span class="input-group-text">/</span>
                                            </div>
                                            <b-form-input :formatter="$parent.number" placeholder="Diastole"
                                              v-model="row.apo_ttv_tekanan_darah_max" type="text"
                                              class="form-control" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">mmHG</span>
                                            </div>
                                          </div>
                                          <VValidate name="Tekanan Darah Min" v-model="row.apo_ttv_tekanan_darah_min"
                                            :rules="toValidate(mrValidation.apo_ttv_tekanan_darah_min)" />
                                          <VValidate name="Tekanan Darah Max" v-model="row.apo_ttv_tekanan_darah_max"
                                            :rules="toValidate(mrValidation.apo_ttv_tekanan_darah_max)" />
                                        </div>
                                      </div>
                                      <div class="col-md-6 col-lg-5">
                                        <div class="form-group">
                                          <label>Nadi</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input :formatter="$parent.number" v-model="row.apo_ttv_nadi"
                                                  type="text" class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">x/mnt</span>
                                                </div>
                                                <div class="input-group-append">
                                                  <div style="width: 140px;">
                                                    <v-select placeholder="Pilih Label" v-model="row.apo_ttv_label"
                                                      :options="Config.mr.StatusRegular" label="text" :clearable="true"
                                                      :reduce="v=>v.value"></v-select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="Nadi" v-model="row.apo_ttv_nadi"
                                            :rules="toValidate(mrValidation.apo_ttv_nadi)" />
                                          <VValidate name="Label" v-model="row.apo_ttv_label"
                                            :rules="toValidate(mrValidation.apo_ttv_label)" />
                                        </div>
                                      </div>
                                      <div class="col-md-4 col-lg-3">
                                        <div class="form-group">
                                          <label>Gula Darah</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input :formatter="$parent.alphanum"
                                                  v-model="row.apo_ttv_gula_darah" type="text" class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">mg/dL</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="Gula Darah" v-model="row.apo_ttv_gula_darah"
                                            :rules="toValidate(mrValidation.apo_ttv_gula_darah)" />
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div class="form-group">
                                          <label>Pernafasan</label>
                                          <div class="input-group">
                                            <b-form-input :formatter="$parent.number" v-model="row.apo_ttv_pernafasan"
                                              type="text" class="form-control" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">x/mnt</span>
                                            </div>
                                          </div>
                                        </div>
                                        <VValidate name="Pernafasan" v-model="row.apo_ttv_pernafasan"
                                          :rules="toValidate(mrValidation.apo_ttv_pernafasan)" />
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <label>SPO2</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input :formatter="$parent.number" v-model="row.apo_ttv_spo2"
                                                  type="text" class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">%</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="SPO2" v-model="row.apo_ttv_spo2"
                                            :rules="toValidate(mrValidation.apo_ttv_spo2)" />
                                        </div>
                                      </div>
                                      <div class="col-md-3 col-lg-2">
                                        <div class="form-group">
                                          <label>Suhu</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input :formatter="$parent.number" v-model="row.apo_ttv_suhu"
                                                  type="text" class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">C</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="Suhu" v-model="row.apo_ttv_suhu"
                                            :rules="toValidate(mrValidation.apo_ttv_suhu)" />
                                        </div>
                                      </div>
                                      <div class="col-md-3 col-lg-2">
                                        <div class="form-group">
                                          <label>Berat Badan</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input @input="hitungBMI()" :formatter="$parent.number"
                                                  v-model="row.apo_ttv_weight" type="text" class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">kg</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="Berat Badan" v-model="row.apo_ttv_weight"
                                            :rules="toValidate(mrValidation.apo_ttv_weight)" />
                                        </div>
                                      </div>
                                      <div class="col-md-3 col-lg-2">
                                        <div class="form-group">
                                          <label>Tinggi</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input @input="hitungBMI()" :formatter="$parent.number"
                                                  v-model="row.apo_ttv_height" type="text" class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">cm</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="Tinggi Badan" v-model="row.apo_ttv_height"
                                            :rules="toValidate(mrValidation.apo_ttv_height)" />
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <label>Lingkar Kepala</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input :formatter="$parent.number"
                                                  v-model="row.apo_ttv_lingkar_kepala" type="text"
                                                  class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">cm</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="Lingkar Kepala" v-model="row.apo_ttv_lingkar_kepala"
                                            :rules="toValidate(mrValidation.apo_ttv_lingkar_kepala)" />
                                        </div>
                                      </div>
                                      <div class="col-md-3" v-if="row.ap_usia >= 1">
                                        <div class="form-group">
                                          <label>BMI</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <div class="input-group">
                                                <b-form-input disabled v-model="row.apo_ttv_bmi" type="text"
                                                  class="form-control" />
                                                <div class="input-group-append">
                                                  <span class="input-group-text">m2</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <VValidate name="BMI" v-model="row.apo_ttv_bmi"
                                            :rules="toValidate(mrValidation.apo_ttv_bmi)" />
                                        </div>
                                      </div>
                                      <div class="col-md-4 col-lg-3" v-if="row.ap_usia <= 15">
                                        <div class="form-group">
                                          <label>Luas Permukaan Tubuh Anak</label>
                                          <div class="input-group">
                                            <b-form-input :formatter="$parent.number"
                                              v-model="row.apo_ttv_luas_permukaan_anak" type="text"
                                              class="form-control" />
                                            <div class="input-group-append">
                                              <span class="input-group-text">m <sup>2</sup>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5 col-lg-3">
                                        <div class="form-group">
                                          <label>Kesadaran</label>
                                          <div class="form-row">
                                            <div class="col-md-12">
                                              <v-select placeholder="Pilih Kesadaran" v-model="row.apo_ttv_kesadaran"
                                                :options="mKesadaran" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                                <template slot="selected-option" slot-scope="option">
                                                  <span v-b-tooltip.hover :title="option.text">{{ option.text }}</span>
                                                </template>
                                                <template slot="option" slot-scope="option">
                                                  <span v-b-tooltip.hover :title="option.text">{{ option.text }}</span>
                                                </template>
                                              </v-select>
                                            </div>
                                          </div>
                                          <VValidate name="Kesadaran" v-model="row.apo_ttv_kesadaran"
                                            :rules="toValidate(mrValidation.apo_ttv_kesadaran)" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
                                <div class="card mb-0">
                                  <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Kajian TBC</h6>
                                  </div>
                                  <div class="card-body p-3">
                                    <div class="form-group">
                                      <label for="tbcCheck">Pasien Mengalami TBC?<strong
                                          class="text-danger">*</strong></label>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.yesNoOpt" v-model="row.apo_is_tbc" />
                                        <VValidate name="TBC" v-model="row.apo_is_tbc" :rules="{required: 1}" />
                                      </div>
                                    </div>
                                    <div id="tbcFormCheck" v-if="row.apo_is_tbc == 'Y'">
                                      <div class="row">
                                        <div class="col-md-4 col-lg-2">
                                          <div class="form-group">
                                            <label for="tbcUmum">Kondisi Umum <strong
                                                class="text-danger">*</strong></label>
                                            <v-select v-model="row.apo_tb_kondisi_umum" placeholder="Pilih Kondisi Umum"
                                              :options="Config.mr.configStatusKondisi" label="text" :clearable="true"
                                              :reduce="v=>v.value"></v-select>
                                          </div>
                                          <VValidate name="Kondisi Umum" v-model="row.apo_tb_kondisi_umum"
                                            :rules="{required: 1}" />
                                        </div>
                                        <div class="col-lg-8">
                                          <div class="form-group">
                                            <label for="">Glasgow Coma Scale (GSC) <strong
                                                class="text-danger">*</strong></label>
                                            <div class="form-row">
                                              <div class="col-md-12 mb-1">
                                                <div class="input-group">
                                                  <div class="input-group-prepend"><span
                                                      class="input-group-text">E</span>
                                                  </div>
                                                  <div class="form-control p-0 border-0">
                                                    <v-select v-model="row.apo_tb_gsc_e"
                                                      placeholder="Pilih Glasgow Coma Scale (GSC)E"
                                                      :options="Config.mr.configStatusGSCE" label="text"
                                                      :clearable="true" :reduce="v=>v.value">

                                                      <template slot="selected-option" slot-scope="option">
                                                        <span v-b-tooltip.hover.right :title="option.text">
                                                          {{option.text||"-"}}
                                                        </span>
                                                      </template>

                                                      <template slot="option" slot-scope="option">
                                                        <span v-b-tooltip.hover.bottom
                                                          :title="option.text">{{ option.text }}</span>
                                                      </template>
                                                    </v-select>
                                                  </div>
                                                </div>
                                                <VValidate name="E" v-model="row.apo_tb_gsc_e" :rules="{required: 1}" />
                                              </div>
                                              <div class="col-md-12 mb-1">
                                                <div class="input-group">
                                                  <div class="input-group-prepend"><span
                                                      class="input-group-text">V</span>
                                                  </div>
                                                  <div class="form-control p-0 border-0">
                                                    <v-select v-model="row.apo_tb_gsc_v"
                                                      placeholder="Pilih Glasgow Coma Scale (GSC)V"
                                                      :options="Config.mr.configStatusGSCV" label="text"
                                                      :clearable="true" :reduce="v=>v.value">

                                                      <template slot="selected-option" slot-scope="option">
                                                        <span v-b-tooltip.hover.right :title="option.text">
                                                          {{option.text||"-"}}
                                                        </span>
                                                      </template>

                                                      <template slot="option" slot-scope="option">
                                                        <span v-b-tooltip.hover.bottom
                                                          :title="option.text">{{ option.text }}</span>
                                                      </template>
                                                    </v-select>
                                                  </div>
                                                </div>
                                                <VValidate name="V" v-model="row.apo_tb_gsc_v" :rules="{required: 1}" />
                                              </div>
                                              <div class="col-md-12 mb-1">
                                                <div class="input-group">
                                                  <div class="input-group-prepend"><span
                                                      class="input-group-text">M</span>
                                                  </div>
                                                  <div class="form-control p-0 border-0">
                                                    <v-select v-model="row.apo_tb_gsc_m"
                                                      placeholder="Pilih Glasgow Coma Scale (GSC)M"
                                                      :options="Config.mr.configStatusGSCM" label="text"
                                                      :clearable="true" :reduce="v=>v.value">

                                                      <template slot="selected-option" slot-scope="option">
                                                        <span v-b-tooltip.hover.right :title="option.text">
                                                          {{option.text||"-"}}
                                                        </span>
                                                      </template>

                                                      <template slot="option" slot-scope="option">
                                                        <span v-b-tooltip.hover.bottom
                                                          :title="option.text">{{ option.text }}</span>
                                                      </template>
                                                    </v-select>
                                                  </div>
                                                </div>
                                                <VValidate name="M" v-model="row.apo_tb_gsc_m" :rules="{required: 1}" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="wrap_line">
                                        <h3>Kepala</h3>
                                        <div class="row">
                                          <div class="col-md-6 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="headKonjungtiva">Konjungtiva</label>
                                              <v-select v-model="row.apo_tb_kep_konjungtiva"
                                                placeholder="Pilih Konjungtiva"
                                                :options="Config.mr.configStatusKonjungtiva" label="text"
                                                :clearable="true" :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate name="Konjungtiva" v-model="row.apo_tb_kep_konjungtiva"
                                                :rules="{required: 1}" />
                                            </div>
                                          </div>
                                          <div class="col-md-6 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="Sianosis">Sianosis</label>

                                              <v-select v-model="row.apo_tb_kep_sianosis" placeholder="Pilih Sianosis"
                                                :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate name="Sianosis" v-model="row.apo_tb_kep_sianosis"
                                                :rules="{required: 1}" />

                                            </div>
                                          </div>
                                          <div class="col-md-6 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="Sklera">Sklera</label>
                                              <v-select v-model="row.apo_tb_kep_sklera" placeholder="Pilih Sklera"
                                                :options="Config.mr.configStatusSklera" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate name="Sklera" v-model="row.apo_tb_kep_sklera"
                                                :rules="{required: 1}" />
                                            </div>
                                          </div>
                                          <div class="col-md-6 col-lg-3">
                                            <div>
                                              <label for="Hidung">Hidung</label>
                                              <v-select v-model="row.apo_tb_kep_hidung" placeholder="Pilih Hidung"
                                                :options="Config.mr.configStatusHidung" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate name="Hidung" v-model="row.apo_tb_kep_hidung"
                                                :rules="{required: 1}" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="wrap_line">
                                        <h3>Thorax / Dada</h3>
                                        <div class="row">
                                          <div class="col-md-6 col-lg-3">
                                            <div>
                                              <label for="thoraxParu">Paru</label>
                                              <v-select v-model="row.apo_tb_trx_paru" placeholder="Pilih Dada"
                                                :options="Config.mr.configStatusParu" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <div class="mt-1" v-if="row.apo_tb_trx_paru == 'Lain-lain'">
                                                <input type="text" :formatter="normalText"
                                                  v-model="row.apo_tb_trx_paru_lainnya" class="form-control">

                                                <VValidate name="Paru Lainnya" v-model="row.apo_tb_trx_paru_lainnya"
                                                  :rules="{required: 1, min :3}" />
                                              </div>
                                            </div>
                                            <VValidate name="Paru" v-model="row.apo_tb_trx_paru"
                                              :rules="{required: 1}" />
                                          </div>

                                          <div class="col-md-6 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="thoraxRespirasi">Respirasi</label>
                                              <v-select v-model="row.apo_tb_trx_respirasi" placeholder="Pilih Respirasi"
                                                :options="Config.mr.configStatusRespirasi" label="text"
                                                :clearable="true" :reduce="v=>v.value">
                                              </v-select>
                                            </div>

                                            <VValidate name="Respirasi" v-model="row.apo_tb_trx_respirasi"
                                              :rules="{required: 1}" />
                                          </div>
                                          <div class="col-md-6 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="thoraxAlatBantuNafas">Alat Bantu Nafas</label>
                                              <v-select v-model="row.apo_tb_trx_alat_bantu_nafas"
                                                placeholder="Pilih Alat Bantu Nafas" :options="Config.mr.yesNoOptV2"
                                                label="text" :clearable="true" :reduce="v=>v.value">
                                              </v-select>
                                            </div>

                                            <VValidate name="Alat Bantu Nafas" v-model="row.apo_tb_trx_alat_bantu_nafas"
                                              :rules="{required: 1}" />
                                          </div>
                                          <div class="col-md-6 col-lg-3">
                                            <div>
                                              <label for="thoraxJantung">Jantung</label>

                                              <v-select v-model="row.apo_tb_trx_jantung" placeholder="Pilih Jantung"
                                                :options="Config.mr.configStatusJantung" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>

                                              <div class="mt-1" v-if="row.apo_tb_trx_jantung=='Lain-lain'">
                                                <input type="text" v-model="row.apo_tb_trx_jantung_lainnya"
                                                  class="form-control">

                                                <VValidate name="Jantung Lainnya"
                                                  v-model="row.apo_tb_trx_jantung_lainnya"
                                                  :rules="{required: 1, min: 3}" />
                                              </div>
                                            </div>

                                            <VValidate name="Jantung" v-model="row.apo_tb_trx_jantung"
                                              :rules="{required: 1}" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="wrap_line">
                                        <h3>Abdomen</h3>
                                        <div class="row">
                                          <div class="col-md-4 col-md-3 col-xl-2">
                                            <div class="form-group">
                                              <label for="abdNyeriTekanParu">Nyeri Tekan</label>
                                              <v-select v-model="row.apo_tb_abd_nyeri" placeholder="Pilih Nyeri"
                                                :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                            </div>
                                            <VValidate name="Nyeri" v-model="row.apo_tb_abd_nyeri"
                                              :rules="{required: 1}" />
                                          </div>
                                          <div class="col-md-4 col-md-3 col-xl-2">
                                            <div class="form-group">
                                              <label for="abdBisingUsus">Bising Usus</label>
                                              <v-select v-model="row.apo_tb_abd_bising_usus" placeholder="Pilih Nyeri"
                                                :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                            </div>
                                            <VValidate name="Bising Usus" v-model="row.apo_tb_abd_bising_usus"
                                              :rules="{required: 1}" />
                                          </div>
                                          <div class="col-md-8 col-lg-6">
                                            <div>
                                              <label for="abdKonAbdomen">Kondisi Abdomen</label>
                                              <div class="form-row">
                                                <div class="col-md-6">
                                                  <v-select v-model="row.apo_tb_abd_kondisi"
                                                    placeholder="Pilih Kondii Abdomen"
                                                    :options="Config.mr.configKondisiAbdomen" label="text"
                                                    :clearable="true" :reduce="v=>v.value">
                                                  </v-select>
                                                </div>
                                                <div class="col-md-6" v-if="row.apo_tb_abd_kondisi=='Lain-lain'">
                                                  <input type="text" v-model="row.apo_tb_abd_kondisi_lainnya"
                                                    class="form-control">

                                                  <VValidate name="Kondisi Lainnya"
                                                    v-model="row.apo_tb_abd_kondisi_lainnya"
                                                    :rules="{required: 1, min: 3}" />
                                                </div>

                                                <VValidate name="Kondisi Abdomen" v-model="row.apo_tb_abd_kondisi"
                                                  :rules="{required: 1}" />

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="wrap_line">
                                        <h3>Ekstremitas</h3>
                                        <div class="row">
                                          <div class="col-md-4 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="ekstremitasEdema">Edema</label>
                                              <v-select v-model="row.apo_tb_eks_edema" placeholder="Pilih Edema"
                                                :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>

                                              <VValidate name="Edema" v-model="row.apo_tb_eks_edema"
                                                :rules="{required: 1}" />
                                            </div>
                                          </div>
                                          <div class="col-md-4 col-lg-3 col-xl-2">
                                            <div>
                                              <label for="ekstremitasAkaral">Akaral</label>
                                              <v-select v-model="row.apo_tb_eks_akaral" placeholder="Pilih Akaral"
                                                :options="Config.mr.configAkaral" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>

                                              <VValidate name="Akaral" v-model="row.apo_tb_eks_akaral"
                                                :rules="{required: 1}" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-6">
                                          <div class="form-group">
                                            <label for="pemeriksaanTCM">Hasil Pemeriksaan TCM</label>
                                            <b-form-textarea v-model="row.apo_hasil_tcm" :formatter="normalText"
                                              name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                                              placeholder="Hasil Pemeriksaan TCM"></b-form-textarea>
                                            <VValidate name="Hasil Pemeriksaan TCM" v-model="row.apo_hasil_tcm"
                                              :rules="{required: 1, min: 3, max: 128}" />
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="form-group">
                                            <label for="pemeriksaanTCM">Hasil Pemeriksaan BTA</label>
                                            <b-form-textarea v-model="row.apo_hasil_bta" :formatter="normalText"
                                              name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                                              placeholder="Hasil Pemeriksaan BTA"></b-form-textarea>
                                            <VValidate name="Hasil Pemeriksaan BTA" v-model="row.apo_hasil_bta"
                                              :rules="{required: 1, min: 3, max: 128}" />
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="form-group">
                                            <label for="pemeriksaanTCM">Hasil Pemeriksaan Rontgen</label>
                                            <b-form-textarea v-model="row.apo_hasil_rontgen" :formatter="normalText"
                                              name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                                              placeholder="Hasil Pemeriksaan Rontgen"></b-form-textarea>
                                            <VValidate name="Hasil Pemeriksaan Rontgen" v-model="row.apo_hasil_rontgen"
                                              :rules="{required: 1, min: 3, max: 128}" />
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="form-group">
                                            <label for="pemeriksaanTCM">Hasil Pemeriksaan PA / Mantoux Test</label>
                                            <b-form-textarea v-model="row.apo_hasil_test" :formatter="normalText"
                                              name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                                              placeholder="Hasil Pemeriksaan PA / Mantoux Test"></b-form-textarea>
                                            <VValidate name="Hasil Pemeriksaan PA / Mantoux Test"
                                              v-model="row.apo_hasil_test" :rules="{required: 1, min: 3, max: 128}" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-0 bor_line_bottom" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'assessment')" variant="false"
                        class="text-left">
                        <div class="label_code mr-1">
                          <h2>A</h2>
                        </div>
                        <strong>Assessment</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2 p-0" v-model="cardKajianCollapse['assessment']" role="tabpanel">
                      <div class="wrap_line">
                        <div class="row">
                          <div class="col-md">
                            <ul class="nav nav-tabs nav-tabs-bottom">
                              <li class="nav-item" v-for="(v,k) in diagnosa" :key="k">
                                <a href="javascript:;" @click="changeTab(k+1)" data-toggle="tab"
                                  :data-target="'#diagTab'+(k+1)"
                                  :class="isActiveTab == (k+1) ? 'nav-link active' : 'nav-link'">{{v.text||"Diagnosa baru"}}
                                  <i @click="removeDiagnosa(v.apa_id,k)" class="icon-cross ml-2"></i>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a @click="addNewDiagnosa" href="javascript:;" class="nav-link">
                                  <i class="icon-plus2 mr-2"></i> Tambah Diagnosa </a>
                              </li>
                            </ul>
                            <div class="tab-content">
                              <div v-for="(v,k) in diagnosa" :key="k+'a'"
                                :class="isActiveTab == (k+1) ? 'tab-pane fade show active':'tab-pane fade'"
                                :id="'#diagTab'+(k+1)">
                                <div class="row g-3">
                                  <div class="col-md-8">
                                    <div>
                                      <label for="diagnosa">Pilih Diagnosa</label>
                                      <v-select placeholder="Pilih Diagnosa" class="diagnosa-section select-paging"
                                        v-model="diagnosa[k].apa_diagnosa_id" @input="selectPenyebab($event,k)"
                                        :options="getMasterSDKI(mSDKI,diagnosa,k)" label="text" :clearable="true"
                                        :reduce="v=>v.value">
                                        <template slot="selected-option">
                                          <span>{{ v.text || "-" }}</span>
                                        </template>
                                        <template #list-footer>
                                          <li class="list-paging" v-if="ceilData(v.totalRows/10)">
                                            <div class="d-flex align-items-center justify-content-between">
                                              <b-button @click="changePageSDKI(v.currentPage,k,'min','diagnosa')"
                                                size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                <i class="icon-arrow-left22 text-white"></i>
                                              </b-button>
                                              <small>Halaman {{v.currentPage}} dari {{ceilData(v.totalRows/10)}}</small>
                                              <b-button @click="changePageSDKI(v.currentPage,k,'plus','diagnosa')"
                                                size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                <i class="icon-arrow-right22 text-white"></i>
                                              </b-button>
                                            </div>
                                          </li>
                                        </template>
                                      </v-select>
                                      <VValidate :name="`Diagnosa #${k+1}`" v-model="diagnosa[k].apa_diagnosa_id"
                                        :rules="{required: 1}" />
                                    </div>
                                  </div>
                                  <div class="col-6" v-if="diagnosa[k].apa_diagnosa_id">
                                    <div class="card mb-0">
                                      <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Penyebab</h6>
                                      </div>
                                      <div class="card-body p-3">
                                        <b-form-checkbox-group
                                          v-if="(diagnosa[k].apa_config_sdki.msd_penyebab||[]).length"
                                          :id="'penyebab'+k" v-model="diagnosa[k].apa_penyebab"
                                          :options="diagnosa[k].apa_config_sdki.msd_penyebab" :name="'penyebab'+k"
                                          value-field="name" text-field="name"
                                          stacked
                                        />
                                        <span v-else> (Tidak Ada) </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-6" v-if="diagnosa[k].apa_diagnosa_id">
                                    <div class="card mb-0">
                                      <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                                      </div>
                                      <div class="card-body p-3">
                                        <div class="row gx-4">
                                          <div class="col-md-6">
                                            <div class="signsCheck">
                                              <b-form-checkbox-group
                                                v-if="(diagnosa[k].apa_config_sdki.msd_faktor_resiko||[]).length"
                                                v-model="diagnosa[k].apa_faktor_resiko"
                                                :options="diagnosa[k].apa_config_sdki.msd_faktor_resiko"
                                                :id="'reskio_faktor'+k" :name="'reskio_faktor'+k" value-field="name"
                                                text-field="name"></b-form-checkbox-group>
                                              <span v-else> (Tidak Ada) </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12" v-if="diagnosa[k].apa_diagnosa_id">
                                    <div class="card mb-0">
                                      <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                                      </div>
                                      <div class="card-body p-3">
                                        <div class="row gx-4">
                                          <div class="col-md-6">
                                            <div class="signsCheck">
                                              <h6 class="font-weight-semibold">Subjective</h6>
                                              <b-form-checkbox-group
                                                v-if="(diagnosa[k].apa_config_sdki.msd_gejala_mayor_subjektif||[]).length"
                                                v-model="diagnosa[k].apa_gejala_mayor_subjektif"
                                                :options="diagnosa[k].apa_config_sdki.msd_gejala_mayor_subjektif"
                                                :id="'mayor_sub'+k" :name="'mayor_sub'+k" value-field="name"
                                                text-field="name"
                                                stacked
                                              />
                                              <span v-else> (Tidak Ada) </span>
                                            </div>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="signsCheck">
                                              <h6 class="font-weight-semibold">Objective</h6>
                                              <b-form-checkbox-group
                                                v-if="(diagnosa[k].apa_config_sdki.msd_gejala_mayor_objektif||[]).length"
                                                v-model="diagnosa[k].apa_gejala_mayor_objektif"
                                                :options="diagnosa[k].apa_config_sdki.msd_gejala_mayor_objektif"
                                                :id="'mayor_obj'+k" :name="'mayor_obj'+k" value-field="name"
                                                text-field="name"
                                                stacked
                                              />
                                              <span v-else> (Tidak Ada) </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12" v-if="diagnosa[k].apa_diagnosa_id">
                                    <div class="card mb-0">
                                      <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                                      </div>
                                      <div class="card-body p-3">
                                        <div class="row gx-4">
                                          <div class="col-md-6">
                                            <div class="signsCheck">
                                              <h6 class="font-weight-semibold">Subjective</h6>
                                              <b-form-checkbox-group
                                                v-if="(diagnosa[k].apa_config_sdki.msd_gejala_minor_subjektif||[]).length"
                                                v-model="diagnosa[k].apa_gejala_minor_subjektif"
                                                :options="diagnosa[k].apa_config_sdki.msd_gejala_minor_subjektif"
                                                :id="'minor_sbj'+k" :name="'minor_sbj'+k" value-field="name"
                                                text-field="name"
                                                stacked
                                              />
                                              <span v-else> (Tidak Ada) </span>
                                            </div>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="signsCheck">
                                              <h6 class="font-weight-semibold">Objective</h6>
                                              <b-form-checkbox-group
                                                v-if="(diagnosa[k].apa_config_sdki.msd_gejala_minor_objektif||[]).length"
                                                v-model="diagnosa[k].apa_gejala_minor_objektif"
                                                :options="diagnosa[k].apa_config_sdki.msd_gejala_minor_objektif"
                                                :id="'minor_obj'+k" :name="'minor_obj'+k" value-field="name"
                                                text-field="name"
                                                stacked
                                              />
                                              <span v-else> (Tidak Ada) </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <template v-if="diagnosa[k].apa_diagnosa_id">
                                  <h3 class="mt-5">
                                    <span>Planning</span>
                                  </h3>
                                  <div class="row">
                                    <div class="col-md-auto">
                                      <div class="label_code">
                                        <h2>P</h2>
                                      </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                      <div class="card mb-0">
                                        <div class="card-header bg_head_panel">
                                          <h6 class="card-title font-weight-semibold">Intervensi Keperawatan</h6>
                                        </div>
                                        <div class="card-header">
                                          <ul class="nav nav-tabs nav-tabs-bottom mb-0">
                                            <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                                              <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab"
                                                :data-target="'#intvTab'+(k2+1)"
                                                :class="v.apa_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                                                {{ v2.text || "Intervensi Baru"}}
                                                <i @click="removeIntervensi(v2.app_id,k,k2)"
                                                  class="icon-cross ml-2"></i>
                                              </a>
                                            </li>
                                            <li class="nav-item">
                                              <a href="javascript:;"
                                                @click="addIntervensi(v.apa_diagnosa_id, v.apa_arm_id, v.apa_id, k)"
                                                class="nav-link">
                                                <i class="icon-plus2 mr-2"></i> Tambah Intervensi </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div class="tab-content">
                                          <div v-for="(v2,k2) in v.siki" :key="k2+'sik'"
                                            :class="v.apa_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'"
                                            :id="'#intvTab1'+(k2+1)">
                                            <div class="card-body">
                                              <div class="row">
                                                <div class="col-md-8">
                                                  <div>
                                                    <label for="diagnosa">Pilih Intervensi</label>
                                                    <v-select placeholder="Pilih Intervensi"
                                                      class="diagnosa-section select-paging"
                                                      v-model="diagnosa[k]['siki'][k2]['app_intervensi_id']"
                                                      :options="getMasterSIKI(diagnosa[k]['mSiki'],diagnosa[k]['siki'],k2) "
                                                      @input="selectedIntervensi($event,k,k2)" label="text"
                                                      :clearable="true" :reduce="v=>v.value">
                                                      <template slot="selected-option">
                                                        <span v-b-tooltip.hover.right placement='right'
                                                          :title="v2.text">{{ v2.text || "Intervensi Baru" }}</span>
                                                      </template>
                                                      <template slot="option" slot-scope="option">
                                                        <span v-b-tooltip.hover.right placement='right'
                                                          :title="option.text">{{ option.text }}</span>
                                                      </template>
                                                      <template #list-footer>
                                                        <li class="list-paging">
                                                          <div
                                                            class="d-flex align-items-center justify-content-between">
                                                            <b-button
                                                              @click="changePageSIKI(v2.currentPage,k,k2,'min','diagnosa','siki')"
                                                              size="sm" variant="transparent"
                                                              class="btn-icon bg-blue-300">
                                                              <i class="icon-arrow-left22 text-white"></i>
                                                            </b-button>
                                                            <small>Halaman {{v2.currentPage}} dari
                                                              {{ceilData(v2.totalRows/10)}}</small>
                                                            <b-button
                                                              @click="changePageSIKI(v2.currentPage,k,k2,'plus','diagnosa','siki')"
                                                              size="sm" variant="transparent"
                                                              class="btn-icon bg-blue-300">
                                                              <i class="icon-arrow-right22 text-white"></i>
                                                            </b-button>
                                                          </div>
                                                        </li>
                                                      </template>
                                                    </v-select>
                                                  </div>
                                                  <VValidate :name="`Intervensi Keperawatan #${k+1} #${k2+1}`"
                                                    v-model="diagnosa[k]['siki'][k2]['app_intervensi_id']"
                                                    :rules="{required: 1}" />
                                                </div>
                                              </div>
                                            </div>
                                            <table v-if="diagnosa[k]['siki'][k2]['app_intervensi_id']"
                                              class="table table-bordered table-striped table-hover">
                                              <thead>
                                                <tr>
                                                  <th class="table-primary" colspan="99">Tindakan</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th colspan="2" class="table-light">OBSERVASI</th>
                                                </tr>
                                                <tr v-for="(vo,ko) in diagnosa[k]['siki'][k2]['app_tindakan_observasi']"
                                                  :key="ko+'ko'">
                                                  <td width="128">
                                                    <div class="form-check">
                                                      <label class="form-check-label">
                                                        <b-form-checkbox value="Y" :unchecked-value="null"
                                                          v-model="diagnosa[k]['siki'][k2]['app_tindakan_observasi'][ko]['value']"
                                                          :name="'tinInt'+k2+ko" class="form-check-input-styled" />
                                                      </label>
                                                    </div>
                                                  </td>
                                                  <td>{{vo.name}}</td>
                                                </tr>
                                              </tbody>
                                              <tbody>
                                                <tr>
                                                  <th colspan="2" class="table-light">TERAPEUTIK</th>
                                                </tr>
                                                <tr
                                                  v-for="(vt,kt) in diagnosa[k]['siki'][k2]['app_tindakan_terapeutik']"
                                                  :key="kt+'kt'">
                                                  <td width="128">
                                                    <div class="form-check">
                                                      <label class="form-check-label">
                                                        <b-form-checkbox value="Y" :unchecked-value="null"
                                                          v-model="diagnosa[k]['siki'][k2]['app_tindakan_terapeutik'][kt]['value']"
                                                          :name="'tinTer'+kt" class="form-check-input-styled" />
                                                      </label>
                                                    </div>
                                                  </td>
                                                  <td>{{vt.name}}</td>
                                                </tr>
                                              </tbody>
                                              <tbody>
                                                <tr>
                                                  <th colspan="2" class="table-light">EDUKASI</th>
                                                </tr>
                                                <tr v-for="(ve,ke) in diagnosa[k]['siki'][k2]['app_tindakan_edukasi']"
                                                  :key="ke+'ke'">
                                                  <td width="128">
                                                    <div class="form-check">
                                                      <label class="form-check-label">
                                                        <b-form-checkbox value="Y" :unchecked-value="null"
                                                          v-model="diagnosa[k]['siki'][k2]['app_tindakan_edukasi'][ke]['value']"
                                                          :name="'tinEd'+ke" class="form-check-input-styled" />
                                                      </label>
                                                    </div>
                                                  </td>
                                                  <td>{{ve.name}}</td>
                                                </tr>
                                              </tbody>
                                              <tbody>
                                                <tr>
                                                  <th colspan="2" class="table-light">KOLABORASI</th>
                                                </tr>
                                                <tr
                                                  v-for="(vk,kk) in diagnosa[k]['siki'][k2]['app_tindakan_kolaborasi']"
                                                  :key="kk+'kk'">
                                                  <td width="128">
                                                    <div class="form-check">
                                                      <label class="form-check-label">
                                                        <b-form-checkbox value="Y" :unchecked-value="null"
                                                          v-model="diagnosa[k]['siki'][k2]['app_tindakan_kolaborasi'][kk]['value']"
                                                          :name="'tinKol'+kk" class="form-check-input-styled" />
                                                      </label>
                                                    </div>
                                                  </td>
                                                  <td>{{vk.name}}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div class="row ml-2 mt-2">
                                              <div class="form-group col-6">
                                                <label for="addInfoTindakan">Nama Perawat</label>
                                                <b-form-input v-model="diagnosa[k]['siki'][k2]['app_nama_perawat']"
                                                  :formatter="$parent.normalText" placeholder="Tulis Nama" />
                                              </div>
                                              <div class="form-group col-6">
                                                <label for="addInfoTindakan">Waktu Tindakan</label>
                                                <vue-timepicker manual-input
                                                  v-model="diagnosa[k]['siki'][k2]['app_tindakan_pada']" format="HH:mm"
                                                  input-class="form-control"></vue-timepicker>
                                              </div>
                                            </div>
                                            <div class="form-group mt-3 ml-3">
                                              <label for="addInfoTindakan">Tindakan Keperawatan</label>
                                              <b-textarea v-model="diagnosa[k]['siki'][k2]['app_info_tindakan']"
                                                :formatter="$parent.normalText" id="addInfoTindakan" rows="6"
                                                class="form-control" placeholder="Tindakan Keperawatan"></b-textarea>
                                              <VValidate :name="`Tindakan #${k+1} #${k2+1}`"
                                                v-model="diagnosa[k]['siki'][k2]['app_info_tindakan']"
                                                :rules="{min: 2}" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pengkajian Resiko Jatuh</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-auto">
                <div class="label_code" style="background-color:#d9ffdb;">
                  <h2>A</h2>
                </div>
              </div>
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group label="Cara Berjalan pasien (salah satu atau lebih) :">
                      <b-form-checkbox-group class="mt-2" id="checkbox-block-2" v-model="row.akp_pengkajian_A"
                        :options="Config.mr.keselamatanPasienA" @input="changeTindakan()" name="ca">
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <hr class="small_line">
            <div class="row">
              <div class="col-md-auto">
                <div class="label_code" style="background-color:#d9ffdb;">
                  <h2>B</h2>
                </div>
              </div>
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group label="">
                      <b-form-checkbox-group class="mt-2" id="checkbox-block-4" v-model="row.akp_pengkajian_B"
                        :options="Config.mr.keselamatanPasienB" @input="changeTindakan()" name="cb">
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-10 col-lg-7">
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                  <thead>
                    <tr>
                      <th>HASIL</th>
                      <th>Tindakan</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (row.akp_tindakan||[])" :key="k+'tindakan'">
                      <td v-if="k==0" :rowspan="(row.akp_tindakan||[]).length">{{hasil}}</td>
                      <td>
                        <span
                          v-if="(v == 'Pasang pita kuning'||v == 'edukasi') || hasil !== 'Beresiko Tinggi'">{{v}}</span>
                        <span v-else>
                          <b-form-input v-model="row.akp_tindakan[k]" :formatter="$parent.normalText" type="text"
                            name="name" class="form-control" />
                        </span>
                      </td>
                      <td>
                        <span v-if="(v == 'Pasang pita kuning'||v == 'edukasi') || hasil !== 'Beresiko Tinggi'"> -
                        </span>
                        <a v-else href="javascript:;" class="list-icons-item"
                          @click="(row.akp_tindakan||[]).splice(k,1)" data-toggle="tooltip" data-placement="top"
                          title="Delete">
                          <i class="icon-bin"></i>
                        </a>
                      </td>
                    </tr>
                    <tr v-if="hasil == 'Beresiko Tinggi'">
                      <td colspan="99" class="text-center">
                        <a href="javascript:;" @click="(row.akp_tindakan||[]).push('')"
                          class="btn btn-outline-primary btn-sm font-weight-semibold">Tambah Tindakan</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card border shadow-0 mt-3" v-if="row.isEdit">
              <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="addInfoTindakan">Keterangan Perubahan</label>
                  <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control"
                    placeholder="Keterangan Perubahan"></b-textarea>
                  <VValidate :name="`Keterangan Perubahan`" v-model="row.arm_notes"
                    :rules="{required: 1, min: 2, max:512}" />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  // import GlobalVue from '@/libs/Global.vue'
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  export default {
    // extends: GlobalVue,
    props: {
      row: Object,
      rowReg: Object,
      mrValidation: Object,
      Config: Object,
      diagnosa: Array,
      mSDKI: Array,
      mSIKI: Array,
      mKesadaran: Array,
      mAlergi: Array,
      resepDokter: Array,
    },
    data() {
      return {
        isActiveTab: null,
        
        cardKajianCollapse: {
          subjective: true,
          objective: false,
          assessment: false,
        }
      }
    },
    components: {
      VueTimepicker
    },
    computed: {
      hasil() {
        let text = ''
        if (!(this.row.akp_pengkajian_A || []).length && !(this.row.akp_pengkajian_B || []).length) {
          text = 'Tidak Berisiko'
        } else if ((this.row.akp_pengkajian_A || []).length && (this.row.akp_pengkajian_B || []).length) {
          text = 'Beresiko Tinggi'
        } else {
          text = 'Berisiko Sedang'
        }
        return text
      }
    },
    methods: {
      toggleCardCollapse(e, collapseParamKey){
        e.stopPropagation()
        this.cardKajianCollapse[collapseParamKey] = !this.cardKajianCollapse[collapseParamKey]
        if(this.cardKajianCollapse[collapseParamKey]){
          const mainTarget = e.target.classList.contains('card-header') ? e.target : e.target.parentElement
          const pageHeaderOpen = document.querySelector('.page-header-sticky.page-header-sticky-open')
          const addCount = pageHeaderOpen ? pageHeaderOpen.offsetHeight : 0
          let count, addHeight = document.querySelector('.navbar').offsetHeight + addCount + 20
          setTimeout(() => {
            count = Math.round(mainTarget.parentElement.getBoundingClientRect().top + document.documentElement.scrollTop) - addHeight
            $("html, body").scrollTop(count)
          }, 125)
        }
      },
      searchAlergi: _.debounce(function(e) {
        let data = {
          name: e,
          type: 'get-alergi'
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(res => {
          this.$parent.mAlergi = res.data.data
        })
      }, 100),
      ceilData(value) {
        return Math.ceil(value)
      },
      back() {
        this.$router.back()
      },
      hitungBMI(){
        if (this.row.apo_ttv_weight && this.row.apo_ttv_height) {
          this.row.apo_ttv_bmi = this.row.apo_ttv_weight / ((this.row.apo_ttv_height/100)*(this.row.apo_ttv_height/100))
          this.row.apo_ttv_bmi = this.row.apo_ttv_bmi.toFixed(2)
        }
      },
      changeTindakan() {
        if (!(this.row.akp_pengkajian_A || []).length && !(this.row.akp_pengkajian_B || []).length) {
          this.row.akp_tindakan = ['Tidak Ada Tindakan']
        } else if ((this.row.akp_pengkajian_A || []).length && (this.row.akp_pengkajian_B || []).length) {
          this.row.akp_tindakan = ['Pasang pita kuning', 'edukasi']
        } else {
          this.row.akp_tindakan = ['Edukasi']
        }
      },
      getMasterSDKI(master, diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.apa_diagnosa_id
        })
        return _.filter(master, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },
      selectPenyebab(e, k) {
        if (e) {
          let index = this.mSDKI.findIndex(x => x.value == e)
          if (index !== -1) {
            this.diagnosa[k]['apa_config_sdki'] = this.mSDKI[index]
            this.diagnosa[k]['text'] = this.mSDKI[index]['text']
            for (let i = 0; i < (this.diagnosa[k]['siki'] || []).length; i++) {
              this.removeIntervensi(this.diagnosa[k]['siki'][i].app_id, k, i)
            }
          }
        }
      },
      toValidate(val) {
        return {
          ...val
        }
      },
      changeAlergi(e) {
        if (!(this.row.aps_alergi || []).length) {
          this.addAlergi()
        }
      },
      addAlergi() {
        this.row.aps_alergi.push({
          jenis: '',
          name: '',
        })
        this.addMasterAlergi()
      },
      addMasterAlergi() {
        for (let i = 0; i < (this.row.aps_alergi || []).length; i++) {
          let newData = this.row.aps_alergi[i]
          if ((newData.name || "").length && newData.jenis == "Obat") {
            let dataPost = {
              type: 'auto-save-riwayat',
              name: newData.name
            }
            Gen.apiRest("/do/" + 'RekamMedis', {
              data: dataPost
            }, "POST")
          }
        }
      },
      removeAlergi(k) {
        this.row.aps_alergi.splice(k, 1)
      },
      autoSave: _.debounce(function(data) {
        data.type = 'auto-save-lanjutan'
        data.akp_hasil = this.hasil
        if (!data.isEdit) {
          Gen.apiRest("/do/" + 'RekamMedis', {
            data: data
          }, "POST")
        }
      }, 1000),
      autoSaveDiagnosaLanjutan: _.debounce(function(data) {
        let dataPost = {
          type: 'auto-save-diagnosa-lanjutan',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest("/do/" + 'RekamMedis', {
            data: dataPost
          }, "POST")
        }
      }, 1000),
      getLabelSDKI(data = null) {
        if (data) {
          let index = this.mSDKI.findIndex(x => x.value == data)
          if (index !== -1) {
            data = this.mSDKI[index]['text']
          }
        }
        return data
      },
      getMasterSIKI(master, diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.app_intervensi_id
        })
        return _.filter(master, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },
      changeTab(k) {
        this.isActiveTab = k
      },
      addNewDiagnosa() {
        this.$parent.loadingOverlay = true
        let data = {
          apa_arm_id: this.row.arm_id,
          type: 'add-diagnosa-lanjutan'
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(res => {
          let resp = res.data
          this.diagnosa.push(resp.row)
          this.isActiveTab = this.diagnosa.length
          this.$parent.loadingOverlay = false
        })
      },
      removeDiagnosa(id, k) {
        this.$parent.loadingOverlay = true
        let data = {
          id: id,
          type: 'remove-diagnosa-lanjutan'
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(() => {
          this.diagnosa.splice(k, 1)
          this.isActiveTab = this.diagnosa.length
          this.$parent.loadingOverlay = false
        })
      },
      changeTabSIKI(k2, k) {
        this.diagnosa[k].apa_last_tab_siki = k2
        this.autoSave(this.row)
      },
      getLabelSIKI(data, id) {
        let text = ""
        if (id) {
          let index = data.findIndex(x => x.value == id)
          if (index !== -1) {
            text = data[index]['text']
          }
        }
        return text
      },
      selectedIntervensi(e, k, k2) {
        let index = this.diagnosa[k]['mSiki'].findIndex(x => x.value == e)
        if (index !== -1) {
          this.diagnosa[k]['siki'][k2]['app_tindakan_observasi'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_observasi']
          this.diagnosa[k]['siki'][k2]['app_tindakan_terapeutik'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_terapeutik']
          this.diagnosa[k]['siki'][k2]['app_tindakan_edukasi'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_edukasi']
          this.diagnosa[k]['siki'][k2]['app_tindakan_kolaborasi'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_kolab']
          this.diagnosa[k]['siki'][k2]['text'] = this.diagnosa[k]['mSiki'][index]['text']
        }
      },
      addIntervensi(diagnosa, arm_id, apa_id, k) {
        this.$parent.loadingOverlay = true
        let data = {
          app_diagnosa_id: diagnosa,
          app_apa_id: apa_id,
          app_arm_id: arm_id,
          app_tindakan_observasi: [],
          app_tindakan_terapeutik: [],
          app_tindakan_edukasi: [],
          app_tindakan_kolaborasi: [],
          app_data: [],
          app_tindakan_tambahan: [],
          type: 'add-intervensi-lanjutan'
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(res => {
          let resp = res.data
          this.diagnosa[k]['siki'].push(resp.row)
          this.diagnosa[k]['apa_last_tab_siki'] = resp.countBySDKI
          this.diagnosa[k]['mSiki'] = resp.mSiki
          this.$parent.loadingOverlay = false
        })
      },
      removeIntervensi(id, k, k2) {
        this.$parent.loadingOverlay = true
        let data = {
          id: id,
          type: 'remove-intervensi-lanjutan'
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(() => {
          this.diagnosa[k]['siki'].splice(k2, 1)
          this.diagnosa[k]['apa_last_tab_siki'] = this.diagnosa[k]['siki'].length
          this.$parent.loadingOverlay = false
        })
      },
      doSubmitLanjutan() {
        if (this.row.arm_is_active == 'N') {
          return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          })
        }
        this.$refs['VFormLanjutan'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }
                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }
          if (!(this.diagnosa || []).length) {
            return this.$swal({
              icon: 'error',
              title: 'Minimal harus ada 1 diagnosa'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }
                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }
          let invalSiki = 0
          let invalSikiTab = []
          for (let i = 0; i < (this.diagnosa || []).length; i++) {
            if (!(this.diagnosa[i]['siki'] || []).length) {
              invalSiki += 1
              invalSikiTab.push(i + 1)
            }
          }
          if (invalSiki) {
            return this.$swal({
              icon: 'error',
              title: `SIKI Pada Tab Ke ${invalSikiTab.join(', ')} Kosong`
            })
          }
          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.row
                data.type = 'submit-data-lanjutan'
                data.diagnosa = this.diagnosa
                data.resepDokter = this.resepDokter
                
                this.$parent.loadingOverlay = true
                Gen.apiRest("/do/" + 'RekamMedis', {
                  data: data
                }, "POST").then(res => {
                  this.$parent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.addMasterAlergi()
                    if (result.value) {
                      if (this.$parent.user.levelId == 1) {
                        this.$router.push({
                          name: 'RekamMedis',
                          params: {
                            pageSlug: this.row.arm_pasien_id
                          },
                          query: {
                            regId: this.row.arm_ar_id,
                            byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uPerawat : null
                          }
                        }).catch(() => {})
                      } else {
                        this.$router.push({
                          name: 'RekamMedis',
                          params: {
                            pageSlug: this.row.arm_pasien_id
                          },
                          query: {
                            regId: this.row.arm_ar_id
                          }
                        }).catch(() => {})
                      }

                      if(this.rowReg.ar_mpo_id == 1){
                        this.$socket.emit('refresh_data', {to : this.$parent.uDokterFisio})
                      }else{
                        this.$socket.emit('refresh_data', {to : this.$parent.uDokter})
                      }
                    }
                  })
                }).catch(err => {
                  this.$parent.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.$parent.doSetAlertForm(err)
                })
                // 934?regId=1367
              }
            })
          }
        })
      },
      changePageSDKI(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }
        let data = {
          type: 'select-paging-sdki',
          page: page,
          search: this[col][k]['search']
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(res => {
          this.mSDKI = res.data.data
        })
      },
      filterSDKI: _.debounce(function(e, k) {
        if (e) {
          this.diagnosa[k]['search'] = e
          Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
              type: 'select-paging-sdki',
              search: e
            }
          }, 'POST').then(res => {
            this.mSDKI = res.data.data
            this.diagnosa[k]['currentPage'] = 1
            this.diagnosa[k]['totalRows'] = res.data.totalRows
          })
        }
      }, 10),
      changePageSIKI(page, k, k2, act, col, diagnosa) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k][diagnosa][k2]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k][diagnosa][k2]['totalRows'] / 10)) {
            page += 1
            this[col][k][diagnosa][k2]['currentPage'] += 1
          }
        }
        let data = {
          type: 'select-paging-siki',
          page: page,
          diagnosa_id: this[col][k]['apa_diagnosa_id'],
          search: this[col][k]['search']
        }
        Gen.apiRest("/do/" + 'RekamMedis', {
          data: data
        }, "POST").then(res => {
          this.mSIKI = res.data.data
        })
      },
      filterSIKI: _.debounce(function(e, k, k2) {
        if (e) {
          this.diagnosa[k]['siki'][k2]['search'] = e
          Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
              type: 'select-paging-siki',
              search: e,
              diagnosa_id: this['diagnosa'][k]['apa_diagnosa_id']
            }
          }, 'POST').then(res => {
            this.mSIKI = res.data.data
            this.diagnosa[k]['siki'][k2]['currentPage'] = 1
            this.diagnosa[k]['siki'][k2]['totalRows'] = res.data.totalRows
          })
        }
      }, 10),
    },
    mounted() {
      document.body.classList.add('sidebar-xs')
      setTimeout(() => {
        if ((this.diagnosa || []).length) {
          this.isActiveTab = (this.diagnosa || []).length
        }
        this.row.arm_notes = null
      }, 1500)
    },
    watch: {
      row: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },
      diagnosa: {
        handler(v) {
          this.autoSaveDiagnosaLanjutan(v)
        },
        deep: true
      },
    }
  }
</script>