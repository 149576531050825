<template>
  <div>
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover patient-table">
        <thead>
          <tr>
            <th>No. Reg</th>
            <th>Tanggal Reg</th>
            <th>Nama Dokter</th>
            <th>Diagnosa Primer</th>
            <th>Cara Bayar</th>
            <th>Kajian Awal</th>
            <th>Riwayat RM</th>
            <th>Dokumen Persetujuan Ranap</th>
            <th>Edukasi Terintegrasi</th>
            <th>Dicharge Planning</th>
            <th>Hais</th>
          </tr>
        </thead>
        <template v-if="(listDataRanap.data||[]).length">
          <template v-for="(v,k) in (listDataRanap.data||[])" >
            <tbody :class="v.isHead? 'isHead': ''" :key="k">
              <tr>
                <td>{{ v.aranr_reg_code }}</td>
                <td>{{ v.aranr_reg_date | moment("LL") }}</td>
                <td>
                  <span>
                    <strong>{{ v.bu_full_name }}</strong>
                  </span>                          
                </td>
                <td>{{ v.diagnosa_primer || '-' }}</td>
                <td>{{ v.mcp_name }}</td>
                <td>
                  <a href="javascript:;" @click="openKajianRanap(v)" class="btn btn-icon alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Kajian">
                    <i class="icon-file-eye"></i>
                  </a>
                </td>
                <td>
                  <a href="javascript:;" @click="openRiwayatRMRanap(v)" class="btn btn-icon alpha-teal border-teal text-teal-800" v-b-tooltip.hover title="Lihat Riwayat">
                    <i class="icon-file-eye"></i>
                  </a>
                </td>
                <td>
                  <a href="javascript:;" @click="openDokumenPersetujuan(v)" class="btn btn-icon alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Dokumen">
                    <i class="icon-file-eye"></i>
                  </a>
                </td>
                <td>
                  <a href="javascript:;" @click="openEdukasiTerintegrasi(v)" class="btn btn-icon alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Lihat Edukasi Terintegrasi">
                    <i class="icon-file-eye"></i>
                  </a>
                </td>
                <td>
                  <a href="javascript:;" @click="openDischargePlanning(v)" class="btn btn-icon alpha-orange border-orange text-orange-800" v-b-tooltip.hover title="Lihat Discharge Planning">
                    <i class="icon-file-eye"></i>
                  </a>
                </td>
                <td>
                  <a href="javascript:;" @click="openHais(v)" class="btn btn-icon alpha-info border-info text-info-800" v-b-tooltip.hover title="Lihat Hais">
                    <i class="icon-file-eye"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </template>
        <tbody v-else-if="!(listDataRanap.data||[]).length">
          <tr>
            <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
              Data Tidak Ditemukan
            </th>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </th>
          </tr>
        </tbody>                
      </table>
    </div>

    
    <!-- Modal Ranap -->
    <b-modal v-model="modal.kajianAwal" :title="'Kajian Awal Rawat Inap'" size="xl" ok-only ok-title="Tutup">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-model="tabKajianAwal">
        <KajianAwal :kajianData="kajianData" :tab.sync="tabKajianAwal" />

        <KajianPersalinan :kajianData="kajianPersalinanData"  />
      </b-tabs>
    </b-modal>

    <b-modal v-model="modal.edukasiTerintegrasi" title="Edukasi Terintegrasi" size="xl" ok-only ok-title="Tutup">
      <edukasi-terintegrasi :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.dischargePlanning" title="Discharge Planning" size="xl" ok-only ok-title="Tutup">
      <discharge-planning :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.hais" title="Riwayat Hais" size="xl" ok-only ok-title="Tutup">
      <hais :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.dokumenPersetujuan" title="Dokumen Persetujuan" size="xl" ok-only ok-title="Tutup">
      <dokumen-persetujuan-ranap :reg-id.sync="dataRanap.regId" />
    </b-modal>

    <b-modal v-model="modal.riwayatRM" title="Riwayat Rekam Medis" size="xl" ok-only ok-title="Tutup">
      <riwayat-rm-ranap :reg-id.sync="dataRanap.regId" :row-data="dataRanap" />
    </b-modal>
    <!-- END Modal Ranap -->

  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

import Hais from '@/components/Ranap/Hais.vue' // n
import KajianAwal from '@/components/Ranap/KajianAwal.vue'
import KajianPersalinan from '@/components/Ranap/KajianPersalinan.vue'
import EdukasiTerintegrasi from '@/components/Ranap/EdukasiTerintegrasi.vue' // n
import DischargePlanning from '@/components/Ranap/DischargePlanning.vue' // n
import DokumenPersetujuanRanap from '@/components/Ranap/DokumenPersetujuanRanap.vue' // n
import { default as RiwayatRmRanap } from '@/components/Ranap/RiwayatRM.vue' // n

export default{
  extends: GlobalVue,
  mounted() {
    this.getData()
  },
  data() {
    return {
      dataRanap: {},
      modal: {
        kajianAwal: false,
        edukasiTerintegrasi: false,
        hais: false,
        dokumenPersetujuan: false,
      },
      tabKajianAwal: 0,
      kajianData: {},
      kajianPersalinanData: {},
      listDataRanap: {}
    }
  },
  props:{
    pasienId : String
  },
  components: {
    Hais,KajianAwal,KajianPersalinan,EdukasiTerintegrasi,DischargePlanning,DokumenPersetujuanRanap,RiwayatRmRanap
  },
  methods: {
    getData(){
      this.loadingOverlay = true
      let data = {
        type : "data-ranap-by-pasien",
        id: this.pasienId
      }
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: data
      },
      "POST"
      ).then(res => {
        _.forEach(res.data, (v,k)=>{
          
        console.log(k,v)
          this.$set(this, k, v)
        })
        this.loadingOverlay = false
      })
    },
    // Ranap Methods
    openRiwayatRMRanap(data){
      this.$set(this, 'dataRanap', { ...data, regId: data.aranr_id })
      this.$set(this.modal, 'riwayatRM', true)
    },
    openDokumenPersetujuan(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_aranres_id })
      this.$set(this.modal, 'dokumenPersetujuan', true)
    },
    openHais(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_id })
      this.$set(this.modal, 'hais', true)
    },
    openDischargePlanning(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_id })
      this.$set(this.modal, 'dischargePlanning', true)
    },
    openEdukasiTerintegrasi(data){
      this.$set(this, 'dataRanap', { regId: data.aranr_id }) // ET = Edukasi Terintegrasi
      this.$set(this.modal, 'edukasiTerintegrasi', true)
    },
    openKajianRanap(data){
      this.getKajianPersalinanData(data.aranr_id)

      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian",
            regId: data.aranr_id
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Kajian awal Tidak Ditemukan'
          })
        }

        this.$set(this.modal, 'kajianAwal', true)
        this.kajianData = resp.data
      })
    },
    getKajianPersalinanData(regId){
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-persalinan",
            regId: regId
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Kajian persalinan Tidak Ditemukan'
          })
        }
        this.kajianPersalinanData = resp.data
      })
    },
    // -- 
  },
}
</script>