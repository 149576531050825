<template>
<b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
  <b-tab title="Monitoring">
    <div class="row p-3" v-if="loading.monitoring">
      <div class="col-md-12">
        <div class="d-flex justify-content-center align-items-center text-primary">
          <b-spinner class="mr-2 mb-0" small></b-spinner>
          <h6 class="font-weight-semibold mb-0">Loading...</h6>
        </div>
      </div>
    </div>

    <template v-else>
      <span v-if="isCritical">
        <intensive :reg-id="regId" />
      </span>

      <span v-else>
        <span v-if="isObsgyn">
          <MEOWS :reg-id="regId" />
        </span>
        <span v-else>
          <PEWS v-if="showPEWS" :reg-id="regId" />
          <EWS v-else-if="showEWS" :reg-id="regId" />
        </span>
      </span>
    </template>
  </b-tab>

  <b-tab title="CPPT">
    <div class="row p-3" v-if="loading.cppt">
      <div class="col-md-12">
        <div class="d-flex justify-content-center align-items-center text-primary">
          <b-spinner class="mr-2 mb-0" small></b-spinner>
          <h6 class="font-weight-semibold mb-0">Loading...</h6>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="table-responsive mb-0">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Asesmen</th>
              <th>Instruksi</th>
              <th>Verifikasi</th>
            </tr>
          </thead>
          <template v-for="(v,k) in (listCPPT||[])">
            <tbody v-if="v.arancp_type == 'PERAWAT'" :key="k">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{v.data.arancpp_tanggal | moment("DD MMMM YYYY")}},
                        {{v.data.arancpp_jam}}</div>
                      <small class="text-secondary">Pelapor: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                    </div>
                    <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                      <a href="javascript:;" @click="singleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                        <i class="icon-file-check2"></i>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>S</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpp_subjektif||"-"}}</pre>
                    </div>
                  </div>
                </td>
                <td class="align-top" rowspan="4">-</td>
                <td class="align-top" rowspan="4">
                  <template v-if="v.arancp_is_draft == 'Y'">
                    <span class="badge badge-warning">Draft</span>
                  </template>
                  <template v-else>
                    <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                    <div v-else>
                      <span class="badge badge-success">Sudah Diverifikasi</span><br />
                      <span>
                        Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                      </span>
                    </div>

                  </template>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>O</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpp_objektif||"-"}}</pre>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>A</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpp_assesment||"-"}}</pre>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>P</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpp_planning||"-"}}</pre>
                      <template v-if="v.data.arancpp_is_resep == 'Y'">
                        <div class="font-weight-semibold">Resep</div>
                        <ul class="mb-0 pl-3">
                          <li v-for="(v1,k1) in (v.resep||[])" :key="k1">
                            {{v1.arancpak_nama||"-"}} - {{v1.arancpak_jumlah||0}} - {{v1.arancpak_satuan}} -
                            <span v-if="v1.arancpak_frekuensi == 99999">
                              {{v1.arancpak_frekuensi_lainnya||"-"}}
                            </span>
                            <span v-else>{{v1.mdo_name||"-"}}</span>
                          </li>
                        </ul>
                        <span v-if="!(v.resep||[]).length"></span>
                        <div class="font-weight-semibold">Catatan</div>
                        <span>{{v.data.arancpp_keterangan||"-"}}</span>
                      </template>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="v.arancp_type == 'SBAR'" :key="k">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{v.data.arancps_tanggal | moment("DD MMMM YYYY")}},
                        {{v.data.arancps_jam}}</div>
                      <small class="text-secondary">Pelapor: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                    </div>
                    <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                      <a href="javascript:;" @click="singleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                        <i class="icon-file-check2"></i>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>S</h2>
                      </div>
                    </div>
                    <div class="col">
                      <p>
                        Melaporkan Pasien ke DPJP <br />
                        Pasien dengan diagnosa medis <span class="font-weight-semibold">{{v.data.arancps_diagnosa_medis||"-"}}</span>, keluhan utama saat ini <span class="font-weight-semibold">{{v.data.arancps_keluhan_utama||"-"}}</span>. Pasien mempunyai riwayat penyakit: <span class="font-weight-semibold">{{v.data.arancps_riwayat_penyakit||"-"}}</span>.
                      </p>
                    </div>
                  </div>
                </td>
                <td class="align-top" rowspan="4">
                  <div class="mb-2" v-for="(vA,kA) in (v.advis||[])" :key="kA">
                    <div class="font-weight-semibold">Advis {{kA+1}}: </div>
                    <pre class="pre-input">{{vA.arancsa_advis_text||"-"}}</pre>

                    <p class="text-danger-800">Perintah Dokter telah dilakukan penulisan, pembacaan perintah ulang dan konfirmasi ke {{vA.nama_dokter||"-"}} oleh <span class="font-weight-semibold">
                        {{v.data.arancps_nama_petugas||"-"}}</span>, Pada tanggal <span class="font-weight-semibold">
                        {{v.data.arancps_tanggal | moment('DD MMMM YYYY')}}
                      </span> jam <span class="font-weight-semibold">{{v.data.arancps_jam}} WIB</span></p>

                    <a href="javascript:;" v-if="isHaveTindakLanjut(vA)" @click="toDetailSBARAdvis(vA)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                  </div>

                </td>
                <td class="align-top" rowspan="4">
                  <template v-if="v.arancp_is_draft == 'Y'">
                    <span class="badge badge-warning">Draft</span>
                  </template>
                  <template v-else>
                    <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                    <div v-else>
                      <span class="badge badge-success">Sudah Diverifikasi</span><br />
                      <span>
                        Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                      </span>
                    </div>

                  </template>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>B</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <template v-if="(v.data.arancps_riwayat_alergi||[]).length">
                          <span>Pasien mempunyai riwayat alergi:</span>
                          <ul class="mb-0 pl-3">
                            <li v-for="(vAl,kAl) in (v.data.arancps_riwayat_alergi||[])" :key="kAl">
                              ({{vAl.jenis||"-"}}) {{vAl.name}}
                            </li>
                          </ul>
                        </template>
                        dengan keluhan saat ini <span class="font-weight-semibold">{{v.data.arancps_keluhan_saat_ini||"-"}}</span>, pengobatan yang sudah dilakukan <span class="font-weight-semibold">{{v.data.arancps_pengobatan_yang_dilakukan||"-"}}</span>, riwayat pembedahan <span class="font-weight-semibold">{{v.data.arancps_riwayat_pembedahan||"-"}}</span> riwayat tindakan invasif <span class="font-weight-semibold">

                          <ol class="mb-0" style="margin-left: -35px;" v-if="(v.data.arancps_tindakan_invasif||[]).length">
                            <li v-for="(v,k) in (v.data.arancps_tindakan_invasif||[])" :key="k" class="mt-1 ml-3">
                              {{v}}
                            </li>
                          </ol>
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>A</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <div class="mb-2">
                          <!--
                              <div>{{v.data.arancps_nilai_nyeri}} | 

                                <template v-if="(row.ap_usia > 6)">
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="v.data.arancps_nilai_nyeri == 0">Tidak Ada Nyeri</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 1 && v.data.arancps_nilai_nyeri <= 3">Sedikit Nyeri</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 4 && v.data.arancps_nilai_nyeri <= 5">Agak Mengganggu</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 6 && v.data.arancps_nilai_nyeri <= 7">Mengganggu Aktivitas</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 8 && v.data.arancps_nilai_nyeri <= 9">Sangat Mengganggu</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 10">Tak Tertahankan</span>
                                </template>

                                <template v-if="(row.ap_usia <= 6)">
                                    <template v-if="(row.ap_usia > 1)">
                                        <span v-if="v.data.arancps_nilai_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                        <span v-else-if="v.data.arancps_nilai_nyeri >= 1 && v.data.arancps_nilai_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                        <span v-else-if="v.data.arancps_nilai_nyeri >= 4 && v.data.arancps_nilai_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    </template>

                                    <template v-if="(row.ap_usia <= 1)">
                                        <span v-if="v.data.arancps_nilai_nyeri < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                        <span v-else-if="v.data.arancps_nilai_nyeri >= 3 && v.data.arancps_nilai_nyeri < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                    </template>
                                </template>
                              </div>,
                              -->
                          Tanda tanda vital sebagai berikut:
                          <ul class="mb-0 pl-3">
                            <li v-if="v.data.arancps_ttv_tekanan_darah_min && v.data.arancps_ttv_tekanan_darah_max">Tekanan Darah:
                              <span>
                                {{v.data.arancps_ttv_tekanan_darah_min||"-"}}/
                                {{v.data.arancps_ttv_tekanan_darah_max||"-"}} mmHG
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_nadi && v.data.arancps_ttv_label">Nadi:
                              <span>
                                {{v.data.arancps_ttv_nadi||"-"}} x/mnt - {{v.data.arancps_ttv_label||"-"}}
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_gula_darah">Gula Darah:
                              <span>
                                {{v.data.arancps_ttv_gula_darah||"-"}} mg/dL
                              </span>
                            </li>
                            <li v-if="v.data.arancps_ttv_pernafasan">Pernafasan:
                              <span>
                                {{v.data.arancps_ttv_pernafasan||"-"}} x/mnt
                              </span>
                            </li>
                            <li v-if="v.data.arancps_ttv_spo2">SPO2:
                              <span>
                                {{v.data.arancps_ttv_spo2||"-"}}%
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_suhu">Suhu:
                              <span>
                                {{v.data.arancps_ttv_suhu||"-"}}C
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_weight">Berat Badan
                              <span>
                                {{v.data.arancps_ttv_weight||"-"}} kg
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_height">Tinggi:
                              <span>
                                {{v.data.arancps_ttv_height||"-"}} cm
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_lingkar_kepala">Lingkar Kepala:
                              <span>
                                {{v.data.arancps_ttv_lingkar_kepala||"-"}} cm
                              </span>
                            </li>

                            <li v-if="v.data.arancps_ttv_luas_permukaan_anak">Lingkar Dada:
                              <span>
                                {{v.data.arancps_ttv_luas_permukaan_anak||"-"}} CM<sup>2</sup>
                              </span>
                            </li>

                            <li>Kesadaran:
                              <span>{{v.data.arancps_ttv_kesadaran}} |
                                <span v-if="v.data.arancps_ttv_kesadaran >= 14"> Composmentis</span>
                                <span v-else-if="v.data.arancps_ttv_kesadaran >= 12 && v.data.arancps_ttv_kesadaran <= 13">Apatis</span>
                                <span v-else-if="v.data.arancps_ttv_kesadaran >= 10 && v.data.arancps_ttv_kesadaran <= 11">Delirium</span>
                                <span v-else-if="v.data.arancps_ttv_kesadaran >= 7 && v.data.arancps_ttv_kesadaran <= 9">Somonolen</span>
                                <span v-else-if="v.data.arancps_ttv_kesadaran >= 5 && v.data.arancps_ttv_kesadaran <= 6">Sopor</span>
                                <span v-else-if="v.data.arancps_ttv_kesadaran >= 4 && v.data.arancps_ttv_kesadaran <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>

                                <span class="ml-2" style="margin-right:20px;">E:
                                  {{v.data.arancps_ttv_kesadaran_e||0}}
                                </span>
                                <span style="margin-right:20px;">M:
                                  {{v.data.arancps_ttv_kesadaran_m||0}}
                                </span>
                                <span style="margin-right:20px;">V:
                                  {{v.data.arancps_ttv_kesadaran_v||0}}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div class="mb-2" v-if="(v.data.arancps_hasil_lab||[]).length">
                          <span class="font-weight-semibold">Tindakan Lab</span>
                          <ol class="mb-0 pl-3">
                            <li v-for="(vL,kL) in (v.data.arancps_hasil_lab||[])" :key="kL">
                              {{vL.value||"-"}}
                            </li>
                          </ol>
                        </div>
                        <div class="mb-2" v-if="(v.data.arancps_hasil_radiologi||[]).length">
                          <span class="font-weight-semibold">Tindakan Radiologi</span>
                          <ol class="mb-0 pl-3">
                            <li v-for="(vR,kR) in (v.data.arancps_hasil_radiologi||[])" :key="kR">
                              {{vR.value||"-"}}
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>R</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <span class="font-weight-semibold">Usulan kolaborasi</span>
                        <ul class="mb-0 pl-3">
                          <li v-for="(vk,kk) in (v.data.arancps_usulan_kolaborasi||[])" :key="kk+'Usulan'">
                            {{vk||"-"}}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="v.arancp_type == 'DOKTER'" :key="k">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{v.data.arancpd_tanggal | moment("DD MMMM YYYY")}},
                        {{v.data.arancpd_jam}}</div>
                      <small class="text-secondary">Pelapor: {{getUserInput(v.arancp_type)||v.ppas}} - {{v.bu_full_name||"-"}}</small>
                    </div>
                    <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                      <a href="javascript:;" @click="singleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                        <i class="icon-file-check2"></i>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>S</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpd_subjektif||"-"}}</pre>
                    </div>
                  </div>
                </td>
                <td class="align-top" rowspan="4">-</td>
                <td class="align-top" rowspan="4">
                  <template v-if="v.arancp_is_draft == 'Y'">
                    <span class="badge badge-warning">Draft</span>
                  </template>
                  <template v-else>
                    <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                    <div v-else>
                      <span class="badge badge-success">Sudah Diverifikasi</span><br />
                      <span>
                        Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                      </span>
                    </div>

                  </template>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>O</h2>
                      </div>
                    </div>
                    <div class="col">
                      <pre class="pre-input">{{v.data.arancpd_objektif||"-"}}</pre>
                      <p><span class="font-weight-semibold">Status Lokalis:</span> <br />
                        <ul v-if="(v.data.arancpd_status_lokalis||[]).length">
                          <li v-for="(vSl,kSl) in (v.data.arancpd_status_lokalis||[])" :key="kSl">
                            Terdapat {{(vSl.data||[]).length}} titik yang ditandai pada bagian {{defineAnat(vSl.type)}} pasien.
                          </li>
                        </ul>
                        <span v-else> Tidak ada pemeriksaan </span>
                        <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>A</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <div>
                          <div class="font-weight-semibold">Diagnosis:</div>
                          <ul class="pl-3 mb-0" v-if="(v.diagnosa||[]).length">
                            <li v-for="(v1,k1) in (v.diagnosa||[])" :key="k1">
                              {{v1.mi10_name||"-"}}
                              <span class="font-weight-semibold">[{{v1.mi10_code||"-"}}]</span>
                            </li>
                          </ul>
                        </div>
                        <p>Diagnosa Lainnya:
                          <pre class="pre-input">{{v.data.arancpd_diagnosa_text||"-"}}</pre>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>P</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <p>{{v.data.arancpd_perencanaan||"-"}}</p>
                        <p>
                          <span class="font-weight-semibold">Tindak Lanjut: </span> {{checkTindakLanjut(v.data)}}
                          <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="v.arancp_type == 'PPARADIO' || v.arancp_type == 'PPALAB' || v.arancp_type == 'PPAFARMASI' || v.arancp_type == 'PPAFISIO'" :key="k">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{v.data.arancppa_tanggal | moment("DD MMMM YYYY")}},
                        {{v.data.arancppa_jam}}</div>
                      <small class="text-secondary">Pelapor: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                    </div>
                    <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                      <a href="javascript:;" @click="singleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                        <i class="icon-file-check2"></i>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>S</h2>
                      </div>
                    </div>
                    <div class="col">
                      <p>{{v.data.arancppa_subjektif||"-"}}</p>
                    </div>
                  </div>
                </td>
                <td class="align-top" rowspan="4">-</td>
                <td class="align-top" rowspan="4">
                  <template v-if="v.arancp_is_draft == 'Y'">
                    <span class="badge badge-warning">Draft</span>
                  </template>
                  <template v-else>
                    <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                    <div v-else>
                      <span class="badge badge-success">Sudah Diverifikasi</span><br />
                      <span>
                        Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                      </span>
                    </div>

                  </template>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>O</h2>
                      </div>
                    </div>
                    <div class="col">
                      <p>{{v.data.arancppa_objektif||"-"}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>A</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <p>{{v.data.arancppa_assesmen||"-"}}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>P</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <p>{{v.data.arancppa_planning||"-"}}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="v.arancp_type == 'DIETISIEN'" :key="k">
              <tr class="table-info">
                <td colspan="4">
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-semibold mr-2">{{v.data.arancpdi_tanggal | moment("DD MMMM YYYY")}},
                        {{v.data.arancpdi_jam}}</div>
                      <small class="text-secondary">Pelapor: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                    </div>
                    <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                      <a href="javascript:;" @click="singleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                        <i class="icon-file-check2"></i>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>A</h2>
                      </div>
                    </div>
                    <div class="col">
                      <p>
                        <span class="font-weight-semibold">BB: </span>
                        <span v-if="v.data.arancpdi_bb">{{v.data.arancpdi_bb}}kg,</span>
                        <span v-else> - ,</span>
                        <span class="font-weight-semibold">TB: </span>
                        <span v-if="v.data.arancpdi_tb">{{v.data.arancpdi_tb}}cm,</span>
                        <span v-else> - ,</span>
                        <span class="font-weight-semibold">BBI: </span>
                        <span v-if="v.data.arancpdi_bbi">{{v.data.arancpdi_bbi}}kg,</span>
                        <span v-else> - ,</span>
                        <span class="font-weight-semibold">BBI%: </span>
                        <span v-if="v.data.arancpdi_bbi100">{{v.data.arancpdi_bbi100}}%,</span>
                        <span v-else> - ,</span>
                        <span class="font-weight-semibold">IMT: </span>
                        <span v-if="v.data.arancpdi_imt">{{v.data.arancpdi_imt}}kg/m<sup>2</sup>,</span>
                        <span v-else> - </span>
                      </p>
                      <p><span class="font-weight-semibold">Status Gizi: </span>{{v.data.arancpdi_status_gizi}}</p>
                      <p>

                        <span class="font-weight-semibold">Gangguan Fisik/Klinis: </span>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.arancpdi_fisik_klinis)||"-"}}

                        <ul class="mb-0 pl-3" v-if="v.data.arancpdi_fisik_klinis == 'Y'">
                          <li><span class="font-weight-semibold">Diare:</span>
                            <span v-if="v.data.arancpdi_is_diare == 'Y'"> {{v.data.arancpdi_is_diare_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                          <li><span class="font-weight-semibold">Gangguan Menelan: </span>
                            <span v-if="v.data.arancpdi_is_lainnya == 'Y'"> {{v.data.arancpdi_is_lainnya_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                          <li><span class="font-weight-semibold">Muntah: </span>
                            <span v-if="v.data.arancpdi_is_muntah == 'Y'"> {{v.data.arancpdi_is_muntah_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                          <li><span class="font-weight-semibold">Konstipasi: </span>
                            <span v-if="v.data.arancpdi_is_konstipasi == 'Y'"> {{v.data.arancpdi_is_konstipasi_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                          <li><span class="font-weight-semibold">Mual: </span>
                            <span v-if="v.data.arancpdi_is_mual == 'Y'"> {{v.data.arancpdi_is_mual_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                          <li><span class="font-weight-semibold">Gangguan Mengunyah: </span>
                            <span v-if="v.data.arancpdi_is_gangguan_mengunyah == 'Y'"> {{v.data.arancpdi_is_gangguan_mengunyah_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                          <li><span class="font-weight-semibold">Lainnya: </span>
                            <span v-if="v.data.arancpdi_is_lainnya == 'Y'"> {{v.data.arancpdi_is_lainnya_text||"-"}}</span>
                            <span v-else> - </span>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </td>
                <td class="align-top" rowspan="5">-</td>
                <td class="align-top" rowspan="5">
                  <template v-if="v.arancp_is_draft == 'Y'">
                    <span class="badge badge-warning">Draft</span>
                  </template>
                  <template v-else>
                    <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                    <div v-else>
                      <span class="badge badge-success">Sudah Diverifikasi</span><br />
                      <span>
                        Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                      </span>
                    </div>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>D</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <div class="font-weight-semibold">Diagnosis:</div>
                        <ul class="pl-3 mb-0" v-if="(v.data.arancpdi_diagnosis||[]).length">
                          <li v-for="(v,k) in (v.data.arancpdi_diagnosis||[])" :key="k">
                            {{v.id ? v.name : v.text}}
                          </li>
                        </ul>
                        <span v-else>Tidak ada data</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>I</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <div>
                          <div class="font-weight-semibold">Intervensi:</div>
                          <ul class="pl-3 mb-0" v-if="(v.data.arancpdi_intervensi||[]).length">
                            <li v-for="(v,k) in (v.data.arancpdi_intervensi||[])" :key="k">
                              {{v.id ? v.name : v.text}}
                            </li>
                          </ul>
                          <span v-else>Tidak ada data</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>M</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <p>{{v.data.arancpdi_monitoring||"-"}}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-top">
                  <div class="form-row">
                    <div class="col-auto">
                      <div class="label_code label_code_sm m-0">
                        <h2>E</h2>
                      </div>
                    </div>
                    <div class="col">
                      <div>
                        <p>{{v.data.arancpdi_evaluasi||"-"}}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

          </template>
          <template v-if="!(listCPPT||[]).length">
            <tbody>
              <tr class="table-info">
                <td colspan="99" class="text-center">Tidak ada Data CPPT</td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>
    </template>
  </b-tab>

  <b-tab title="Riwayat Resep">
    <div class="row p-3" v-if="loading.riwayatResep">
      <div class="col-md-12">
        <div class="d-flex justify-content-center align-items-center text-primary">
          <b-spinner class="mr-2 mb-0" small></b-spinner>
          <h6 class="font-weight-semibold mb-0">Loading...</h6>
        </div>
      </div>
    </div>

    <template v-else>
      <table class="table table-striped table-sm  table-bordered">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Nama</th>
            <th>Jumlah</th>
            <th>Frekuensi</th>
            <th>Keterangan</th>
            <th>Status</th>
            <th>PPA/Nama</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody v-for="(v,k) in (dataPermintaanResep||[])" :key="k">
          <tr>
            <th colspan="8" class="table-info">
              <em v-if="v.arantfar_submit_date">{{v.arantfar_submit_date | moment("DD MMMM YYYY, HH:mm")}}</em>
              <em v-else>{{v.arantfar_created_date | moment("DD MMMM YYYY, HH:mm")}}</em>

              <span v-if="v.arantfar_is_draft == 'Y'" class="ml-2 badge badge-warning">Draft</span>
            </th>
          </tr>
          <tr v-for="(v1,k1) in (v.data||[])" :key="k+k1">
            <td colspan="2">
              <strong>{{getConfigDynamic(Config.mr.configResep,v1.arantfarr_jenis)||"-"}}</strong>
              {{v1.arantfarr_nama||"-"}}</td>
            <td>{{v1.arantfarr_jumlah||"-"}}</td>
            <td>{{v1.mdo_name||"-"}}</td>
            <td>{{v1.arantfarr_satuan||"-"}}</td>
            <td>
              <span v-if="v.arantfar_is_draft == 'Y'" class="badge badge-warning">Draft</span>
              <template v-else>
                <template v-if="v1.arantfarr_is_stop == 'Y'">
                  <span v-b-tooltip.hover :title="v1.arantfarr_is_stop_reason" class="badge badge-danger">Obat Dihentikan</span>
                </template>
                <template v-else>
                  <span v-if="v1.arantfarr_status == 'WAITING'" class="badge badge-warning">Belum Diproses</span>
                  <span v-else-if="v1.arantfarr_status == 'PROSES'" class="badge badge-info">Berjalan</span>
                  <span v-else-if="v1.arantfarr_status == 'DONE'" class="badge badge-success">Sudah diberikan</span>
                </template>

              </template>
            </td>
            <td>{{v.created_by||"-"}}</td>
            <td v-if="k1==0" :rowspan="(v.data||[]).length + 1" style="width:4%">
              <a href="javascript:;" @click="toModal(v,'xl','Catatan Pemberian Obat','FormCatatanPemberian')" v-if="moduleRole('tambah_catatan') && (v1.arantfarr_status == 'PROSES' || v1.arantfarr_status == 'DONE')" class="btn btn-icon btn-sm rounded-round btn-sm alpha-indigo border-indigo text-indigo-800 mb-1" v-b-tooltip.hover title="Tambah Catatan Pemberian Obat">
                <i class="fa-solid fa-pencil"></i>
              </a>
              <a href="javascript:;" @click="toModal(v,'xl','Catatan Pemberian Obat','FormCatatanPemberianDetail')" v-if="moduleRole('lihat_catatan') && (v1.arantfarr_status == 'PROSES' || v1.arantfarr_status == 'DONE')" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover title="Lihat Catatan Pemberian Obat">
                <i class="fas fa-file-prescription"></i>
              </a>

              <a href="javascript:;" v-if="v.arantfar_status != 'WAITING'" @click="unduhDokumenResep(v.arantfar_id)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover title="Unduh Dokumen"><i class="icon-file-download"></i></a>

              <a href="javascript:;" v-if="v.arantfar_is_draft == 'Y' && moduleRole('hapus')" @click="hapusTindakan(v)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-danger border-danger text-danger-800 mb-1" v-b-tooltip.hover title="Hapus"><i class="icon-bin"></i></a>
            </td>
          </tr>
          <tr v-if="!(v.data||[]).length">
            <td colspan="7" class="text-center">
              <p class="mt-3">Belum ada data obat</p>
            </td>
            <td>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!(dataPermintaanResep||[]).length">
          <tr>
            <td colspan="99" class="text-center">
              <p>Tidak ada Data</p>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </b-tab>

  <b-tab title="Tindakan">
    <TindakanPenunjang :rowData="{ ap_id: rowData.aranr_ap_id }" :rowReg="rowData" />
  </b-tab>

  <b-tab title="Kajian Pasien">
    <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
      <b-tab title="Kajian Nyeri">
        <nyeri :reg-id="regId" />
      </b-tab>
      <b-tab title="Kajian Risiko Jatuh">
        <risiko-jatuh :reg-id="regId" />
      </b-tab>
      <b-tab title="Kajian Terminal">
        <terminal :reg-id="regId" />
      </b-tab>
      <b-tab title="Kajian TBC">
        <tbc :reg-id="regId" />
      </b-tab>
      <b-tab title="Kajian Restrain">
        <restrain :reg-id="regId" />
      </b-tab>
      <b-tab title="Kajian Fibrinolisis">
        <fibrinolisis :reg-id="regId" />
      </b-tab>
    </b-tabs>
  </b-tab>

  <b-tab title="Serah Terima Pasien">
    <b-table-simple class="table-sm patient-table" bordered>
      <thead>
        <tr>
          <th>No.</th>
          <th>Dari</th>
          <th>Ke</th>
          <th>Tanggal Pindah</th>
          <th>Perawat</th>
          <th>Status</th>
          <th>Aksi</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading.dataSerahTerima">
          <tr>
            <td colspan="7" class="text-center">Loading...</td>
          </tr>
        </template>
        <template v-else-if="dataSerahTerima.length < 1">
          <tr>
            <td colspan="7" class="text-center">Tidak Terdapat Data.</td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="(value, index) in dataSerahTerima" :key="index">
            <td>{{ index + 1 }}</td>
            <td><strong>{{ value.ruang_dari }}</strong></td>
            <td><strong>{{ value.ruang_ke }}</strong></td>
            <td>
              <template v-if="value.tanggal_pindah">{{ value.tanggal_pindah | moment('DD MMMM YYYY, HH:mm') }}</template>
              <template v-else>-</template>
            </td>
            <td>
              {{ value.perawat || '-' }}
            </td>
            <template v-if="value.type == 'RANAP_TO_RANAP'">
              <td>
                <b-badge v-if="value.artipr_status == 'N'" variant="warning">Belum Diisi</b-badge>
                <b-badge v-else-if="value.artipr_status == 'T'" variant="primary">TTD Dokumen</b-badge>
                <b-badge v-else-if="value.artipr_status == 'D'" variant="success">Selesai</b-badge>
              </td>
              <td>                
                <router-link :to="{name: 'RanapSerahTerimaPasien', params: {pageSlug: regId, form: 'detail', id: value.artipr_id}}" v-if="value.artipr_status == 'D'" class="mr-1 btn btn-icon alpha-blue border-blue" v-b-tooltip.hover.noninteractive="'Detail Form Intra'"><i class="icon-eye"></i></router-link>
              </td>
            </template>
            <template v-else-if="value.type == 'RANAP_TO_BEDAH'">
              <td>
                <b-badge variant="success">Selesai</b-badge>
              </td>
              <td>
                <a href="javascript:;" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Download File'" @click.prevent="downloadFile(value)">
                  <i class="fa-solid fa-download"></i>
                </a>
              </td>
            </template>
            <template v-else-if="value.type == 'BEDAH_TO_RANAP'">
              <td>
                <b-badge v-if="!value.arantbr_is_draft || value.arantbr_is_draft == 'Y'" variant="warning">Isi Formulir</b-badge>
                <b-badge v-else-if="value.arantbr_is_draft == 'N' && !value.arantbr_ttd_by" variant="primary">TTD Dokumen</b-badge>
                <b-badge v-else-if="value.arantbr_is_draft == 'N' && value.arantbr_ttd_by" variant="success">Selesai</b-badge>
              </td>
              <td>
                <a v-if="value.arantbr_ttd_by" href="javascript:;" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Download File'" @click.prevent="downloadFileBedahRanap(value)">
                  <i class="fa-solid fa-download"></i>
                </a>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </b-table-simple>
  </b-tab>
</b-tabs>
</template>

<script>
import $ from 'jquery'
import Gen from '@/libs/Gen'
import Config from '@/libs/Config'
import MonitoringOptions from '@/libs/MonitoringOptions'

import TindakanPenunjang from '@/components/Ranap/TindakanPenunjang.vue'

import PEWS from '@/views/backend/RanapMonitoring/PEWS/PEWS.vue'
import EWS from '@/views/backend/RanapMonitoring/EWS/EWS.vue'
import MEOWS from '@/views/backend/RanapMonitoring/MEOWS/MEOWS.vue'
import Intensive from '@/views/backend/RanapMonitoring/Intensive/Intensive.vue'

import Fibrinolisis from '@/components/RiwayatRanap/KajianPasienRanap/Fibrinolisis.vue'
import Nyeri from '@/components/RiwayatRanap/KajianPasienRanap/Nyeri.vue'
import Restrain from '@/components/RiwayatRanap/KajianPasienRanap/Restrain.vue'
import RisikoJatuh from '@/components/RiwayatRanap/KajianPasienRanap/RisikoJatuh.vue'
import Terminal from '@/components/RiwayatRanap/KajianPasienRanap/Terminal.vue'
import { default as Tbc } from '@/components/RiwayatRanap/KajianPasienRanap/TBC.vue'

const _ = global._
const moment = require("moment")

export default {
  name: "RiwayatRM",
  props: {
    regId: Number,
    rowData: Object,
  },

  components: {
    TindakanPenunjang,
    PEWS,
    EWS,
    MEOWS,
    Intensive,

    Fibrinolisis, Nyeri, Restrain,
    RisikoJatuh, Tbc, Terminal,
  },

  data() {
    return {
      dataSerahTerima: [],
      dataPermintaanResep: [],
      patientData: {
        ap_usia_with_ket: ''
      },
      listCPPT: [],

      viewType: "VIEW",

      loading: {
        dataSerahTerima: true,
        monitoring: true,
        cppt: true,
        riwayatResep: true,

        modalSerahTerima: false,
      }
    }
  },

  mounted() {
    this.getPatientInfo()

    this.getDataSerahTerimaPasien()
    this.getPermintaanResep()
    this.getDataCPPT()
  },

  methods: {
    getDataCPPT() {
      this.$set(this.loading, 'cppt', true)
      Gen.apiRest(
        "/do/" + `RanapCPPT`, {
          data: {
            type: 'get-list-all-cppt',
            id_registrasi: this.regId
          }
        }
      ).then(res => {
        this.$set(this, 'listCPPT', res.data.listCPPT)
        this.$set(this.loading, 'cppt', false)
      })
    },
    getPermintaanResep() {
      this.$set(this.loading, 'riwayatResep', true)
      Gen.apiRest(
        "/get/" + `RanapPasienPermintaanResep/${this.regId}`, {}
      ).then(res => {
        this.$set(this.loading, 'riwayatResep', false)
        this.$set(this, 'dataPermintaanResep', res.data.dataPermintaanResep)
      })
    },
    getDataSerahTerimaPasien() {
      this.$set(this.loading, 'dataSerahTerima', true)
      Gen.apiRest(
        "/get/" + `RanapSerahTerimaPasien`, {
          params: {
            id_registrasi: this.regId
          }
        }
      ).then(res => {
        this.$set(this.loading, 'dataSerahTerima', false)
        this.$set(this, 'dataSerahTerima', res.data.data)
      })
    },
    getPatientInfo() {
      this.$set(this.loading, 'monitoring', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.regId
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'monitoring', false)
      })
    },

    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },

    unduhDokumenResep(id) {
      let data = {
        exptype: 'pdf',
        type: "export",
        pasienId: this.rowData.aranr_ap_id,
        regId: this.rowData.aranr_id,
        resepId: id
      }
      let self = this
      self.loadingOverlay = true
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_API_URL + `/report/'dataFarmasiRanap'?token=IXs1029102asoaksoas102901290`,
        data: data,
        cache: false,
        xhrFields: {
          responseType: 'blob'
        },
        success: data => {
          self.loadingOverlay = false
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(data)
          link.download = `resep_${self.rowReg.aranr_reg_code}-${moment().format("YYYY-MM-DD")}.pdf`
          link.click()
        },
        fail: data => {
          self.loadingOverlay = false
          alert('Not downloaded')
        }
      })
    },
    getUserInput(type) {
      let text = ''
      if (type == 'DOKTER') {
        text = 'Dokter'
      } else if (type == 'PPALAB') {
        text = 'Laboratorium'
      } else if (type == 'PPARADIO') {
        text = 'Radiologi'
      } else if (type == 'PPAFISIO') {
        text = 'Fisioterapi'
      } else if (type == 'PPAFARMASI') {
        text = 'Farmasi'
      } else if (type == 'DIETISIEN') {
        text = 'Dietisien'
      } else if (type == 'PERAWAT') {
        text = 'Perawat'
      } else {
        text = null
      }
      return text
    },
    isHaveTindakLanjut(v) {
      if (v.arancsa_is_resep == "Y" || v.arancsa_is_lab == "Y" || v.arancsa_is_radiologi == "Y" || v.arancsa_is_fisio == "Y" || v.arancsa_is_operasi == "Y" || v.arancsa_is_konsultasi == "Y" || v.arancsa_is_dietisien == "Y" || v.arancsa_is_raber == "Y" || v.arancsa_is_alih_rawat == "Y" || v.arancsa_is_pindah_ruang == "Y" || v.arancsa_is_perawatan == "Y" || v.arancsa_is_done_perawatan == "Y" || v.arancsa_is_rencana_operasi == "Y" || v.arancsa_is_stop_raber == 'Y') {
        return true
      } else {
        return false
      }
    },
    defineAnat(anat) {
      if (anat == 'eye') {
        return "Mata"
      } else if (anat == 'front-eye') {
        return "Mata Depan"
      } else if (anat == 'odontogram') {
        return "Odontogram"
      } else if (anat == 'nervousSystem') {
        return "Syaraf"
      } else if (anat == 'heart') {
        return "Jantung"
      } else if (anat == 'ear') {
        return "Telinga"
      } else if (anat == 'nose') {
        return "Hidung"
      } else if (anat == 'throat') {
        return "Tenggorokkan"
      } else if (anat == 'lung') {
        return "Paru-Paru"
      } else if (anat == 'joint') {
        return "Sendi"
      } else {
        return "Tubuh"
      }
    },
    checkTindakLanjut(data) {
      let TL = []
      if (data.arancpd_is_resep == 'Y') {
        TL.push('Resep')
      }
      if (data.arancpd_is_lab == 'Y') {
        TL.push('Laboratorium')
      }
      if (data.arancpd_is_radiologi == 'Y') {
        TL.push('Radiologi')
      }
      if (data.arancpd_is_fisio == 'Y') {
        TL.push('Fisioterapi')
      }
      if (data.arancpd_is_operasi == 'Y') {
        TL.push('Tindakan Operasi')
      }
      if (data.arancpd_is_konsultasi == 'Y') {
        TL.push('Konsultasi')
      }
      if (data.arancpd_is_dietisien == 'Y') {
        TL.push('Konsultasi Dietisien')
      }
      if (data.arancpd_is_raber == 'Y') {
        TL.push('Rawat Bersama')
      }
      if (data.arancpd_is_alih_rawat == 'Y') {
        TL.push('Alih Rawat')
      }
      if (data.arancpd_is_pindah_ruang == 'Y') {
        TL.push('Pindah Ruang')
      }
      if (data.arancpd_is_rencana_operasi == 'Y') {
        TL.push('Rencanakan Operasi')
      }
      if (data.arancpd_is_stop_raber == 'Y') {
        TL.push('Stop Rawat Bersama')
      }
      if (data.arancpd_is_perawatan == 'Y') {
        TL.push('Masih dalam Perawatan')
      }
      if (data.arancpd_is_done_perawat == 'Y') {
        TL.push('Selesai Perawatan')
      }
      return TL.join(", ")
    },

    moduleRole(menu) {
      if (menu) return true
    }
  },

  computed: {
    Config() {
      return Config
    },

    showPEWS() {
      return this.patientData.ap_usia >= 0 && this.patientData.ap_usia <= 18
    },

    showEWS() {
      return this.patientData.ap_usia > 18
    },

    isCritical() { // cek pasien yang berada di ruangan icu, nicu, dan picu
      return MonitoringOptions.CriticalPatient.
      indexOf(this.patientData.mranjr_code) >= 0
    },

    isObsgyn() {
      return MonitoringOptions.ObsgynPatient.
      indexOf(this.patientData.mranjr_code) >= 0
    },
  }
}
</script>
