<template>

    <div class="content">
    <validation-observer ref="VForm">
    <b-form @submit.prevent="doSubmit">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Pengkajian Fisioterapi Lanjutan</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                    <label>Keluhan <small class="txt_mandatory">*</small></label>
                    <b-textarea v-model="row.appfl_keluhan" rows="5" cols="3" class="form-control" placeholder=""
                        spellcheck="false"></b-textarea>
                    <VValidate 
                        name="Keluhan" 
                        v-model="row.appfl_keluhan" 
                        :rules="toValidate(mrValidation.appfl_keluhan)"
                    />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                    <label>Anamnesa <small class="txt_mandatory">*</small></label>
                    <b-textarea v-model="row.appfl_anamnesa" rows="5" cols="3" class="form-control" placeholder=""
                        spellcheck="false"></b-textarea>
                    <VValidate 
                        name="Anamnesa" 
                        v-model="row.appfl_anamnesa" 
                        :rules="toValidate(mrValidation.appfl_anamnesa)"
                    />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Objective<small class="txt_mandatory">*</small></label>
                  <input type="text" name="name" v-model="row.appfl_objektif" class="form-control">
                  <VValidate 
                        name="Objektif" 
                        v-model="row.appfl_objektif" 
                        :rules="toValidate(mrValidation.appfl_objektif)"
                    />
                </div>
              </div>

                <div class="col-md-12 mb-2">
                    <div class="col-md-12 mb-2">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label>Tekanan Darah</label>
                                    <div class="input-group">
                                        <b-form-input :formatter="$parent.number" v-model="row.appfl_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                        
                                        <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                        <b-form-input :formatter="$parent.number" placeholder="Diastole" v-model="row.appfl_ttv_tekanan_darah_max" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                                    </div>
                                    <VValidate 
                                        name="Tekanan Darah Min" 
                                        v-model="row.appfl_ttv_tekanan_darah_min" 
                                        :rules="toValidate(mrValidation.appfl_ttv_tekanan_darah_min)"
                                    />
                                    <VValidate 
                                        name="Tekanan Darah Max" 
                                        v-model="row.appfl_ttv_tekanan_darah_max" 
                                        :rules="toValidate(mrValidation.appfl_ttv_tekanan_darah_max)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label>Nadi</label>
                                        <div class="form-row">
                                            <div class="col-md-12">
                                            <div class="input-group">
                                                <b-form-input :formatter="$parent.number" v-model="row.appfl_ttv_nadi" type="text" class="form-control" />
                                                <div class="input-group-append"><span class="input-group-text">x/mnt</span>
                                                </div>
                                                <div class="input-group-append">
                                                    <div style="width: 140px;">
                                                    <v-select placeholder="Pilih Label" v-model="row.appfl_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <VValidate 
                                            name="Nadi" 
                                            v-model="row.appfl_ttv_nadi" 
                                            :rules="toValidate(mrValidation.appfl_ttv_nadi)"
                                        />
                                        <VValidate 
                                            name="Label" 
                                            v-model="row.appfl_ttv_label" 
                                            :rules="toValidate(mrValidation.appfl_ttv_label)"
                                        />
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>Gula Darah</label>
                                    <div class="form-row">
                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input :formatter="$parent.alphanum" v-model="row.appfl_ttv_gula_darah"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <VValidate 
                                        name="Gula Darah" 
                                        v-model="row.appfl_ttv_gula_darah" 
                                        :rules="toValidate(mrValidation.appfl_ttv_gula_darah)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label>Pernafasan</label>
                                    <div class="input-group">
                                        <b-form-input :formatter="$parent.number" v-model="row.appfl_ttv_pernafasan"  type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                    </div>
                                    </div>
                                    
                                    <VValidate 
                                        name="Pernafasan" 
                                        v-model="row.appfl_ttv_pernafasan" 
                                        :rules="toValidate(mrValidation.appfl_ttv_pernafasan)"
                                    />
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>SPO2</label>
                                    <div class="form-row">
                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input :formatter="$parent.number" v-model="row.appfl_ttv_spo2"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <VValidate 
                                        name="SPO2" 
                                        v-model="row.appfl_ttv_spo2" 
                                        :rules="toValidate(mrValidation.appfl_ttv_spo2)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                    <label>Suhu</label>
                                    <div class="form-row">

                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input :formatter="$parent.number" v-model="row.appfl_ttv_suhu"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">C</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <VValidate 
                                        name="Suhu" 
                                        v-model="row.appfl_ttv_suhu" 
                                        :rules="toValidate(mrValidation.appfl_ttv_suhu)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                    <label>Berat Badan</label>
                                    <div class="form-row">

                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input @input="hitungBMI()" :formatter="$parent.number" v-model="row.appfl_ttv_weight"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">kg</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <VValidate 
                                        name="Berat Badan" 
                                        v-model="row.appfl_ttv_weight" 
                                        :rules="toValidate(mrValidation.appfl_ttv_weight)"
                                    />
                                    </div>
                                </div>

                                
                                <div class="col-md-2">
                                    <div class="form-group">
                                    <label>Tinggi</label>
                                    <div class="form-row">

                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input @input="hitungBMI()" :formatter="$parent.number" v-model="row.appfl_ttv_height"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">cm</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <VValidate 
                                        name="Tinggi Badan" 
                                        v-model="row.appfl_ttv_height" 
                                        :rules="toValidate(mrValidation.appfl_ttv_height)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>Lingkar Kepala</label>
                                    <div class="form-row">

                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input :formatter="$parent.number" v-model="row.appfl_ttv_lingkar_kepala"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">cm</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <VValidate 
                                        name="Lingkar Kepala" 
                                        v-model="row.appfl_ttv_lingkar_kepala" 
                                        :rules="toValidate(mrValidation.appfl_ttv_lingkar_kepala)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-3" v-if="row.ap_usia >= 1">
                                    <div class="form-group">
                                    <label>BMI</label>
                                    <div class="form-row">
                                        <div class="col-md-12">
                                        <div class="input-group">
                                            <b-form-input disabled v-model="row.appfl_ttv_bmi"  type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">m2</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-3" v-if="row.ap_usia <= 15">
                                    <div class="form-group">
                                    <label>Luas Permukaan Tubuh Anak</label>
                                    <div class="input-group">
                                        <b-form-input :formatter="number" v-model="row.appfl_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>Kesadaran</label>
                                    <div class="form-row">
                                        <div class="col-md-12">
                                            <v-select placeholder="Pilih Kesadaran" v-model="row.appfl_ttv_kesadaran" :options="mKesadaran" label="text" :clearable="true" :reduce="v=>v.value">
                                                <template slot="selected-option" slot-scope="option">
                                                    <span v-b-tooltip.hover :title="option.text">{{ option.text }}</span>
                                                </template>
                                                <template slot="option" slot-scope="option">
                                                    <span v-b-tooltip.hover :title="option.text">{{ option.text }}</span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                    <VValidate 
                                        name="Kesadaran" 
                                        v-model="row.appfl_ttv_kesadaran" 
                                        :rules="toValidate(mrValidation.appfl_ttv_kesadaran)"
                                    />
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                

              <div class="col-md-12 mb-2">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-10">
                        

                        <div class="form-row">
                          <div class="col-md-12">
                            <b-form-group>
                                <b-form-checkbox-group
                                    v-model="row.appfl_rencana_tindakan"
                                    :options="Config.mr.rencanaTindakan"
                                    name="rencanaTindakan"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </b-form-group>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <label>Tanggal Pemeriksaan<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-5">
                                    <div class="input-group mb-3">
                                        <datepicker input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.appfl_tanggal_kontrol" >
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>  
                                    
                                    <VValidate 
                                        name="Tanggal Pemeriksaan" 
                                        v-model="row.appfl_tanggal_kontrol" 
                                        :rules="toValidate(mrValidation.appfl_tanggal_kontrol)"
                                    />
                                </div>
                                
                                <!--
                                <div class="col-md-4">
                                  <div class="btn-group">
                                    <a href="javascript:;" data-toggle="modal" data-target="#"
                                      class="btn bg-green-400 btn-labeled btn-labeled-left"><b><i
                                          class="icon-printer"></i></b>
                                      Print Jadwal</a>
                                  </div>
                                </div>
                                -->

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card border shadow-0 mt-3" v-if="row.isEdit">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                </div>
                <div class="card-body">
                    <div class="form-group">
                    <label for="addInfoTindakan">Keterangan Perubahan</label>
                    <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                    </b-textarea>

                        
                    <VValidate 
                        :name="`Keterangan Perubahan`" 
                        v-model="row.arm_notes" 
                        :rules="{required: 1, min: 2, max:512}"
                    />
                    </div>
                </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
    </b-form>
    </validation-observer>
    </div>
</template>

<script>

import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

// import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'

export default{
    // extends: GlobalVue,
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        mKesadaran: Array,
        mAlatBantu: Array,
    },
    components:{ 
        Datepicker
    },
    data(){
        return {
            
        }
    },
    methods: {
        
        hitungBMI(){
            if(this.row.appfl_ttv_weight && this.row.appfl_ttv_height){
                this.row.appfl_ttv_bmi = this.row.appfl_ttv_weight / ((this.row.appfl_ttv_height/100)*(this.row.appfl_ttv_height/100))
                this.row.appfl_ttv_bmi = this.row.appfl_ttv_bmi.toFixed(2) 
            }else{
                this.row.appfl_ttv_bmi = null
            }
        },
        back(){
            this.$router.back()
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save-fisio-lanjutan'
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        toValidate(val){
            return {...val}
        },

        doSubmit(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   

            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data-fisio-lanjutan'
                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok'
                                    // allowOutsideClick: false,
                                    // allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.$parent.user.levelId == 1){
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uFisio : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.$parent.row.arm_ar_id} }).catch(()=>{})
                                        }   
                                        
                                        let dataSocket = {
                                          to : this.$parent.uFisio,
                                          from : this.$parent.uFisio,
                                          no_antrian: this.rowReg.ar_no_antrian_ppa_fisio
                                        }
                                        this.$socket.emit('done_penunjang', dataSocket)
                                        
                                        let dataSocketAll = {
                                          to : 'ALLPJ',
                                          from : this.$parent.uFisio,
                                          no_antrian: this.rowReg.ar_no_antrian_ppa_fisio
                                        }
                                        this.$socket.emit('done_penunjang', dataSocketAll)
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },    
    mounted() {
      document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.row.arm_notes = null
        },1500)
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}

</script>