<template>
  <div> 
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowData['arpd_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowData.arpd_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-4">
                <template v-if="rowData.arpd_is_ttd == 'Digital'">
                  <div class="form-group">
                    <label for="">Tanda Tangan Petugas</label>
                    <div class="signing-element" data-target-id="ttdPetugas">
                        <VueSignaturePad
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttdPetugas"
                        />
                        <div class="mt-2">
                        <button type="button" @click="undoTTD('ttdPetugas','arpd_petugas_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                        </button>
                        
                        <button type="button" @click="output('ttdPetugas','arpd_petugas_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                        </button>
                        </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="">Tanda Pembuat Pernyataan</label>
                    <div class="signing-element" data-target-id="ttdPembuatPernyataan">
                        <VueSignaturePad
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttdPembuatPernyataan"
                        />
                        <div class="mt-2">
                        <button type="button" @click="undoTTD('ttdPembuatPernyataan','arpd_pembuat_pernyataan_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                        </button>
                        
                        <button type="button" @click="output('ttdPembuatPernyataan','arpd_pembuat_pernyataan_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                        </button>
                        </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="form-group">
                    <button type="button" @click="generateDoc" class="btn btn-primary" data-dismiss="modal">Generate Dokumen</button>
                    <div class="mb-2" v-if="isShowUpload">
                      <Uploader v-model="rowData.arpd_file" isDocument type="docimage" />
                    </div>
                  </div>                  
                </template>

                <div class="form-group">
                    <label for="">Nama Penanggung Jawab <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowData.arpd_nama_penanggung_jawab" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                    placeholder="Nama Penanggung Jawab"/>
                    <VValidate 
                        name="Nama Penanggung Jawab" 
                        v-model="rowData.arpd_nama_penanggung_jawab" 
                        :rules="{required: 1}"
                    />
                </div>

                <div class="form-group">
                    <label for="">Tanggal Lahir Penanggung Jawab <small class="text-danger">*</small></label>
                    <div class="input-group">
                        <div class="input-group">
                            <datepicker input-class="form-control transparent"
                            placeholder="Tanggal Lahir Penanggung Jawab" class="my-datepicker"
                            calendar-class="my-datepicker_calendar"
                            v-model="rowData.arpd_tanggal_lahir_penanggung_jawab"
                            >
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                    <VValidate 
                        name="Tanggal Lahir Penanggung Jawab" 
                        v-model="rowData.arpd_tanggal_lahir_penanggung_jawab" 
                        :rules="{required: 1}"
                    />
                </div>

                <div class="form-group">
                    <label for="">Tempat Lahir Penanggung Jawab <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowData.arpd_tempat_lahir_penanggung_jawab" type="text" name="TTLPenanggung" id="TTLPenanggung" class="form-control"
                    placeholder="Tempat Lahir Penanggung Jawab"/>
                    <VValidate 
                        name="Tempat Lahir Penanggung Jawab" 
                        v-model="rowData.arpd_tempat_lahir_penanggung_jawab" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <div class="form-group">
                    <label for="">No Handphone <small class="text-danger">*</small></label>
                    <b-form-input :formatter="numberOnly" v-model="rowData.arpd_no_handphone" type="text" name="NOHP" id="NOHP" class="form-control"
                    placeholder="No Handphone"/>
                    <VValidate 
                        name="No Handphone" 
                        v-model="rowData.arpd_no_handphone" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <div class="form-group">
                    <label for="">Alamat Penanggung Jawab <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowData.arpd_alamat_penanggung_jawab" type="text" name="AlamatDgPJ" id="AlamatDgPJ" class="form-control"
                    placeholder="Alamat Penanggung Jawab"/>
                    <VValidate 
                        name="Alamat Penanggung Jawab" 
                        v-model="rowData.arpd_alamat_penanggung_jawab" 
                        :rules="{required: 1}"
                    />
                </div>

                <div class="form-group">
                    <label for="">Hubungan Penanggung Jawab dengan Pasien <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowData.arpd_hubungan_penanggung_jawab" type="text" name="HubPasien" id="HubPasien" class="form-control"
                    placeholder="Hubungan Penanggung Jawab dengan Pasien"/>
                    <VValidate 
                        name="Hubungan Penanggung Jawab dengan Pasien" 
                        v-model="rowData.arpd_hubungan_penanggung_jawab" 
                        :rules="{required: 1}"
                    />
                </div>
                    
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content">
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                        <div>
                            <table style="border-collapse: collapse;width: 100%;font-size: 12px;line-height: 1.5;">
                            <tbody><tr>
                                <td style="padding: 4px 8px;text-align: center;" colspan="2">
                                <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 1.5;">Formulir Penetapan DPJP</h2>
                                <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 1.5;">(Dokter Penanggung Jawab Pelayanan)</h2>
                                </td>
                            </tr>
                            </tbody></table>
                            <table border="0" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 12px;line-height: 1.5;margin-top: 24px;">
                            <tbody><tr>
                                <td style="padding: 4px 8px;" colspan="2">
                                <p style="margin: 0;margin-bottom: 4px;">Yang bertandatangan dibawah ini:</p>
                                <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                    <tbody><tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Nama</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowData.arpd_nama_penanggung_jawab||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Umur</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%" 
                                    >
                                        <span v-if="rowData.arpd_tanggal_lahir_penanggung_jawab">
                                        {{rowData.arpd_tanggal_lahir_penanggung_jawab | moment("from", "now", true)}}
                                        </span>
                                        <span v-else> - </span>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Tempat, Tgl. Lahir</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowData.arpd_tempat_lahir_penanggung_jawab||"-"}}, {{rowData.arpd_tanggal_lahir_penanggung_jawab | moment("DD MMMM YYYY") }}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Alamat</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">{{rowData.arpd_alamat_penanggung_jawab||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">No. Telp / HP</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">{{rowData.arpd_no_handphone||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Hubungan dengan Pasien</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowData.arpd_hubungan_penanggung_jawab||"-"}}
                                    </td>
                                    </tr>
                                </tbody></table>

                                <br>
                                <p style="margin: 0;margin-bottom: 4px;">Sebagai penanggungjawab atas pasien:</p>
                                <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                    <tbody><tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Nama</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{isParent.row.ap_fullname||"-"}}</td>
                                    </tr>

                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Umur / Tanggal Lahir</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%" 
                                    v-if="isParent.row.ap_dob"> {{isParent.row.ap_dob | moment("from", "now", true)}} / {{isParent.row.ap_dob | moment("DD MMMM YYYY") }}</td>
                                    </tr>

                                </tbody></table>
                                <br>
                                <p style="margin: 0;margin-bottom: 4px;">Dengan ini saya meminta untuk dirawat oleh dokter spesialis yaitu:</p>
                                <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                    <tbody><tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Nama Dokter</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowData.dokter_ranap||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Spesialis</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowData.poli_ranap||"-"}}</td>
                                    </tr>
                                </tbody></table>
                                <br>
                                <p style="margin: 0;margin-bottom: 8px;">Demikian surat ini dibuat tanpa ada unsur paksaan dari pihak manapun.</p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 4px 8px;" width="50%">
                                <div style="text-align:center;">
                                    <div style="margin-bottom: 4px;">&nbsp;</div>
                                    <div style="margin-bottom: 48px;">Petugas</div>
                                    
                                    <span v-if="rowData.arpd_is_ttd == 'Digital'">
                                    <img :src="rowData.arpd_petugas_ttd ? rowData.arpd_petugas_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                    <br/>
                                    </span>
                                    <span v-else>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>         
                                    </span>                            

                                    <div>( <span>{{user.fullName||"-"}}</span> )</div>
                                </div>
                                </td>
                                <td style="padding: 4px 8px;" width="50%">
                                <div style="text-align:center;">
                                    <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                    <div style="margin-bottom: 48px;">Pembuat Pernyataan</div>
                            
                                    <span v-if="rowData.arpd_is_ttd == 'Digital'">
                                    <img :src="rowData.arpd_pembuat_pernyataan_ttd ? rowData.arpd_pembuat_pernyataan_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                    <br/>
                                    </span>
                                    <span v-else>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>         
                                    </span>                            

                                    <div>(<span> {{rowData.arpd_nama_penanggung_jawab||"-"}} </span>)</div>
                                </div>
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'


const moment = require('moment')
export default{
  extends: GlobalVue, 
  components: {
    Datepicker
  },  
  data(){
      return {
        rowData: {},
        mrValidationData: {},
        isShowUpload : false
      }
  },
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
    now(){
        return moment().format('DD MMM YYYY')
    },
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    generateDoc(){
      this.rowData.arpd_temp = $(".document-framer").html()
      
      let data = this.rowData
      data.type = 'generate-dokumen'
      data.arpd_ar_id = this.isParent.regID
      
      let self = this
      self.loadingOverlay = true
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'DokumenTTDPenetapanDPJP'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.isShowUpload = true
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `${'Dokumen-Penetapan'}-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
    getData(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'RoPenetapanDPJP', {
          data: {
            type: 'get-dokumen',
            regId: this.isParent.regID
          }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        
        this.rowData.arpd_nama_penanggung_jawab = this.rowData.arpd_nama_penanggung_jawab||this.isParent.row.arpj_fullname
        this.rowData.arpd_alamat_penanggung_jawab = this.rowData.arpd_alamat_penanggung_jawab||this.isParent.row.arpj_address
        this.rowData.arpd_hubungan_penanggung_jawab = this.rowData.arpd_hubungan_penanggung_jawab||this.isParent.row.arpj_hubungan_keluarga_text

        this.loadingOverlay = false
        this.$nextTick(() => {
          if(this.$refs['ttdPetugas']){
            this.$refs['ttdPetugas'].fromDataURL(this.rowData.arpd_petugas_ttd)
          } 
          if(this.$refs['ttdPembuatPernyataan']){
            this.$refs['ttdPembuatPernyataan'].fromDataURL(this.rowData.arpd_pembuat_pernyataan_ttd)
          } 
        })
      })
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.rowData[field] = null
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.rowData[field] = data
      }
    },
    doSubmit(){
      this.$refs['VFormTTD'].validate().then(success => {
        if (!success) {
              return this.$swal({
                  icon: 'error',
                  title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
              }).then(result => {
                  if (result.value) {
                      setTimeout(() => {
                          let inv = []
                          let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                          for (let i = 0; i < (el || []).length; i++) {
                              if (el[i].style.display !== 'none') {
                              inv.push(el[i].id)
                              }
                          }
                      },250)
                  }
              })
        }

        if(this.rowData.arpd_is_ttd == 'Digital'){
            if(!this.rowData.arpd_pembuat_pernyataan_ttd){
                return this.$swal({
                    icon: 'error',
                    title: 'Dokumen Belum Ditanda tangani'
                })   
            }
            if(!this.rowData.arpd_petugas_ttd){
                return this.$swal({
                    icon: 'error',
                    title: 'Dokumen Belum Ditanda tangani'
                })   
            }
        }

        this.rowData.arpd_temp = $(".document-framer").html()


        if (success) {
            this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.rowData
                data.type = 'submit-dokumen'
                data.arpd_ar_id = this.isParent.regID
                
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RoPenetapanDPJP', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    }).then(result => {
                        this.isParent.openPenetapanDPJP = false
                    })
                }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
              }
            })
        }
      })
    }
  },
  mounted() {
    this.getData()
  },

}


</script>

