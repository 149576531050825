<template>
  <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit" class="card">
        <div class="form-underlying card-body p-3">
          <div class="card mb-2">
              <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                  <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                      <h2>S</h2>
                  </div>
                  <strong class="ml-2">Situation</strong>
                  </div>
              </a>
              </div>
              <div class="wrap_line collapse show" id="formIntra1">
              <table class="table table-bordered">
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Nama</h4>
                      <p>{{isParent.row.ap_fullname||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{isParent.row.ap_code||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>NIK</h4>
                      <p>{{isParent.row.ap_nik||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td>
                      <div class="result_tab">
                      <h4>Tanggal Lahir</h4>
                      <p v-if="isParent.row.ap_dob">{{isParent.row.ap_dob | moment("DD MMMM YYYY")}}</p>
                      <p v-else> - </p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4>DPJP</h4>
                      <p>{{rowData.dpjp||"-"}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                        <h4>Tanggal & Jam Datang</h4>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="rowData.arira_tanggal_datang">
                            </datepicker>
                        </div>

                        <div class="input-group bootstrap-timepicker timepicker mb-2">
                            <span class="input-group-prepend input-group-addon">
                            <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                            </span>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowData.arira_jam_datang">
                            </vue-timepicker>
                        </div>

                        
                        <VValidate 
                            name="Tanggal Datang" 
                            v-model="rowData.arira_tanggal_datang" 
                            :rules="{required: 1}"
                        />
                        
                        <VValidate 
                            name="Jam Datang" 
                            v-model="rowData.arira_jam_datang" 
                            :rules="{required: 1}"
                        />
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td>
                      <div class="result_tab">
                      <h4>Diagnosa</h4>
                      <p>{{rowData.arira_diagnosa||"-"}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4>Kondisi Saat Ini</h4>
                      <p>{{rowData.arira_kondisi_saat_ini||"-"}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4></h4>
                      <p></p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td>
                      <div class="result_tab">
                      <h4>Dari Ruang</h4>
                      <p>{{rowData.arira_dari_ruang||"-"}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4>Ke Ruang</h4>
                      <p>{{rowData.arira_ke_ruang||"-"}}</p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4>Jam Pindah</h4>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="rowData.arira_tanggal_pindah">
                            </datepicker>
                        </div>

                        <div class="input-group bootstrap-timepicker timepicker mb-2">
                            <span class="input-group-prepend input-group-addon">
                            <span class="input-group-text"><i class="fa-solid fa-clock"></i></span>
                            </span>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowData.arira_jam_pindah">
                            </vue-timepicker>
                        </div>
                        
                        <VValidate 
                            name="Tanggal Datang" 
                            v-model="rowData.arira_tanggal_pindah" 
                            :rules="{required: 1}"
                        />
                        
                        <VValidate 
                            name="Jam Datang" 
                            v-model="rowData.arira_jam_pindah" 
                            :rules="{required: 1}"
                        />
                      </div>
                  </td>
                  </tr>
              </table>
              </div>
          </div>
          <div class="card mb-2">
              <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                  <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                      <h2>B</h2>
                  </div>
                  <strong class="ml-2">Background</strong>
                  </div>
              </a>
              </div>
              <div class="wrap_line collapse show" id="formIntra2">
              <div class="row">
                  <div class="col-md-8">
                  <div class="row">
                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Keluhan Utama</label>
                          <b-form-textarea v-model="rowData.arira_keluhan_utama" name="" id="" rows="3" class="form-control" />
                          
                          <VValidate 
                              name="Keluhan Utama" 
                              v-model="rowData.arira_keluhan_utama" 
                              :rules="toValidate(mrValidationData.auira_keluhan_utama)"
                          />
                      </div>
                      </div>
                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Riwayat Penyakit</label>
                          <b-form-textarea v-model="rowData.arira_riwayat_penyakit" name="" id="" rows="3" class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />
                          
                          <VValidate 
                              name="Riwayat Penyakit" 
                              v-model="rowData.arira_riwayat_penyakit" 
                              :rules="toValidate(mrValidationData.auira_riwayat_penyakit)"
                          />
                      </div>
                      </div>

                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Intervensi yang Telah Dilakukan</label>
                          <b-form-textarea v-model="rowData.arira_background_intervensi" name="" id="" cols="30" rows="3" class="form-control" />
                          
                          <VValidate 
                              name="Intervensi yang Telah Dilakukan" 
                              v-model="rowData.arira_background_intervensi" 
                              :rules="toValidate(mrValidationData.auira_background_intervensi)"
                          />
                      </div>  
                      </div>
                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                          <b-form-textarea v-model="rowData.arira_riwayat_pembedahan" name="asuhanPembedahan" id="asuhanPembedahan" rows="3"
                          class="form-control form-control-sm" />
                          
                          <VValidate 
                              name="Riwayat Pembedahan" 
                              v-model="rowData.arira_riwayat_pembedahan" 
                              :rules="toValidate(mrValidationData.auira_riwayat_pembedahan)"
                          />
                      </div>
                      </div>
                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Obat-obatan yang Diberikan</label>
                          <b-form-textarea v-model="rowData.arira_obat_obatan_yang_diberikan" name="" id="" rows="3" class="form-control" />
                          
                          <VValidate 
                              name="Obat-obatan yang Diberikan" 
                              v-model="rowData.arira_obat_obatan_yang_diberikan" 
                              :rules="toValidate(mrValidationData.auira_obat_obatan_yang_diberikan)"
                          />
                      </div>
                      </div>
                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Cairan Parenteral yang Diberikan</label>
                          <b-form-textarea v-model="rowData.arira_cairan_parental_yang_diberikan" name="" id="" rows="3" class="form-control" />
                          
                          <VValidate 
                              name="Cairan Parenteral yang Diberikan" 
                              v-model="rowData.arira_cairan_parental_yang_diberikan" 
                              :rules="toValidate(mrValidationData.auira_cairan_parental_yang_diberikan)"
                          />
                      </div>
                      </div>
                      <div class="col-md-6">
                      <div class="form-group">
                          <label for="">Riwayat Alergi</label>
                          <b-form-textarea v-model="rowData.arira_riwayat_alergi" name="" id="" rows="3" class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />
                          
                          <VValidate 
                              name="Riwayat Alergi" 
                              v-model="rowData.arira_riwayat_alergi" 
                              :rules="toValidate(mrValidationData.auira_riwayat_alergi)"
                          />
                      </div>
                      </div>
                  </div>
                  </div>
                  <div class="col-md-4">
                  <div class="form-group">
                      <label for="">Tindakan Invasif</label>
                      <table class="table table-sm table-bordered">
                      <thead>
                          <tr>
                          <th width="48">#</th>
                          <th>Tindakan</th>
                          <th>Aksi</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(v,k) in (rowData.arira_tindakan_invasif||[])" :key="k">
                          <td>{{k+1}}</td>
                          <td>
                              <input type="text" v-model="rowData.arira_tindakan_invasif[k]" class="form-control form-control-sm">
                              <VValidate 
                                  :name="'Tindakan Invasif '+(k+1)" 
                                  v-model="rowData.arira_tindakan_invasif[k]" 
                                  :rules="{required : 1}"
                              />
                          </td>
                          <td><a href="javascript:;" class="list-icons-item"
                              @click="rowData.arira_tindakan_invasif.splice(k,1)"
                              data-toggle="tooltip" data-placement="top" title="Delete"><i
                                  class="icon-bin"></i></a></td>
                          </tr>

                          <tr v-if="!(rowData.arira_tindakan_invasif||[]).length">
                          <td colspan="3" class="text-center">
                              Tidak ada Data
                          </td>
                          </tr>
                      </tbody>
                      <tfoot>
                          <tr>
                          <td colspan="3" class="text-center">
                              <a href="javascript:;" @click="rowData.arira_tindakan_invasif.push('')" class="btn btn-outline-primary"><i
                                  class="icon-plus2 mr-2"></i>Tambah Tindakan</a>
                          </td>
                          </tr>
                      </tfoot>
                      </table>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          <div class="card mb-2">
              <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                  <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                      <h2>A</h2>
                  </div>
                  <strong class="ml-2">Assessment</strong>
                  </div>
              </a>
              </div>
              <div class="wrap_line collapse show" id="formIntra3">
              <div class="card border shadow-0">
                  <div class="bg-light card-header py-2">
                  <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                  </div>
                  <div class="card-body">
                  <table class="table table-bordered">
                      <tr>
                      <td>
                          <div class="result_tab">
                          <h4>Skala Nyeri</h4>
                          <p>{{rowData.arira_skala_nyeri_value||0}} 
                              <span v-if="rowData.arira_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                              <span v-else-if="rowData.arira_skala_nyeri_value >= 1 && rowData.arira_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                              <span v-else-if="rowData.arira_skala_nyeri_value >= 4 && rowData.arira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                              <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                          <br />(Numeric Rating Scale)</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Tingkat Kesadaran</h4>
                          <p>{{rowData.mk_name||"-"}}</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Risiko Jatuh</h4>
                          <p>{{rowData.arira_resiko_jatuh_value||0}} 
                              <span v-if="rowData.arira_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                              <span v-else-if="rowData.arira_resiko_jatuh_value >= 7 && rowData.arira_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                              <span v-else-if="rowData.arira_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                          <br />
                            <span v-if="isParent.row.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                            <span v-else-if="isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                            <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                          </p>
                          </div>
                      </td>
                      </tr>
                      <tr>
                      <td>
                          <div class="result_tab">
                          <h4>Tekanan Darah</h4>
                          <p>{{rowData.arira_tekanan_darah_min||"-"}}/{{rowData.arira_tekanan_darah_max||"-"}} mmHG</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Nadi</h4>
                          <p>{{rowData.arira_nadi||"-"}}x per menit</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>Suhu</h4>
                          <p>{{rowData.arira_suhu||"-"}} &deg;C</p>
                          </div>
                      </td>
                      </tr>
                      <tr>
                      <td>
                          <div class="result_tab">
                          <h4>Respiration Rate</h4>
                          <p>{{rowData.arira_respiration||"-"}}x per menit</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4>SpO2</h4>
                          <p>{{rowData.arira_spo2||"-"}}</p>
                          </div>
                      </td>
                      <td>
                          <div class="result_tab">
                          <h4></h4>
                          <p></p>
                          </div>
                      </td>
                      </tr>
                  </table>
                  </div>
              </div>
              
              <template v-if="(isParent.row.ap_gender == 2 || isParent.row.ap_gender == 0) && isParent.row.ap_usia >= 12 && isParent.row.ap_usia <= 60 && rowData.arira_is_hamil == 'Y'">
                <div class="card mb-2">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
                  </div>
                  <div class="card-body">
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Pasien Sedang Hamil</h4>
                                    <p>{{rowData.arira_is_hamil == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Pasien Menyusui</h4>
                                    <p>{{rowData.arira_is_menyusui == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                  </div>
                                </td>
                                <td v-if="rowData.arira_is_hamil == 'Y'">
                                  <div class="result_tab">
                                    <h4>Tinggi Fungus</h4>
                                    <p>{{rowData.arira_tinggi_fungus || "-"}}</p>
                                  </div>
                                </td>
                                <td v-if="rowData.arira_is_hamil == 'Y'">
                                  <div class="result_tab">
                                    <h4>Gravid</h4>
                                    <p>{{rowData.arira_gravid || "-"}}</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td v-if="rowData.arira_is_hamil == 'Y'">
                                  <div class="result_tab">
                                    <h4>Para</h4>
                                    <p>{{rowData.arira_para || "-"}}</p>
                                  </div>
                                </td>
                                <td v-if="rowData.arira_is_hamil == 'Y'">
                                  <div class="result_tab">
                                    <h4>Abortus</h4>
                                    <p>{{rowData.arira_abortus || "-"}}</p>
                                  </div>
                                </td>
                                <td v-if="rowData.arira_is_hamil == 'Y'">
                                  <div class="result_tab">
                                    <h4>HPHT</h4>
                                    <p>{{rowData.arira_hpht | moment("DD MMM YYYY")}}</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3" v-if="rowData.arira_is_hamil == 'Y'">
                                  <div class="result_tab">
                                    <h4>HPL</h4>
                                    <p>{{rowData.arira_hpl | moment("DD MMM YYYY")}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="row">
                  <div class="col-md-5">
                  <div class="form-group">
                      <label for="">Pemeriksaan Lab</label>
                      <b-form-textarea v-model="rowData.arira_lab" name="" id="" rows="3" class="form-control" />
                  </div>
                  </div>
                  <div class="col-md-5">
                  <div class="form-group">
                      <label for="">Radiologi</label>
                      <b-form-textarea v-model="rowData.arira_radiologi" name="" id="" rows="3" class="form-control" />
                  </div>
                  </div>
              </div>
              </div>
          </div>
          <div class="card mb-2">
              <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                  <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                      <h2>R</h2>
                  </div>
                  <strong class="ml-2">Recommendation</strong>
                  </div>
              </a>
              </div>
              <div class="wrap_line collapse show" id="formIntra4">
              <div class="row">
                  <div class="col-md-6">
                  <div class="form-group">
                      <label for="">Tindakan yang Perlu Dilanjutkan</label>
                      <b-form-textarea v-model="rowData.arira_tindakan" name="" id="" rows="3" class="form-control" />
                      <VValidate 
                          name="Tindakan yang Perlu Dilanjutkan" 
                          v-model="rowData.arira_tindakan" 
                          :rules="toValidate(mrValidationData.auira_tindakan)"
                      />
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div class="form-group">
                      <label for="">Usulan Kolaborasi</label>
                      <b-form-textarea v-model="rowData.arira_usulan_kolab" name="" id="" rows="3" class="form-control" />
                      <VValidate 
                          name="Usulan Kolaborasi" 
                          v-model="rowData.arira_usulan_kolab" 
                          :rules="toValidate(mrValidationData.auira_usulan_kolab)"
                      />
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </div>
          <div class="card-footer">
          <div class="text-right">
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
          </div>
        </b-form>
    </validation-observer>
  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import VueTimepicker from 'vue2-timepicker'
import Datepicker from 'vuejs-datepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

const moment = require('moment')
export default{
  extends: GlobalVue, 
  data(){
      return {
        rowData: {},
        mrValidationData: {},
        jamPindah: null
      }
  },
  
    components:{ 
        VueTimepicker,Datepicker
    },

  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
    now(){
        return moment().format('DD MMM YYYY')
    },
  },
  methods: {
    
    getTimeF(){
      this.jamPindah = moment().format('HH:mm')
    },
    toValidate(val){
      return {...val}
    },
    getData(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'RoTransferIntra', {
          data: {
            type: 'get-dokumen',
            ar_mpo_id: this.isParent.rowReg.ar_mpo_id,
            regId: this.isParent.regID
          }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay = false
        this.$nextTick(() => {
          if(this.$refs['ttdPetugas']){
            this.$refs['ttdPetugas'].fromDataURL(this.rowData.arpd_petugas_ttd)
          } 
          if(this.$refs['ttdPembuatPernyataan']){
            this.$refs['ttdPembuatPernyataan'].fromDataURL(this.rowData.arpd_pembuat_pernyataan_ttd)
          } 
        })
      })
    },
    doSubmit(){
      this.$refs['VFormTTD'].validate().then(success => {
        if (!success) {
              return this.$swal({
                  icon: 'error',
                  title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
              }).then(result => {
                  if (result.value) {
                      setTimeout(() => {
                          let inv = []
                          let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                          for (let i = 0; i < (el || []).length; i++) {
                              if (el[i].style.display !== 'none') {
                              inv.push(el[i].id)
                              }
                          }
                      },250)
                  }
              })
        }

        if (success) {
            this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.rowData
                data.type = 'submit-dokumen'
                data.arira_ar_id = this.isParent.regID
                
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RoTransferIntra', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    }).then(result => {
                        this.isParent.openPenetapanTransferIntra = false
                    })
                }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
              }
            })
        }
      })
    }
  },
  mounted() {
    this.getData()
    setInterval(()=>{
      this.getTimeF()
    },1000)
  },

}


</script>

