<template>
	<div class="content-wrapper overflow-visible">
		<div class="page-header page-header-sticky page-header-sticky-open">
			<div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

				<div class="page-header-info flex-1 py-3 text-uppercase">
					<div class="d-flex justify-content-between">
						<div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="phi-main font-weight-semibold mb-2 pb-1">
									<span>{{row.ap_fullname||"-"}}</span>
									<span class="ml-2 pl-2 border-left" v-b-tooltip.hover
										title="No Rekam Medis">{{row.ap_code||"-"}}</span>
									<span class="ml-2 pl-2 border-left" v-b-tooltip.hover
										title="No Registrasi">{{rowReg.ar_reg_code||"-"}}</span>
									<span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
									<span class="ml-2 pl-2 border-left" v-b-tooltip.hover
										title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
								</h5>
							</div>
							<div class="phi-sub-info d-flex">
								<div class="phi-info d-flex flex-column">
									<small>Tanggal Lahir</small>
									<strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
								</div>
								<div class="phi-info d-flex flex-column pl-2 ml-3">
									<small>Usia</small>
									<strong
										class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
								</div>
								<div class="phi-info d-flex flex-column pl-2 ml-3">
									<small>Jenis Kelamin</small>
									<strong class="font-weight-semibold">{{row.ap_gender == 1 ? 'Laki-Laki': 'Perempuan'}}</strong>
								</div>
								<div class="phi-info d-flex flex-column pl-2 ml-3">
									<small>Opsi Pembayaran</small>
									<strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
								</div>
								<div class="phi-info d-flex flex-column pl-2 ml-3">
									<small>Agama</small>
									<strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
								</div>
							</div>
						</div>

						<div v-if="typeKajian=='kajian-dokter'||typeKajian=='kajian-dokter-terapi'||typeKajian=='kajian-gizi'"
							class="d-flex flex-column">
							<a href="javascript:;" @click="viewKajianPerawat()" data-toggle="modal"
								class="btn btn-outline-light">Lihat Kajian Perawat</a>

							<a href="javascript:;" v-if="typeKajian=='kajian-dokter' && row.kajianLatestDokter"
								@click="doOpenRiwayatDokter(row.kajianLatestDokter)" data-toggle="modal"
								class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis</a>

							<a href="javascript:;" v-if="(riwayatRadiologi||[]).length || (riwayatLab||[]).length" data-toggle="modal"
								@click="doOpenHasilPenunjang(rowReg.ar_id)" class="btn btn-outline-light mt-1">
								Lihat Hasil Penunjang
							</a>
						</div>


						<div v-else class="phi-info d-flex flex-column col-md-3 ml-auto">
							<small>Dokter</small>
							<h5 class="phi-main font-weight-semibold mb-0">
								<span>{{rowReg.bu_full_name||"-"}}</span>
							</h5>
							<div class="mb-2">
								<strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
			<a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
				<i class="icon-arrow-up5 align-middle"></i>
				<span class="align-middle">Sembunyikan Informasi Pasien</span>
			</a>
		</div>
		<div v-if="isList" class="content">
			<div class="card">
				<div class="card-header">
					<div class="d-flex align-items-center">
						<div class="d-flex align-items-center">
							<h5 class="card-title font-weight-semibold mr-auto">Rekam Medis No. <span class="text-blue-600">{{row.ap_code||"-"}}</span></h5>
							<a href="javascript:;"
								v-if="(user.levelId == uPerawat || user.levelId == uDokter || user.levelId == 1 || user.levelId == uDokterFisio) && ( (rowReg.ar_tanggal_ranap && isPoliAdjRanap) || (rowReg.ar_tanggal_ranap && isPoliAdjRanap) || (rowReg.ar_is_rujukan == 'Y' && Object.keys(row.kajianDokterData||{}).length) || (isShowPengantarRanap))"
								@click="doOpenReport"
								class="btn alpha-green btn-sm border-green text-green-800 font-weight-semibold ml-2" data-toggle="modal"
								data-target="#modalSuratMenyurat">
								<i class="icon-files-empty mr-1"></i>
								Unduh Dokumen Pasien
							</a>
                            <a href="javascript:;"
                                v-if="((rowReg.arres_no_bpjs && rowReg.bu_dokter_bpjs_id && rowReg.absd_no_sep) && moduleRole('lihat_rm_icare')) || bypassICare"
								@click="doOpenICare"
								class="btn alpha-info btn-sm border-info text-info-800 font-weight-semibold ml-2">
                                <template v-if="!isLoadICare">
                                    <i class="icon-file-text3"></i>
                                    Lihat Rekam Medis ICare
                                </template>
                                <template v-else>
                                    <div class="spinner-border spinner-border-sm text-info" role="status"></div>
                                    Sedang mengunduh data Icare
                                </template>
							</a>
						</div>

						<template v-if="user.levelId !== uAdmin">
							<a href="javascript:;" v-if="isShowBtn" @click="toKajianAwal()"
								class="btn bg-blue-600 btn-labeled btn-labeled-left ml-auto">
								<b><i class="icon-plus2"></i></b>
								<template v-if="rowReg.ar_status == sPPA">
									<template v-if="user.levelId == uFisio">
										Buat Kajian Fisio Terapi
									</template>
									<template v-else-if="user.levelId == uLab">
										Buat Kajian Laboratorium
									</template>
									<template v-else-if="user.levelId == uRadiologi">
										Buat Kajian Radiologi
									</template>
									<template v-else-if="user.levelId == uDokterGizi">
										Buat Kajian Dietisien
									</template>
									<template v-else>
										Buat Kajian Awal
									</template>
								</template>
								<template v-else>
									Buat Kajian Awal
								</template>
							</a>
						</template>

					</div>
				</div>
                <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTabList == 1 ? 'nav-link active' : 'nav-link'">Rawat Jalan</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" :class="activeTabList == 2 ? 'nav-link active' : 'nav-link'" class="nav-link">UGD</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" :class="activeTabList == 3 ? 'nav-link active' : 'nav-link'" class="nav-link">Rawat Inap</a></li>
                </ul>
                <div class="tab-content">
                    <div id="tabRmPoli" :class="activeTabList == 1 ? 'tab-pane fade active show':'tab-pane fade'">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-hover table-sm patient-table">
                                <thead>
                                    <tr>
                                        <th>No. Reg</th>
                                        <th>Tanggal Reg</th>
                                        <th>Nama Dokter</th>
                                        <th>Keluhan</th>
                                        <th>Kajian</th>
                                        <th>Riwayat RM</th>
                                        <th>Aksi</th>
                                        <th>Hapus</th>
                                    </tr>
                                </thead>
                                <template v-if="(dataList||[]).length">
                                    <template v-for="(v,k) in (dataList||[])">
                                        <tbody :class="v.isHead? 'isHead': ''" :key="k">
                                            <tr :class="v.arm_is_draft == 'Y' ? 'table-warning' : ''">
                                                <td>
                                                    <a 
                                                        v-if="(v.arm_can_edit_by && (v.arm_is_approve == 'Y' && v.arm_is_approve_upla == 'Y') || otherConditional(v) ) || isDev || isPenunjang || user.levelId == 1"
                                                        title="Edit Data" href="javascript:;" @click="redirect(v)"
                                                        class="text-primary font-weight-semibold border-bottom">{{v.ar_reg_code||"-"}}</a>
                                                    <span v-else>{{v.ar_reg_code||"-"}}</span>
            
                                                    <template v-if="v.arm_is_draft == 'Y'">
                                                        <span :class="`badge bg-warning ml-1`">
                                                            Draft
                                                        </span><br />
                                                        <small>{{v.petugas||"-"}}</small>
                                                    </template>

                                                    <a href="javascript:;"
                                                        @click="openModalDocument(v.ar_id)"
                                                        class="d-block text-green-800 font-weight-semibold">
                                                        Lihat Dokumen
                                                    </a>
                                                </td>
                                                <td>
                                                    <span v-if="v.ar_reg_date">{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                                                    <span v-else>{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                                                </td>
                                                <td>
                                                    <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                                                </td>
            
                                                <td>
                                                    <span>{{v.keluhan||"-"}}</span>
                                                </td>
            
                                                <td>
                                                    <a href="javascript:;" v-if="v.kajianPerawatId" @click="toDetail(v,v.arm_ar_id)"
                                                        class="btn btn-sm btn-icon"
                                                        :class="v.arm_is_kajian_awal == 'Y' ? 'alpha-blue border-blue' : 'alpha-green border-green'"
                                                        :title="'Lihat Kajian '+(v.arm_is_kajian_awal == 'Y' ? 'Awal' : 'Lanjutan')"><i
                                                            class="icon-file-eye"></i></a>
                                                    <span v-else>-</span>
                                                </td>
            
            
                                                <td>
                                                    <template
                                                        v-if="(v.kajianDokter || v.kajianDokterTerapi || v.kajianGizi || v.kajianFisio || v.kajianLab || v.kajianRadiologi )">
                                                        <a href="javascript:;" @click="doOpenRiwayatRM(v)" :title="'Lihat Riwayat Rekam Medis'"
                                                            class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
            
                                                        <a href="javascript:;"
                                                            v-if="v.arm_is_kajian_awal == 'Y' && v.arm_status == 5 && v.arm_ppa == 'FISIO' && v.arm_poli_id == 1"
                                                            @click="downloadFile(v.arm_ar_id,'permintaanFisio')"
                                                            :title="'Lihat Dokumen Permintaan Fisio Terapi'"
                                                            class="btn btn-sm btn-icon alpha-success border-success ml-2"><i class="icon-file-plus"></i></a>
                                                    </template>
                                                    <span v-else>-</span>
            
                                                </td>
                                                <td>
                                                    <a href="javascript:;"
                                                        v-if="user.levelId == uPerawat || user.levelId == uDokter || user.levelId == 1 || user.levelId == uDokterFisio"
                                                        @click="openModalSehatSakit(v.ar_id)"
                                                        class="btn alpha-green btn-sm border-green text-green-800 font-weight-semibold mt-1 mr-1 mb-1" data-toggle="modal">
                                                        <i class="icon-files-empty mr-1"></i>
                                                        Buat Dokumen 
                                                    </a>
                                                    <template v-if="rowReg.ar_status !== sDokter &&  rowReg.ar_is_penjunjang_fisio != 'Y'">
                                                        <a v-if="v.arm_is_draft !== 'Y' && isShowBtn && isShowBtnLanjutan && user.levelId !== uAdmin && rowReg.ar_mpo_id == v.arm_poli_id"
                                                            @click="toKajianLanjut(v.arm_aka_id, v.arm_id)" href="javascript:;"
                                                            class="btn bg-teal btn-labeled btn-labeled-left">
                                                            <b><i class="icon-plus2"></i></b>
                                                            <template v-if="rowReg.ar_status == sPPA">
                                                                <template v-if="user.levelId == uFisio">
                                                                    Buat Kajian Fisio Terapi
                                                                </template>
                                                                <template v-else-if="user.levelId == uLab">
                                                                    Buat Kajian Laboratorium
                                                                </template>
                                                                <template v-else-if="user.levelId == uRadiologi">
                                                                    Buat Kajian Radiologi
                                                                </template>
                                                                <template v-else-if="user.levelId == uDokterGizi">
                                                                    Buat Kajian Dietisien
                                                                </template>
                                                                <template v-else>
                                                                    Buat Kajian
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                Buat Kajian Lanjutan
                                                            </template>
                                                        </a>
                                                    </template>
            
                                                    <template v-else-if="rowReg.ar_status !== sDokter && rowReg.ar_is_penjunjang_fisio == 'Y'">
                                                        <a v-if="v.arm_is_draft !== 'Y' && isShowBtn && isShowBtnLanjutan && user.levelId !== uAdmin"
                                                            @click="toKajianLanjut(v.arm_aka_id, v.arm_id)" href="javascript:;"
                                                            class="btn bg-teal btn-labeled btn-labeled-left">
                                                            <b><i class="icon-plus2"></i></b>
                                                            <template v-if="rowReg.ar_status == sPPA">
                                                                <template v-if="user.levelId == uFisio">
                                                                    Buat Kajian Fisio Terapi
                                                                </template>
                                                                <template v-else-if="user.levelId == uLab">
                                                                    Buat Kajian Laboratorium
                                                                </template>
                                                                <template v-else-if="user.levelId == uRadiologi">
                                                                    Buat Kajian Radiologi
                                                                </template>
                                                                <template v-else-if="user.levelId == uDokterGizi">
                                                                    Buat Kajian Dietisien
                                                                </template>
                                                                <template v-else>
                                                                    Buat Kajian
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                Buat Kajian Lanjutan
                                                            </template>
                                                        </a>
                                                    </template>
            
                                                    <template v-else>
            
                                                        <a v-if="v.arm_is_draft !== 'Y' && isShowBtn && isShowBtnLanjutan && user.levelId !== uAdmin && rowReg.ar_mpo_id == v.arm_poli_id"
                                                            @click="toKajianLanjut(v.arm_aka_id, v.arm_id)" href="javascript:;"
                                                            class="btn bg-teal btn-labeled btn-labeled-left">
                                                            <b><i class="icon-plus2"></i></b>
                                                            Buat Kajian Lanjutan
                                                        </a>
                                                    </template>
            
                                                    <template v-if="isPoliAdj">
            
                                                        <a v-if="v.kajianPerawatId && 
                                                                                                    (uPerawat == user.levelId || uPerawatUGD == user.levelId || user.levelId == 1) && v.arm_ar_id == $route.query.regId"
                                                            @click="resepPerawat(v,v.arm_ar_id)" href="javascript:;"
                                                            class="mt-1 btn bg-warning btn-labeled btn-labeled-left">
                                                            <b><i class="icon-plus2"></i></b>
                                                            {{v.arm_is_have_resep_perawat == 'Y' ? 'Edit' : 'Buat'}} Resep Perawat
                                                        </a>
                                                    </template>
            
            
                                                    <template v-if="isPoliAdjRanap">
                                                        <a v-if="v.arm_is_draft != 'Y' && 	(user.mpo_id == 32 || user.levelId == 1) && v.arm_ar_id == $route.query.regId"
                                                            @click="toSBAR(v,v.arm_ar_id)" href="javascript:;"
                                                            class="mt-1 btn bg-warning btn-labeled btn-labeled-left">
                                                            <b><i class="icon-plus2"></i></b>
                                                            {{rowReg.ar_is_have_sbar == 'Y' ? 'Edit' : 'Buat'}} SBAR
                                                        </a>
                                                    </template>
            
                                                    <template v-if="v.ar_tanggal_ranap && isPoliAdjRanap">
                                                        <a v-if="v.arm_is_draft != 'Y' && (uPerawat == user.levelId || user.levelId == 1) && v.arm_ar_id == $route.query.regId"
                                                            @click="toBalanceCairan(v,v.arm_ar_id, v.arm_id)" href="javascript:;"
                                                            class="mt-1 btn bg-warning btn-labeled btn-labeled-left">
                                                            <b><i class="icon-plus2"></i></b>
                                                            Balance Cairan
                                                        </a>
                                                    </template>
                                                </td>
            
                                                <td>
                                                    <a v-if="v.arm_is_draft == 'Y' && (v.arm_can_edit_by && (v.arm_is_approve == 'Y' && v.arm_is_approve_upla == 'Y') || otherConditional(v) || user.levelId == 1)"
                                                        href="javascript:;" @click="deleteRiwayatRM(v)" v-b-tooltip.hover="'Hapus Data Rekam Medis'"
                                                        class="btn btn-sm btn-icon alpha-danger border-danger"><i class="icon-bin"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </template>
                                <tbody v-else-if="!(dataList||[]).length && dataList">
                                    <tr>
                                        <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                            Data Tidak Ditemukan
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                                            <div class="skeletal-comp mb-2"></div>
                                            <div class="skeletal-comp mb-2"></div>
                                            <div class="skeletal-comp"></div>
                                        </th>
                                    </tr>
                                </tbody>
                                <tfoot v-if="(dataList||[]).length && pageNow < last_page">
                                    <tr>
                                        <td class="text-center" colspan="99">
                                            <a href="javascript:;" @click="loadMore"
                                                class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div id="tabRmUgd" :class="activeTabList == 2 ? 'tab-pane fade active show':'tab-pane fade'">
                        <template v-if="activeTabList == 2"> 
                            <ListRMUGD :pasienId="$route.params.pageSlug"/>
                        </template>
                    </div>
                    <div id="tabRmRanap" :class="activeTabList == 3 ? 'tab-pane fade active show':'tab-pane fade'">
                        <template v-if="activeTabList == 3"> 
                            <ListRMRanap :pasienId="$route.params.pageSlug"/>
                        </template>
                    </div>
                </div>
			</div>

			<div class="card-footer">
				<div class="text-right">
					<button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
				</div>
			</div>
			<!-- /dashboard content -->

			<b-modal v-model="openDetailKajian"
				:title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
                <template v-if="finishLoadKajian">
                    <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
                    <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
                </template>
                <div v-else>
                    <p>
                        Loading...
                    </p>
                </div>
			</b-modal>

		</div>
		<div v-else>
			<FormKajianAwal v-bind="passToSub" v-if="typeKajian == 'awal'" />
			<FormKajianLanjutan v-bind="passToSubLanjutan" v-if="typeKajian == 'lanjutan'" />
			<FormKajianDokter v-bind="passToSubDokter" v-if="typeKajian == 'kajian-dokter'" />
			<FormKajianDokterTerapi v-bind="passToSubDokterTerapi" v-if="typeKajian == 'kajian-dokter-terapi'" />
			<FormKajianGizi v-bind="passToSubGizi" v-if="typeKajian == 'kajian-gizi'" />

			<FormKajianFisio v-bind="passToSubPPAFisio" v-if="typeKajian == 'kajian-awal-fisio'" />
			<FormKajianFisioLanjutan v-bind="passToSubPPAFisioLanjutan" v-if="typeKajian == 'kajian-lanjutan-fisio'" />
			<FormKajianRadiologi v-bind="passToSubRadiologi" v-if="typeKajian == 'kajian-radiologi'" />
			<FormKajianLab v-bind="passToSubLab" v-if="typeKajian == 'kajian-lab'" />
			<BalanceCairan v-bind="passToSub" v-if="typeKajian == 'balance-cairan'" />

			<b-modal v-model="openDetailKajianPerawat"
				:title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
				<DetailKajianAwal v-bind="dataRiwyatRMPerawat" v-if="dataRiwyatRMPerawatIS == 'Y'" />
				<DetailKajianLanjutan v-bind="dataRiwyatRMPerawat" v-else />
			</b-modal>

		</div>

		<b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
			<RiwayatRM v-if="finishLoadRwiayatRM" v-bind="passToSubRM" />
            <div v-else>
                <p>
                    <!-- <b-spinner label="Spinning" small class="me-2"></b-spinner>  -->Loading...
                </p>
            </div>
		</b-modal>

        <b-modal id="modalSuratSehatSakit" title="Unduh Dokumen" size="lg" hide-footer>
            <div class="row row-gutter">
                <div class="col-md-6">
					<div class="wrap_line mb-0">
						<h3 class="pb-1">Surat Keterangan Sakit</h3>
						<a href="javascript:;" @click="openModalSuratKeteranganSakit(dataModal.ar_id)"
							class="btn btn-success btn-labeled btn-labeled-left">
							<b><i class="icon-download"></i></b>
							<span>Unduh</span>
						</a>
					</div>
				</div>

				<div class="col-md-6">
					<div class="wrap_line mb-0">
						<h3 class="pb-1">Surat Keterangan Sehat</h3>
						<a href="javascript:;" @click="openModalSuratKeteranganSehat(dataModal.ar_id)"
							class="btn btn-success btn-labeled btn-labeled-left">
							<b><i class="icon-download"></i></b>
							<span>Unduh</span>
						</a>
					</div>
				</div>
            </div>
        </b-modal>


		<b-modal v-model="openReport" :title="'Unduh Dokumen'" size="lg" hide-footer>
			<div class="row row-gutter">
				<div class="col-md-6 col-lg-4" v-if="rowReg.ar_tanggal_ranap && isPoliAdjRanap">
					<div class="wrap_line mb-0">
						<h3 class="pb-1">Surat Transfer Intra Rawat Inap</h3>
						<a href="javascript:;" @click="doOpenTransferIntra(rowReg.ar_id)"
							class="btn btn-warning btn-labeled btn-labeled-left mr-1 mb-1">
							<b><i class="icon-file-text"></i></b>
							<span>Isi Dokumen</span>
						</a>
					</div>
				</div>

				<div class="col-md-6 col-lg-4" v-if="rowReg.ar_tanggal_ranap && isPoliAdjRanap">
					<div class="wrap_line mb-0">
						<h3 class="pb-1">Surat Penepatan DPJP</h3>
						<a href="javascript:;" @click="doOpenDPJP(rowReg.ar_id)"
							class="btn btn-warning btn-labeled btn-labeled-left mr-1 mb-1">
							<b><i class="icon-file-text"></i></b>
							<span>Isi Dokumen</span>
						</a>
						<a :href="uploader(docDetail.dataDPJP.arpd_file)" target="_blank" v-if="docDetail.dataDPJP.arpd_file"
							class="btn btn-success btn-labeled btn-labeled-left mr-1 mb-1">
							<b><i class="icon-download"></i></b>
							<span>Unduh</span>
						</a>
					</div>
				</div>

				<template v-if="rowReg.ar_is_rujukan == 'Y' && Object.keys(row.kajianDokterData||{}).length">
					<div class="col-md-6 col-lg-4" v-if="row.kajianDokterData.artlr_type == 'RI'">
						<div class="wrap_line mb-0">
							<h3 class="pb-1">Surat Rujukan Internal</h3>
							<a href="javascript:;" @click="downloadReport('suratRujukanInternal','Surat Rujukan Internal')"
								class="btn btn-success btn-labeled btn-labeled-left">
								<b><i class="icon-download"></i></b>
								<span>Unduh</span>
							</a>
						</div>
					</div>
					<div class="col-md-6 col-lg-4"
						v-if="row.kajianDokterData.artlr_type == 'RK' || row.kajianDokterData.artlr_type == 'RP'">
						<div class="wrap_line mb-0">
							<h3 class="pb-1">Surat Rujukan Kasus</h3>
							<a href="javascript:;" @click="downloadReport('suratRujukanExternal','Surat Rujukan External')"
								class="btn btn-success btn-labeled btn-labeled-left">
								<b><i class="icon-download"></i></b>
								<span>Unduh</span>
							</a>
						</div>
					</div>
				</template>

				<template v-if="isShowPengantarRanap">
					<div class="col-md-6 col-lg-4">
						<div class="wrap_line mb-0">
							<h3 class="pb-1">Surat Pengantar Rawat Inap</h3>
							<a href="javascript:;" @click="doOpenPengantar" class="btn btn-success btn-labeled btn-labeled-left">
								<b><i class="icon-download"></i></b>
								<span>Unduh</span>
							</a>
						</div>
					</div>
				</template>
			</div>
		</b-modal>

		<b-modal v-model="openResepPerawat" title="Resep Perawat" size="xl" hide-footer>
			<ResepPerawat />
		</b-modal>

		<b-modal v-model="openHasilPenunjang" title="Hasil Penunjang" size="xl" hide-footer>
			<HasilPenunjang />
		</b-modal>

		<b-modal v-model="openPengantar" @ok.prevent="doSubmitPengantar" title="Surat Pengantar Rawat Inap" size="lg">
			<div class="card-body" v-if="Object.keys(pengantarRanap||{}).length">
				<table class="table table-bordered table-sm text-uppercase">
					<tbody>
						<tr>
							<td width="33%">
								<div class="result_tab">
									<h4>Nama Pasien</h4>
									<p>{{pengantarRanap.ap_fullname||"-"}}</p>
								</div>
							</td>
							<td width="33%">
								<div class="result_tab">
									<h4>Tanggal Lahir</h4>
									<p>{{pengantarRanap.ap_dob | moment("DD MMMM YYYY")}}</p>
								</div>
							</td>
							<td width="33%">
								<div class="result_tab">
									<h4>Jenis Kelamin </h4>
									<p>{{pengantarRanap.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="result_tab">
									<h4>No. Rekam Medis</h4>
									<p>{{pengantarRanap.ap_code||"-"}}</p>
								</div>
							</td>
							<td>
								<div class="result_tab">
									<h4>DR. Jaga/DPJP </h4>
									<p>{{pengantarRanap.bu_full_name||"-"}}</p>
								</div>
							</td>
							<td>
								<div class="result_tab">
									<h4>Diagnosa Awal</h4>
									<p>{{pengantarRanap.aranspr_diagnosa_awal||"-"}}</p>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="result_tab">
									<h4>Tanggal Masuk/Jam</h4>
									<div class="input-group">
										<datepicker input-class="form-control transparent h-38" placeholder="Tanggal" class="my-datepicker"
											calendar-class="my-datepicker_calendar" v-model="pengantarRanap.aranspr_tanggal_masuk">
										</datepicker>
										<vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam"
											input-class="form-control h-38" v-model="pengantarRanap.aranspr_jam_masuk">
										</vue-timepicker>
										<div class="input-group-append calendar-group">
											<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
										</div>
									</div>
								</div>
							</td>
							<td>
								<div class="result_tab">
									<h4>Tindakan Medis</h4>
									<b-form-textarea v-model="pengantarRanap.aranspr_tindakan_medis" rows="4" />
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="result_tab">
									<h4>Rencana Tanggal Masuk/Jam</h4>
									<div class="input-group">
										<datepicker input-class="form-control transparent h-38" placeholder="Tanggal" class="my-datepicker"
											calendar-class="my-datepicker_calendar" v-model="pengantarRanap.aranspr_rencana_tanggal_masuk">
										</datepicker>
										<vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam"
											input-class="form-control h-38" v-model="pengantarRanap.aranspr_rencana_jam_masuk">
										</vue-timepicker>
										<div class="input-group-append calendar-group">
											<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
										</div>
									</div>
								</div>
							</td>
							<td>
								<div class="result_tab">
									<h4>Persiapan Pre Operasi</h4>
									<b-form-textarea v-model="pengantarRanap.aranspr_pre_operasi" rows="4" />
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="result_tab">
									<h4>Keterangan</h4>
									<b-form-checkbox-group class="checkbox-inline mt-2" v-model="pengantarRanap.aranspr_keterangan"
										:options="Config.mr.keteranganFormulir" name="Keterangan"></b-form-checkbox-group>
									<template v-if="(pengantarRanap.aranspr_keterangan||[]).indexOf('Lainnya') != -1">
										<b-form-group>
											<b-form-input placeholder="Keterangan Lainnya" type="text" class="form-control"
												v-model="pengantarRanap.aranspr_keterangan_lain" />
										</b-form-group>
									</template>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</b-modal>

		<b-modal v-model="openSBAR" title="CPPT SBAR" size="xl" hide-footer>
			<CPPTSBAR />
		</b-modal>

		<b-modal v-model="openPenetapanTransferIntra" title="Transfer Intra Rawat Inap" size="xl" hide-footer>
			<TransferIntra />
		</b-modal>

		<b-modal v-model="openPenetapanDPJP" title="Penetapan DPJP" size="xl" hide-footer>
			<PenetapanDPJP />
		</b-modal>

        <b-modal id="daftarSurat" title="Daftar Surat" size="lg">
            <b-overlay :show="loadingDocument">
                <b-table-simple responsive bordered>
                    <b-thead>
                        <b-tr>
                            <b-th>No</b-th>
                            <b-th>Jenis Surat</b-th>
                            <b-th>Tanggal Dibuat</b-th>
                            <b-th>Aksi</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-if="documents.length > 0">
                            <b-tr v-for="(value, index) in documents" :key="index">
                                <b-td>{{ index + 1 }}</b-td>
                                <b-td>{{ value.aspa_jenis_surat == 'surat-keterangan-sakit' ? 'Surat Keterangan Sakit' : 'Surat Keterangan Sehat' }}</b-td>
                                <b-td>{{ value.aspa_created_date | moment('DD MMM YYYY, HH:mm') }}</b-td>
                                <b-td>
                                    <a target="_blank" :href="uploader(value.aspa_file)" class="btn alpha-green btn-sm border-green text-green-800 font-weight-semibold ml-2">
                                        <i class="icon-download mr-1"></i>
                                        Unduh 
                                    </a>
                                </b-td>
                            </b-tr>
                        </template>
                        <b-tr v-else>
                            <b-td colspan="4" class="text-center">Tidak Terdapat Surat</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-overlay>
            <template #modal-footer="{close}">
                <b-button variant="primary" @click="close">Tutup</b-button>
            </template>
        </b-modal>

        <b-modal id="modalSuratSakit" title="Form Surat Keterangan Sakit" size="lg">
            <validation-observer ref="VFormSuratSakit" v-slot="{handleSubmit}">
                <b-form @submit.prevent="handleSubmit(submitSuratSakit)">
                    <b-row>
                        <b-col md="4">
                            <b-form-group>
                            <label>Tanggal Awal <span class="text-danger">*</span></label>
                            <b-input-group>
                                <template #prepend><span class="input-group-text"><i class="icon-calendar"></i></span></template>
                                <datepicker v-model="dataModal.tanggal_awal" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
                            </b-input-group>
                            <VValidate name="Tanggal Awal" v-model="dataModal.tanggal_awal" rules="required" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                            <label>Tanggal Akhir <span class="text-danger">*</span></label>
                            <b-input-group>
                                <template #prepend><span class="input-group-text"><i class="icon-calendar"></i></span></template>
                                <datepicker v-model="dataModal.tanggal_akhir" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
                            </b-input-group>
                            <VValidate name="Tanggal Akhir" v-model="dataModal.tanggal_akhir" rules="required"></VValidate>
                            </b-form-group>
                        </b-col>
                        <div class="w-100"></div>
                        <b-col md="4">
                            <b-form-group>
                                <label>Alasan Izin <span class="text-danger">*</span></label>
                                <v-select name="Alasan Izin" v-model="dataModal.alasan_izin" :options="mrAlasanIzin"></v-select>
                                <VValidate name="Alasan Izin" v-model="dataModal.alasan_izin" rules="required" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <label>Keperluan Izin <span class="text-danger">*</span></label>
                                <v-select name="Keperluan Izin" v-model="dataModal.keperluan_izin" :options="mrKeperluanIzin"></v-select>
                                <VValidate name="Keperluan Izin" v-model="dataModal.keperluan_izin" rules="required" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <template #modal-footer>
                <b-button variant="secondary" @click="closeModal('modalSuratSakit')">Batal</b-button>
                <b-button variant="success" @click="submitSuratSakit">Submit</b-button>
            </template>
        </b-modal>

        <b-modal id="modalSuratSehat" title="Form Surat Keterangan Sehat" size="lg">
            <validation-observer ref="VFormSuratSehat" v-slot="{handleSubmit}">
                <b-form @submit.prevent="handleSubmit(submitSuratSehat)">
                    <b-row>
                        <b-col md="12">
                            <b-form-group>
                                <template>Alasan <span class="text-danger">*</span></template>
                                <b-form-textarea v-model="dataModal.alasan"></b-form-textarea>
                                <VValidate name="Alasan" v-model="dataModal.alasan" rules="required" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <template #modal-footer>
                <b-button variant="secondary" @click="closeModal('modalSuratSehat')">Batal</b-button>
                <b-button variant="success" @click="submitSuratSehat">Submit</b-button>
            </template>
        </b-modal>

	</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import FormKajianAwal from './FormKajianAwal.vue'
import FormKajianLanjutan from './FormKajianLanjutan.vue'
import FormKajianDokter from './FormKajianDokter.vue'
import FormKajianDokterTerapi from './FormKajianDokterTerapi.vue'
import FormKajianGizi from './FormKajianGizi.vue'
import FormKajianFisio from './FormKajianFisio.vue'
import FormKajianFisioLanjutan from './FormKajianFisioLanjutan.vue'
import FormKajianRadiologi from './FormKajianRadiologi.vue'
import FormKajianLab from './FormKajianLab.vue'

import BalanceCairan from './BalanceCairan/BalanceCairan.vue'

import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal.vue'
import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan.vue'
import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM.vue'
import ListRMRanap from '@/components/Ranap/RiwayatRM/List.vue'
import ListRMUGD from '@/components/RiwayatUGD/List.vue'

import ResepPerawat from './ResepPerawat.vue'
import CPPTSBAR from './CPPTSBAR.vue'
import HasilPenunjang from './HasilPenunjang.vue'
import PenetapanDPJP from './PenetapanDPJP.vue'
import TransferIntra from './TransferIntra.vue'

import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker'


export default {
  extends: GlobalVue,
  components:{FormKajianAwal,FormKajianLanjutan,DetailKajianAwal,DetailKajianLanjutan,FormKajianDokter,FormKajianDokterTerapi,FormKajianGizi,RiwayatRM,FormKajianFisioLanjutan,FormKajianFisio,FormKajianRadiologi,FormKajianLab,ResepPerawat,CPPTSBAR,HasilPenunjang,Datepicker,VueTimepicker,BalanceCairan,TransferIntra,PenetapanDPJP,ListRMRanap,ListRMUGD},
  data() {
    return {
        isBtnType : '',
        isShowBtn : false,
        isShowBtnLanjutan : false,
        rowReg: {},
        mPekerjaan: [],
        mAlatBantu: [],
        mSkrininGizi: [],
        mSkrininGiziV2: [],
        diagnosa: [],
        mSDKI: [],
        mSLKI: [],
        mSIKI: [],
        mPoli: [],
        mDokter: [],
        mKesadaran: [],
        mICD9: [],
        mICD10: [],
        mRadiologi: [],
        mLab: [],
        pageNow: 1,
        last_page: 1,
        resepDokter: [],
        resepPerawatData: [],
        mObat: [],
        mAlergi: [],
        mObatPreferensi: [],
        mTypeSatuan: [],
        mDosis: [],
        openDetailKajianPerawat: false,

        openDetailKajian: false,
        idKajian: false, // sbg key pengunci
        finishLoadKajian: false,

        openRwiayatRM: false,
        idRiwayatRM: false, // sbg key pengunci
        finishLoadRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwayatRMFarmasi: [],
        dataRiwayatRMFollowUp: {},

        dataRiwyatRMPerawatIS: '',

        statusElektroterapi: [],
        statusMekanoTerapi: [],
        statusLatihan: [],
        mAlatKesehatan: [],
        mAlatInfus: [],
        mPaketAlkes: [],
        tindakanLainnya: [],
        mRacikan: [],

        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],
        
        riwayatKeluhan: [],
        riwayatDiagnosa: [],
        riwayatObat: [],
        dataDokter: {},

        // new
        dataICD9: [],
        dataICD10: [],
        riwayatICD9: [],
        riwayatICD10: [],

        mGiziTindakan: [],
        mGiziDiagnosa: [],
        dataGiziTindakan: [],
        dataGiziDiagnosa: [],
        riwayatGiziTindakan: [],
        riwayatGiziDiagnosa: [],
        openReport: false,
        
        dataICD9RM: [],
        dataICD10RM: [],
        mFaskes: [],
        mHariLibur: [],
        mDokterRujukan: [],
        mPoliRujukan : [],

        mRuangan: [],
        mDokterRanap: [],

        openResepPerawat: false,
        regID : null,
        idRM: null,

        riwayatRadiologi: [],
        riwayatLab: [],
        openHasilPenunjang: false,

        // pengantar ranap
        pengantarRanap: {},
        isShowPengantarRanap: false,
        openPengantar: false,

        openSBAR: false,

        openPenetapanDPJP: false,
        openPenetapanTransferIntra: false,   
        docDetail: {
            dataDPJP : {},
            dataTransfer : {}
        },
        activeTabList: 1,

        isLoadICare: false,
        // pengantar ranap

        // Form Surat sakit & surat sehat
        dataModal: {},
        mrKeperluanIzin: [],
        mrAlasanIzin: [],

        loadingDocument: false,
        documents: [],
    }
  },
  computed: {
    isPenunjang(){
        if(this.user.levelId == 9 || this.user.levelId == 13 || this.user.levelId == 12 || this.user.levelId == 6){
            return true
        }else{
            return false
        }
    },
    bypassICare(){
        return this.$route.query.bypassICare
    },
    isDev(){
      return process.env.VUE_APP_IS_DEV || false
    },
    isList(){
        return !this.$route.params.rmNo
    },
    typeKajian(){
        return this.$route.params.typeKajian
    },
    passToSub(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mPekerjaan: this.mPekerjaan,
        mAlatBantu: this.mAlatBantu,
        mSkrininGizi: this.mSkrininGizi,
        mSkrininGiziV2: this.mSkrininGiziV2,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSLKI: this.mSLKI,
        mSIKI: this.mSIKI,
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat,
        mAlergi: this.mAlergi,
        resepDokter: this.resepDokter,
      })
    },
    passToSubLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSIKI: this.mSIKI,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat,
        mAlergi: this.mAlergi,
        resepDokter: this.resepDokter,
      })
    },
    passToSubDokter(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mKesadaran: this.mKesadaran,
        diagnosa: this.diagnosa,
        mICD9: this.mICD9,
        mICD10: this.mICD10,
        mRadiologi: this.mRadiologi,
        mLab: this.mLab,
        riwayatObat: this.riwayatObat,
        riwayatDiagnosa: this.riwayatDiagnosa,
        riwayatKeluhan: this.riwayatKeluhan,

        dataICD9: this.dataICD9,
        dataICD10: this.dataICD10,
        riwayatICD9: this.riwayatICD9,
        riwayatICD10: this.riwayatICD10,

        resepDokter: this.resepDokter,
        resepPerawatData: this.resepPerawatData,

        mObatPreferensi: this.mObatPreferensi,
        mAlergi: this.mAlergi,
        mTypeSatuan: this.mTypeSatuan,
        
        mObat: this.mObat,
        mDosis: this.mDosis,
        mAlatKesehatan: this.mAlatKesehatan,
        mAlatInfus: this.mAlatInfus,

        mRacikan: this.mRacikan,
        
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mFaskes: this.mFaskes,
        
        mHariLibur: this.mHariLibur,
        mRuangan: this.mRuangan,
        mDokterRanap: this.mDokterRanap,


        mDokterRujukan: this.mDokterRujukan,
        mPaketAlkes: this.mPaketAlkes,
        tindakanLainnya: this.tindakanLainnya,
        mPoliRujukan: this.mPoliRujukan,

        
        dataICD9RM: this.dataICD9RM,
        dataICD10RM: this.dataICD10RM,
      })
    },
    passToSubDokterTerapi(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        
        mICD9: this.mICD9,
        mICD10: this.mICD10,
        dataICD9: this.dataICD9,
        dataICD10: this.dataICD10,
        riwayatICD9: this.riwayatICD9,
        riwayatICD10: this.riwayatICD10,

        statusElektroterapi: this.statusElektroterapi,
        statusMekanoTerapi: this.statusMekanoTerapi,
        statusLatihan: this.statusLatihan,
        
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        
        mFaskes: this.mFaskes,
        mHariLibur: this.mHariLibur,
        
        mRuangan: this.mRuangan,
        mDokterRanap: this.mDokterRanap,

        mDokterRujukan: this.mDokterRujukan,
        mPaketAlkes: this.mPaketAlkes,
        tindakanLainnya: this.tindakanLainnya,
        mPoliRujukan: this.mPoliRujukan,
        
        dataICD9RM: this.dataICD9RM,
        dataICD10RM: this.dataICD10RM,
        
        resepDokter: this.resepDokter,
        resepPerawatData: this.resepPerawatData,

        mDosis: this.mDosis,
        mObatPreferensi: this.mObatPreferensi,
        mAlatKesehatan: this.mAlatKesehatan,
        mAlatInfus: this.mAlatInfus,

        mRacikan: this.mRacikan,
        mObat: this.mObat,
        mTypeSatuan: this.mTypeSatuan,
        riwayatObat: this.riwayatObat,
      })
    },
    passToSubGizi(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mGiziTindakan: this.mGiziTindakan,
        mGiziDiagnosa: this.mGiziDiagnosa,
        dataGiziTindakan: this.dataGiziTindakan,
        dataGiziDiagnosa: this.dataGiziDiagnosa,
        riwayatGiziTindakan: this.riwayatGiziTindakan,
        riwayatGiziDiagnosa: this.riwayatGiziDiagnosa,
      })
    },
    passToSubPPAFisio(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mKesadaran: this.mKesadaran,
        mAlatBantu: this.mAlatBantu,
        mHariLibur: this.mHariLibur,
      })
    },
    passToSubPPAFisioLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mKesadaran: this.mKesadaran,
      })
    },
    passToSubRadiologi(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataDokter: this.dataDokter
      })
    },
    passToSubLab(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataDokter: this.dataDokter
      })
    },
    passToSubRM(){
      return _.assign({
        dataRiwayatRMDokter: this.dataRiwayatRMDokter,
        dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
        dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
        dataRiwayatRMFisio: this.dataRiwayatRMFisio,
        dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
        dataRiwayatRMLab: this.dataRiwayatRMLab,
        dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,
        row: this.row,
        rowReg: this.rowReg,
        dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          
        historyKajianPerawat: this.historyKajianPerawat,
        historyKajianDokter: this.historyKajianDokter,
        historyKajianDokterTerapi: this.historyKajianDokterTerapi,
        historyKajianDokterGizi: this.historyKajianDokterGizi,
        historyKajianFisio: this.historyKajianFisio,
        historyKajianRadiologi: this.historyKajianRadiologi,
        historyKajianLab: this.historyKajianLab,
      })
    }
  },
  mounted(){
    this.$swal().close()
    setTimeout(()=>{
        this.initSticky()
    },1000)
    this.apiGet()
  },
  methods: {    
    openModalDocument(ar_id){
        this.documents = []
        this.$bvModal.show('daftarSurat')
        this.loadingDocument = true

        Gen.apiRest('/get/' + this.modulePage + '/daftar-surat-sehat-sakit', {
            params: { ar_id }
        }).then(res => {
            this.loadingDocument = false
            this.documents = res.data.data
        }).catch(() => {
            this.loadingDocument = false
        })
    },
    openModalSehatSakit(ar_id){
        this.dataModal = { ar_id: ar_id }
        this.$bvModal.show('modalSuratSehatSakit')
    },
    countDays(date1, date2) {
        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        const firstDate = new Date(date1)
        firstDate.setHours(0)
        const secondDate = new Date(date2)
        secondDate.setHours(0)
        return Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1
    },
    openModalSuratKeteranganSakit(ar_id){
        this.dataModal = {ar_id: ar_id}
        this.$bvModal.show('modalSuratSakit')
    },
    openModalSuratKeteranganSehat(ar_id){
        this.dataModal = {ar_id: ar_id}
        this.$bvModal.show('modalSuratSehat')
    },
    submitSuratSakit(){
        this.$refs.VFormSuratSakit.validate().then(success => {
            if(!success) return

            this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                this.$swal.showLoading()
                }
            })

            this.dataModal.total_days = this.countDays(this.dataModal.tanggal_awal, this.dataModal.tanggal_akhir)
            this.dataModal.tanggal_awal = moment(this.dataModal.tanggal_awal).format("YYYY-MM-DD")
            this.dataModal.tanggal_akhir = moment(this.dataModal.tanggal_akhir).format("YYYY-MM-DD")

            this.downloadReport('suratKeteranganSakit','Surat Keterangan Sakit', this.dataModal.ar_id, this.dataModal)
        })
    },
    submitSuratSehat(){
        this.$refs.VFormSuratSehat.validate().then(success => {
            if(!success) return

            this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                this.$swal.showLoading()
                }
            })
            this.downloadReport('suratKeteranganSehat','Surat Keterangan Sehat', this.dataModal.ar_id, this.dataModal)
        })
    },

    closeModal(id){
        this.$bvModal.hide(id)
        this.dataModal = {}
    },

    doOpenICare(){
        // open ICare
        // gak ada log soalnya response halaman icare ada expirednya
        this.isLoadICare = true
        let data = {
            param : this.bypassICare ? '0001719806578' : this.rowReg.arres_no_bpjs,
            kodedokter : this.bypassICare ? '446291' : this.rowReg.bu_dokter_bpjs_id
        }
        data.type = "get-data-icare",
        Gen.apiRest(
            "/do/"+'RekamMedis', 
            {
                data: data
            }, "POST"
        ).then(res=>{
            let resp = res.data
            this.isLoadICare = false
            if(resp.status == "fail"){
                this.$swal({
                    icon: 'warning',
                    text: resp.message,
                    title: "BPJS RESPONSE",
                })
            }else{
                window.open(resp.url, '_blank').focus()
            }
            console.log(res)
        }).catch(err=>{
            this.isLoadICare = false
            // this.$swal({
            //     icon: 'warning',
            //     title: 'Silakan Coba Kembali',
            // })
        })

    },
    changeTab(val){
        this.activeTabList = val
    },
    doOpenReport(){
        this.loadingOverlay = true                        
        let data = {
            regId : this.rowReg.ar_id
        }
        data.type = "get-report",
        Gen.apiRest(
            "/do/"+'RekamMedis', 
            {
                data: data
            }, "POST"
        ).then(res=>{
            this.loadingOverlay = false   
            this.openReport = true
            this.docDetail = res.data
        })

        let dataSuratPengantar = {
            type : "surat-pengantar",
            regId : this.rowReg.ar_id
        }
        Gen.apiRest(
            "/do/"+'RekamMedis', 
            {
                data: dataSuratPengantar
            }, "POST"
        ).then(res=>{
            this.pengantarRanap = res.data.pengantarRanap
            this.isShowPengantarRanap = res.data.isShowPengantarRanap
        })
        
    },
    doOpenTransferIntra(regID){
        this.regID = regID
        this.openPenetapanTransferIntra = true
    },
    doOpenDPJP(regID){
        this.regID = regID
        this.openPenetapanDPJP = true
    },
    doSubmitPengantar(){
        this.loadingOverlay = true                        
        let data = this.pengantarRanap
        data.type = "submit-pengantar",
    
        Gen.apiRest(
            "/do/"+'RekamMedis', 
            {
                data: data
            }, "POST"
        ).then(()=>{
            this.loadingOverlay = false   

            let self = this
            let dataPengantar = {
                exptype: 'pdf',
                type: "export",
                id: this.pengantarRanap.aranspr_id
            }
            // self.loadingOverlay = true
            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'suratPengantarRanap'}?token=IXs1029102asoaksoas102901290`,
                data: dataPengantar,
                cache: false,
                xhrFields: {
                    responseType: 'blob'
                },
                success: data => {
                    self.loadingOverlay = false
                    self.openPengantar = false
                    self.openReport = false

                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `${'Surat-Pengantar-Ranap'}-${moment().format("YYYY-MM-DD")}.pdf`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        })
    },

    doOpenPengantar(){
      this.openPengantar=true
    },

    doOpenHasilPenunjang(regID){
        this.regID = regID
        this.openHasilPenunjang = true
    },
      
    resepPerawat(v,regID){
        this.idRM = v.kajianPerawatId 
        this.regID = regID
        this.openResepPerawat = true
    },
    
    toSBAR(v,regID){
        this.regID = regID
        this.openSBAR = true
    },

    toBalanceCairan(v, regID, arm_id){
        this.$router.push({ 
            name: 'RekamMedis', 
            params: {
                    pageSlug: this.row.ap_id, 
                    rmNo: arm_id, 
                    typeKajian:'balance-cairan' 
            }, 
            query: {
                regId: this.$route.query.regId
            } 
        }).catch(()=>{})
    },

    downloadFile(id,val){
        let data = {exptype: 'xlsx', type: "export", id: id}
        let self = this
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${val}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `${'Form Permintaan Fisio'}-${moment().format("YYYY-MM-DD")}.pdf`
                link.click()
            },
            fail: data => {
                self.loadingOverlay = false
                alert('Not downloaded')
            }
        })
    },
    back(){
        this.$router.push({
            name:'Dashboard'
        })
    },
    deleteRiwayatRM(v){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'delete-data-rm', arm_id: v.arm_id}}
        ).then(res=>{
            this.loadingOverlay = false
            this.$swal({
                title: `Data Berhasil DiHapus`,
                icon: 'success',
            })
            this.apiGet()
        })
    },
    initSticky(){
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if(PageHeaderSticky){    
            const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
            if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                PageHeaderToggle.addEventListener('click', function(e){
                // e.preventDefault()
                PageHeaderSticky.classList.toggle('page-header-sticky-open')
                if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                    PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                }else{
                    PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                }
                })    
            } 
        }
    },

    doOpenRiwayatDokter(v){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-history-kajian-dokter', regId: v}}
        ).then(res=>{
            let resp = res.data
            this.doOpenRiwayatRM(resp.data)
        })
    },

    doOpenRiwayatRM(v){
        this.idRiwayatRM = v.arm_id
        this.openRwiayatRM = true
        this.finishLoadRwiayatRM = false

        this.dataRiwayatRMDokter = {}
        this.dataRiwayatRMDokterTerapi = {}
        this.dataRiwayatRMDokterGizi = {}
        this.dataRiwayatRMFisio = {}
        this.dataRiwayatRMRadiologi = {}
        this.dataRiwayatRMLab = {}
        this.dataRiwayatRMFarmasi = {}
        this.historyKajianPerawat = []
        this.historyKajianDokter = []
        this.historyKajianDokterTerapi = []
        this.historyKajianDokterGizi = []
        this.historyKajianFisio = []
        this.historyKajianRadiologi = []
        this.historyKajianLab = []

        const totalFinish = 9
        var currentFinish = 0

        const checkFinish = (selectTab = false, selectDatas = {}) => {
            currentFinish += 1
            if (currentFinish >= totalFinish) {
                this.finishLoadRwiayatRM = true
            } else if (selectTab === 3 && Object.keys(selectDatas||{}).length) {
                this.finishLoadRwiayatRM = true
            } else if (selectTab === 2 && Object.keys(selectDatas||{}).length) {
                this.finishLoadRwiayatRM = true
            } else if (selectTab === 1) {
                this.finishLoadRwiayatRM = true
            }
        }

        if(v.kajianDokter){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                if (this.idRiwayatRM === v.arm_id) {
                    this.dataRiwayatRMDokter = res.data
                    this.dataRiwayatRMDokter.Config = this.Config
                    
                    checkFinish(1)
                }
            }).catch(() => {
                if (this.idRiwayatRM === v.arm_id) {
                    checkFinish(1)
                }
            })
        }else{
            this.dataRiwayatRMDokter = {}
            checkFinish()
        }

        if(v.kajianDokterTerapi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokterTerapi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                if (this.idRiwayatRM === v.arm_id) {
                    this.dataRiwayatRMDokterTerapi = res.data
                    this.dataRiwayatRMDokterTerapi.Config = this.Config
                    
                    checkFinish(2, this.dataRiwayatRMDokterTerapi)
                }
            }).catch(() => {
                if (this.idRiwayatRM === v.arm_id) {
                    checkFinish()
                }
            })
        }else{
            this.dataRiwayatRMDokterTerapi = {}
            checkFinish()
        }

        if(v.kajianGizi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianGizi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                if (this.idRiwayatRM === v.arm_id) {
                    this.dataRiwayatRMDokterGizi = res.data
                    this.dataRiwayatRMDokterGizi.Config = this.Config
                    
                    checkFinish(3, this.dataRiwayatRMDokterGizi)
                }
            }).catch(() => {
                if (this.idRiwayatRM === v.arm_id) {
                    checkFinish()
                }
            })
        }else{
            this.dataRiwayatRMDokterGizi = {}
            checkFinish()
        }

        if(v.kajianFisio){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianFisio+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                if (this.idRiwayatRM === v.arm_id) {
                    this.dataRiwayatRMFisio = res.data
                    this.dataRiwayatRMFisio.Config = this.Config
                    
                    checkFinish()
                }
            }).catch(() => {
                if (this.idRiwayatRM === v.arm_id) {
                    checkFinish()
                }
            })
        }else{
            this.dataRiwayatRMFisio = {}
            checkFinish()
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                if (this.idRiwayatRM === v.arm_id) {
                    this.dataRiwayatRMRadiologi = res.data
                    this.dataRiwayatRMRadiologi.Config = this.Config
                    
                    checkFinish()
                }
            }).catch(() => {
                if (this.idRiwayatRM === v.arm_id) {
                    checkFinish()
                }
            })
        }else{
            this.dataRiwayatRMRadiologi = {}
            checkFinish()
        }

        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                if (this.idRiwayatRM === v.arm_id) {
                    this.dataRiwayatRMLab = res.data
                    this.dataRiwayatRMLab.Config = this.Config
                    
                    checkFinish()
                }
            }).catch(() => {
                if (this.idRiwayatRM === v.arm_id) {
                    checkFinish()
                }
            })
        }else{
            this.dataRiwayatRMLab = {}
            checkFinish()
        }

        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-obat-farmasi', regId: v.arm_ar_id}}
        ).then(res=>{
            if (this.idRiwayatRM === v.arm_id) {
                let resp = res.data
                this.dataRiwayatRMFarmasi = resp.data
                
                checkFinish()
            }
        }).catch(() => {
            if (this.idRiwayatRM === v.arm_id) {
                checkFinish()
            }
        })

        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-riwayat-rekam-medis', ar_id: v.arm_ar_id}}
        ).then(res=>{
            if (this.idRiwayatRM === v.arm_id) {
                let resp = res.data

                this.historyKajianPerawat = resp.historyKajianPerawat
                this.historyKajianDokter = resp.historyKajianDokter
                this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
                this.historyKajianDokterGizi = resp.historyKajianDokterGizi
                this.historyKajianFisio = resp.historyKajianFisio
                this.historyKajianRadiologi = resp.historyKajianRadiologi
                this.historyKajianLab = resp.historyKajianLab

                checkFinish()
            }
        }).catch(() => {
            if (this.idRiwayatRM === v.arm_id) {
                checkFinish()
            }
        })

        
        Gen.apiRest(
            "/get/"+'FollowUp'+'/'+this.row.ap_id+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            if (this.idRiwayatRM === v.arm_id) {
                this.dataRiwayatRMFollowUp = res.data
                this.dataRiwayatRMFollowUp.Config = this.Config
                
                checkFinish()
            }
        }).catch(() => {
            if (this.idRiwayatRM === v.arm_id) {
                checkFinish()
            }
        })
    },

    viewKajianPerawat(id=null){
        this.loadingOverlay = true
        if(!id){
            id = this.row.kajianPerawat
        }

        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+id+'?regId='+this.$route.query.regId, 
        ).then(res=>{
            this.loadingOverlay = false
            this.dataRiwyatRMPerawat = res.data
            this.dataRiwyatRMPerawat.Config = this.Config
            this.dataRiwyatRMPerawat.row['isDok'] = true
            this.dataRiwyatRMPerawatIS = res.data.row.arm_is_kajian_awal
            this.openDetailKajianPerawat = true
        })
    },

      toDetail(v, ar_id) {
        this.idKajian = v.arm_id
        this.finishLoadKajian = false
        this.openDetailKajian = true

        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianPerawatId+'?regId='+ar_id, 
        ).then(res=>{
            if (this.idKajian === v.arm_id) {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })

                this.$nextTick(() => {
                    this.finishLoadKajian = true
                })
            }
        })
    },
    doRefreshData(){
        this.apiGet()
        window.scrollTo(0,0)
    },
    otherConditional(v){
        return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date) && v.ar_status != 7 
    },
    to24Hours(date){
        return moment(date).add(48, 'hours').format('YYYY-MM-DD')
    },
    apiGet(params = {}, page = 1){
        
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url+'?isData=1', 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            this.pageNow = page
            this.last_page = this.data.last_page
            
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.params.rmNo){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
    loadMore(){
        this.pageNow = this.pageNow + 1
        this.loadingOverlay = true

        let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
        
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query

        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: this.pageNow}, paramsQuery)
            }
        ).then(res=>{
            this.loadingOverlay = false
            let newData = res.data.data.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.data.data.push(newData[i])        
            }
            if(!this.isFound){
                this.$router.push({name : this.$route.name}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })
    },
    toKajianAwal(id='add'){
        if(this.rowReg.ar_status == this.sPerawat){
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-awal',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'awal' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
    
                this.$socket.emit('refresh_data', {to : this.uPerawat})

            })

        }else if(this.rowReg.ar_status == this.sDokter){
            // membuat initial draft untuk kajian dokter
            this.$swal({
                html: 'Membuat data draft...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    this.$swal.showLoading()
                }
            })

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'Y'
                }}, 
                "POST"
            ).then(res=>{
                this.$swal({
                    html: 'Redirecting...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        this.$swal.showLoading()
                    }
                })

                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokter})
            })

        }else if(this.rowReg.ar_status == this.sFisio){
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter-terapi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'Y'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter-terapi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokterFisio})
            })

        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 9) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 9)){
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-fisio-awal',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'Y'}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-awal-fisio' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uFisio})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })

        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 13) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 13)){
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-radiologi',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'Y'}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-radiologi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
           
                this.$socket.emit('refresh_data', {to : this.uRadiologi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })

        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 12) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 12)){
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-lab',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'Y'}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-lab' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
           
                this.$socket.emit('refresh_data', {to : this.uLab})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })

        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 6) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 6)){
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-gizi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'Y'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-gizi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uDokterGizi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }
    },
    toKajianLanjut(aka_id,arm_id){
        this.loadingOverlay = true
        if(this.rowReg.ar_status == this.sPerawat){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-lanjutan',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    aka_id: aka_id,
                    refer_id: arm_id
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'lanjutan' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uPerawat})
            })
        }else if(this.rowReg.ar_status == this.sDokter){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'N',
                    refer_id: arm_id
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokter})
            })
        }else if(this.rowReg.ar_status == this.sFisio){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter-terapi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'N',
                    refer_id: arm_id
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter-terapi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokterFisio})
            })           
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 9) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 9)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-fisio-lanjutan',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'N',refer_id: arm_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-lanjutan-fisio' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uFisio})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 13) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 13)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-radiologi',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'N',refer_id: arm_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-radiologi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
            
                this.$socket.emit('refresh_data', {to : this.uRadiologi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 12) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 12)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-lab',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'N',refer_id: arm_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-lab' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uLab})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 6) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 6)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-gizi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'N'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-gizi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uDokterGizi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }
    },
    redirect(v){
        if(v.arm_status == this.sPerawat){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sDokter){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-dokter'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sFisio){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-dokter-terapi'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if((v.arm_status == this.sPPA && this.user.levelId == 6) || (v.arm_status == this.sPPA && this.$route.query.byPassLevel == 6)){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-gizi'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if((v.arm_status == this.sPPA && this.user.levelId == 9) || (v.arm_status == this.sPPA && this.$route.query.byPassLevel == 9)){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: v.arm_is_kajian_awal == 'Y' ? 'kajian-awal-fisio' : 'kajian-lanjutan-fisio'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if((v.arm_status == this.sPPA && this.user.levelId == 13) || (v.arm_status == this.sPPA &&  this.$route.query.byPassLevel == 13)){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-radiologi'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if((v.arm_status == this.sPPA && this.user.levelId == 12) || (v.arm_status == this.sPPA &&  this.$route.query.byPassLevel == 12)){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-lab'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }
    },
    downloadReport(link,name, reg_id = null, payload = null){
        let data = {
            exptype: 'pdf', type: "export", pasienId: this.$route.params.pageSlug, regId: reg_id || this.$route.query.regId
        }

        if(payload){
            data.payload = payload
        }

        let self = this

        $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${link}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var anchor = document.createElement('a')
                    anchor.href = window.URL.createObjectURL(data)
                    anchor.download = `${name}-${moment().format("YYYY-MM-DD")}.pdf`
                    this.$swal.close()
                    anchor.click()

                    if(link == 'suratKeteranganSakit' || link == 'suratKeteranganSehat'){
                        const id = link == 'suratKeteranganSakit' ? 'modalSuratSakit' : 'modalSuratSehat'
                        this.closeModal(id)
                    }

                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
        })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>