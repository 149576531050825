<template>
	<div class="card">
		<div class="card-header bg-info">
			<h5 class="card-title font-weight-semibold">Get Up and Go</h5>
		</div>
		<div class="card-body">
			<div class="form-group">
				<div class="row">
					<div class="col-md-12">
						<table class="table table-bordered table-striped table-hover table-sm patient-table">
							<thead>
								<tr>
									<th>PARAMETER</th>
									<th>SKOR</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in (isParent.row.arj_getup_go||[])" :key="k">
									<td>
										<b-form-checkbox @input="inputTindakan()" :value="v.valueVal" :unchecked-value="null"
											v-model="isParent.row.arj_getup_go[k]['value']" class="form-check-input-styled"
											name="radio-inline-left">{{v.name}}</b-form-checkbox>
									</td>
									<td>
										{{v.valueVal}}
									</td>
								</tr>
								<tr>
									<td>
										<b-form-checkbox @input="inputTindakan()" v-model="isParent.row.isSkipJatuh"
											class="form-check-input-styled" :value="true" name="radio-inline-left">Tidak Ada Data Resiko Jatuh
										</b-form-checkbox>
									</td>
									<td>
										0
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr class="table-info">
									<td colspan="99" class="text-info-700">
										<span>NILAI SKALA RESIKO JATUH: {{ isParent.hitungJatuh }}</span>
										<div class="d-inline-block border-left ml-2 pl-2 font-weight-semibold">
											<span v-if="isParent.hitungJatuh < 4">RENDAH</span>
											<span v-else>Tinggi</span>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-lg-7">
						<table class="table table-bordered table-striped table-hover table-sm patient-table">
							<thead>
								<tr>
									<th>HASIL</th>
									<th>Tindakan</th>
									<th>Aksi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in (isParent.row.arj_tindakan_tambahan||[])" :key="k+'tindakan'">
									<td v-if="k==0" :rowspan="(isParent.row.arj_tindakan_tambahan||[]).length">{{isParent.row.arj_hasil}}
									</td>
									<td>
										<span
											v-if="(v == 'Pasang pita kuning'||v == 'edukasi') || isParent.row.arj_hasil !== 'Beresiko Tinggi'">{{v}}</span>
										<span v-else>
											<b-form-input v-model="isParent.row.arj_tindakan_tambahan[k]" type="text" name="name"
												class="form-control" />
										</span>
									</td>
									<td>
										<span
											v-if="(v == 'Pasang pita kuning'||v == 'edukasi') || isParent.row.arj_hasil !== 'Beresiko Tinggi'">
											- </span>
										<a v-else href="javascript:;" class="list-icons-item"
											@click="(isParent.row.arj_tindakan_tambahan||[]).splice(k,1)" data-toggle="tooltip"
											data-placement="top" title="Delete"><i class="icon-bin"></i></a>
									</td>
								</tr>
								<tr v-if="isParent.hitungJatuh >= 4">
									<td colspan="99" class="text-center">
										<a href="javascript:;" @click="(isParent.row.arj_tindakan_tambahan||[]).push('')"
											class="btn btn-outline-primary btn-sm font-weight-semibold">Tambah Tindakan</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// const moment = require('moment')

export default{
    extends: GlobalVue,
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        inputTindakan(){
            let value = this.isParent.hitungJatuh 
            if(value >= 4){
                this.isParent.row.arj_tindakan_tambahan = ['Pasang pita kuning','edukasi']
            }else{
                this.isParent.row.arj_tindakan_tambahan = ['Tidak Ada Tindakan']                
            }
        }
    }
}
</script>