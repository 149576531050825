<template>
    <div class="content">

        <validation-observer ref="VFormDokterTerapi">
        <b-form @submit.prevent="doSubmitDokterTerapi">
        <div class="card">
            <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Pengkajian Fisioterapi</h6>
            </div>
            <div class="card-body p-3">
            <div class="row">
                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Anamnesa</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div>
                            <div>
                            <div >
                                <b-form-radio-group
                                    :options="Config.mr.configAnamnesa"
                                    v-model="row['apdf_anamnesa']"
                                />
                                <VValidate 
                                    name="Anamnesa" 
                                    v-model="row.apdf_anamnesa" 
                                    :rules="toValidate(mrValidation.apdf_anamnesa)"
                                />
                            </div>
                            <div v-if="row.apdf_anamnesa == 2" >
                                <div class="ml-2 mt-2">
                                <b-form-input v-model="row.apdf_anamnesa_lainnya" type="text" name="perKeluhan" id="perKeluhan" class="form-control"
                                    placeholder="Hubungan" />
                                </div>
                                
                                <VValidate 
                                    name="Anamnesa Lainnya" 
                                    v-model="row.apdf_anamnesa_lainnya" 
                                    :rules="toValidate(mrValidation.apdf_anamnesa_lainnya)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div class="row mt-3">
                        
                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Penyakit Sekarang <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_penyakit_sekarang']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Penyakit Sekarang" 
                                v-model="row.apdf_riwayat_penyakit_sekarang" 
                                :rules="toValidate(mrValidation.apdf_riwayat_penyakit_sekarang)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Diagnosis Klinis <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_alergi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Diagnosis Klinis" 
                                v-model="row.apdf_riwayat_alergi" 
                                :rules="toValidate(mrValidation.apdf_riwayat_alergi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Penyakit Dahulu <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_penyakit_dahulu']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Penyakit Dahulu" 
                                v-model="row.apdf_riwayat_penyakit_dahulu" 
                                :rules="toValidate(mrValidation.apdf_riwayat_penyakit_dahulu)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Pengobatan <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_pengobatan']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Pengobatan" 
                                v-model="row.apdf_riwayat_pengobatan" 
                                :rules="toValidate(mrValidation.apdf_riwayat_pengobatan)"
                            />
                        </div>
                        </div>


                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Penyakit Keluarga <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_penyakit_keluarga']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Penyakit Keluarga" 
                                v-model="row.apdf_riwayat_penyakit_keluarga" 
                                :rules="toValidate(mrValidation.apdf_riwayat_penyakit_keluarga)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Pekerjaan, Sosial Ekonomi, Kejiawaan dan Kebiasaan <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_pekerjaan']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Pekerjaan, Sosial Ekonomi, Kejiawaan dan Kebiasaan" 
                                v-model="row.apdf_riwayat_pekerjaan" 
                                :rules="toValidate(mrValidation.apdf_riwayat_pekerjaan)"
                            />
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Gizi</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div>
                            <div>
                            <div >
                                <b-form-radio-group
                                    :options="Config.mr.statusGizi"
                                    v-model="row['apdf_status_gizi']"
                                />
                                <VValidate 
                                    name="Status Gizi" 
                                    v-model="row.apdf_status_gizi" 
                                    :rules="toValidate(mrValidation.apdf_status_gizi)"
                                />
                                <div class="ml-2 mt-2" v-if="row.apdf_status_gizi == 'L'" >
                                    <b-form-input v-model="row.apdf_status_gizi_lainnya" type="text" name="perKeluhan" id="perKeluhan" class="form-control"
                                    placeholder="Sebutkan" />
                                    
                                    <VValidate 
                                        name="Gizi Lainnya" 
                                        v-model="row.apdf_status_gizi_lainnya" 
                                        :rules="toValidate(mrValidation.apdf_status_gizi_lainnya)"
                                    />
                                </div>
                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik (Termasuk pemeriksan Neurologis dan
                        Muskoloskeletal)</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                            <div class="form-group">
                                <label>Keluhan Utama <small class="txt_mandatory">*</small></label>

                                <b-form-textarea v-model="row.apdf_keluhan" :formatter="$parent.normalText"
                                name="perRiwayat" id="perRiwayat" rows="4" class="form-control"
                                placeholder="Keluhan Utama dari pasien">
                                </b-form-textarea>

                                <VValidate name="Keluhan Utama" v-model="row.apdf_keluhan"
                                :rules="toValidate(mrValidation.apdf_keluhan)" />
                            </div>
                            </div>
                            <div class="col-md-12">
                            <div class="wrap_anatomy_info">
                                <label for="perKeluhan">Lokasi Nyeri<strong class="text-danger">*</strong></label>
                                
                                <div class="position-relative" id="anatomiNyeri" @click.stop.prevent>
                                <div class="row">
                                    <div class="col-md-6">
                                    <div class="body_wrapper">
                                        <img :src="$parent.assetLocal('img/front-body.png')" class="img-fluid" alt="">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="body_wrapper">
                                        <img :src="$parent.assetLocal('img/back-body.png')" class="img-fluid" alt="">
                                    </div>
                                    </div>
                                </div>
                                <div class="anatomy-info" v-for="(v,k) in row.apdf_lokasi_nyeri||[]" :key="k"
                                    :style="`top:${v.y}%;left:${v.x}%`">
                                    <a href="javascript:;" @click="openAnat(k)" class="ai-point" v-b-tooltip.hover :html="true"
                                    :title="v.value"></a>
                                    <div :class="v.isOpen?'anatomy-info-input d-block':'anatomy-info-input'" @click.stop.prevent>
                                    <textarea v-model="v.value" name="anatomyInput" id="anatomyInput" rows="2"
                                        class="form-control form-control-sm"></textarea>
                                    <div class="mt-2 text-right">
            
                                        <a href="javascript:;" class="btn btn-sm btn-danger" @click.prevent.stop="removeAnat(k)">Hapus</a>
                                        <a href="javascript:;" @click.prevent.stop="closeAnat(k)" class="btn btn-sm btn-success">Simpan</a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group mb-3 mt-3">
                                <label for="perKeluhan">Apakah nyerinya berpindah dari satu ke tempat lain<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                <b-form-radio-group
                                    :options="Config.mr.configNilaiNyeri"
                                    v-model="row['apdf_nilai_nyeri']"
                                />
                                <VValidate 
                                    name="Rasa Nyeri Pindah" 
                                    v-model="row.apdf_nilai_nyeri" 
                                    :rules="toValidate(mrValidation.apdf_nilai_nyeri)"
                                />
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label for="perKeluhan">Berapa Lama nyeri dirasakan<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                <b-form-radio-group
                                    :options="Config.mr.configLamaNyeri"
                                    v-model="row['apdf_lama_nyeri']"
                                />
                                <VValidate 
                                    name="Lama Nyeri" 
                                    v-model="row.apdf_lama_nyeri" 
                                    :rules="toValidate(mrValidation.apdf_lama_nyeri)"
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">

                        <div class="form-group mb-3 mt-3">
                            <label for="perKeluhan">Rasa Nyeri<strong class="text-danger">*</strong></label>
                            <div class="row">
                                <b-form-radio-group
                                    class="col-md-5 custom-block"
                                    :options="Config.mr.statusNyeri"
                                    v-model="row['apdf_rasa_nyeri']"
                                />
                                <VValidate 
                                    name="Rasa Nyeri" 
                                    v-model="row.apdf_rasa_nyeri" 
                                    :rules="toValidate(mrValidation.apdf_rasa_nyeri)"
                                />
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="perKeluhan">Apakah yang membuat nyeri berkurang atau bertambah?<strong
                                class="text-danger">*</strong></label>
                            <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                    <th colspan="2" class="table-light">Kompres:</th>
                                    </tr>
                                    <tr>
                                    <td v-for="v,k in Config.mr.kompres||[]" :key="k+'kompress'" >
                                        <div class="form-check mb-2  form-check-inline align-middle">
                                            <b-form-radio v-model="row.apdf_kompres" name="kompress" :value="v.value">{{v.text}}</b-form-radio>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <th colspan="2" class="table-light">Aktifitas:</th>
                                    </tr>
                                    <tr>
                                    <td v-for="v,k in Config.mr.aktifitas||[]" :key="k+'aktifitas'" >
                                        <div class="form-check mb-2  form-check-inline align-middle">
                                            <b-form-radio v-model="row.apdf_aktifitas" name="aktifitas" :value="v.value">{{v.text}}</b-form-radio>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <VValidate 
                                    name="Kompres" 
                                    v-model="row.apdf_kompres" 
                                    :rules="toValidate(mrValidation.apdf_kompres)"
                                />
                                <VValidate 
                                    name="Aktivitas" 
                                    v-model="row.apdf_aktifitas" 
                                    :rules="toValidate(mrValidation.apdf_aktifitas)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                <div class="card border shadow-0">
                    <div class="bg-light card-header py-2">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                        <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                        <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                        </a>
                    </div>
                    </div>
                    <div class="card-body">
                    <div class="form-group mb-0">
                        <div class="input-group">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                v-model="inputICD10"
                                @search="filterICD10($event,0)"
                                @input="selectICD10(inputICD10)"
                                :options="mICD10" label="text"
                                :clearable="true" :reduce="v=>v.value">
                                
                                <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="inputICD10Name">
                                    {{inputICD10Name||"-"}}
                                </span>
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                </template>
                                <template #list-footer v-if="(mICD10||[]).length >= 1">
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mICD10[0].currentPage}} dari
                                        {{ceilData(mICD10[0].totalRows/5)}}</small>
                                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                                </template>
                            </v-select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                    <thead>
                        <tr>
                        <th>Item ICD-10 dipilih</th>
                        <th width="64">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                        <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                        <td>
                            <a href="javascript:;" @click="removedataICD10(v.api10_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                            </a>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <div class="card-body py-2" v-else>
                    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                    </div>
                    <div class="card-body pt-2">
                    <div class="form-group mb-0">
                        <label for="inputManualDiagnose">
                        Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(dataICD10||[]).length">*</strong>
                        <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                        </label>
                        <b-textarea v-model="row.apdf_diagnosa" name=""
                        id="" rows="3" class="form-control"></b-textarea>
                        
                    <VValidate v-if="!(dataICD10||[]).length" :name="`Diagnosa`" v-model="row.apdf_diagnosa"
                                  :rules="{required:1, min:2, max:512}" />
                    </div>
                    </div>
                </div>
                </div>
    
                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Daftar masalah kedokteran fisik dan rehabilitas</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Mobilisasi  </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_mobilisasi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Mobilisasi" 
                                v-model="row.apdf_mobilisasi" 
                                :rules="toValidate(mrValidation.apdf_mobilisasi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">ADL</label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_adl']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="ADL" 
                                v-model="row.apdf_adl" 
                                :rules="toValidate(mrValidation.apdf_adl)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Komunikasi </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_komunikasi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Komunikasi " 
                                v-model="row.apdf_komunikasi" 
                                :rules="toValidate(mrValidation.apdf_komunikasi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Psikologi </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_psikologi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Psikologi " 
                                v-model="row.apdf_psikologi" 
                                :rules="toValidate(mrValidation.apdf_psikologi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Sosial Ekonomi </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_sosial_ekonomi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Sosial Ekonomi " 
                                v-model="row.apdf_sosial_ekonomi" 
                                :rules="toValidate(mrValidation.apdf_sosial_ekonomi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Vokasional </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_vokasional']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Vokasional " 
                                v-model="row.apdf_vokasional" 
                                :rules="toValidate(mrValidation.apdf_vokasional)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Lain-lain</label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_lainnya']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Lain-lain" 
                                v-model="row.apdf_lainnya" 
                                :rules="toValidate(mrValidation.apdf_lainnya)"
                            />
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Kedokteran fisik dan rehabilitasi</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div class="form-group">
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_rencana_kedokteran']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Rencana Kedokteran fisik dan rehabilitasi" 
                                v-model="row.apdf_rencana_kedokteran" 
                                :rules="toValidate(mrValidation.apdf_rencana_kedokteran)"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana pemeriksaan penunjang</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div class="form-group">
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_rencana_pemerikasaan_penunjang']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Rencana pemeriksaan penunjang"
                                v-model="row.apdf_rencana_pemerikasaan_penunjang" 
                                :rules="toValidate(mrValidation.apdf_rencana_pemerikasaan_penunjang)"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12">
                    <div class="card">
                    <div class="card-header bg_head_panel">
                        <div class="row">
                            <div class="col-md-11">                                    
                            <h6 class="card-title font-weight-semibold mt-1">Resep</h6>
                            </div>
                            <div class="col-md-auto" v-if="isPoliAdj">
                            <!--
                            <div class="form-group mb-0">
                                <button type="button" @click="getResepPerawat()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang Resep Perawat" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                            </div>
                            -->
                            </div>
                        </div>
                    </div>

                    <table class="table table-striped table-sm table-hover table-bordered">
                        <thead>
                        <tr>
                            <th width="48">Aksi</th>
                            <th width="160">Jenis</th>
                            <th width="320">Nama</th>
                            <th width="150">Jumlah</th>
                            <th width="210">Frekuensi</th>
                            <th>Keterangan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                            <td>
                            <a href="javascript:;" @click="removeObat(v,k)"
                                class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                                class="icon-trash"></i></a>
                            </td>
                            <td>
                            <v-select placeholder="Pilih Item" v-model="resepDokter[k].ard_jenis"
                                :options="Config.mr.configResep" label="text"
                                @input="changeJenis($event,k)" :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                                v-model="resepDokter[k].ard_jenis" :rules="{required : 1}" />
                            </td>
                            <td>
                            <div class="d-flex">
                                <template v-if="resepDokter[k]['ard_jenis'] == 1">
                                <v-select style="flex: 1;" class="med-selection select-paging"
                                    placeholder="Pilih Item"
                                    :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                                    @open="countLen" @search="filterObat($event,k);countLen();"
                                    v-model="resepDokter[k].ard_item_id" :options="mObatPreferensi"
                                    label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                                    :reduce="v=>v.value"
                                    :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                    <template slot="selected-option">
                                    <span>{{ v.ard_nama }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                    <span v-else>{{ option.text }}</span>
                                    </template>
                                    <template #list-footer>
                                    <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                        <b-button
                                            @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{v.currentPage}} dari
                                            {{ceilData(v.totalRows/10)}}</small>
                                        <b-button
                                            @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                    </li>
                                    </template>
                                </v-select>
                                <br />
                                </template>

                                <template v-else-if="resepDokter[k]['ard_jenis'] == 2">
                                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                    placeholder="Pilih Item" v-model="resepDokter[k].ard_item_id"
                                    :options="mAlatKesehatan" label="text"
                                    @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>

                                <template v-else-if="resepDokter[k]['ard_jenis'] == 3">
                                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                    placeholder="Pilih Item" v-model="resepDokter[k].ard_item_id"
                                    :options="mRacikan" label="text"
                                    @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>
                                
                                <template v-else-if="resepDokter[k]['ard_jenis'] == 4">
                                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                    placeholder="Pilih Item" v-model="resepDokter[k].ard_item_id"
                                    :options="mPaketAlkes" label="text"
                                    @input="selectJumlahSatuanPaketAlkes($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>
                                
                                <a href="javascript:;" v-if="resepDokter[k]['ard_jenis'] !== 3 && resepDokter[k]['ard_jenis'] !== 4"
                                @click="resepDokter[k]['ard_item_id'] = 99999; resepDokter[k]['ard_nama'] = 'Lainnya'"
                                v-b-tooltip.hover="'Pilih Lainnya'"
                                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                    class="icon-plus-circle2"></i></a>
                            </div>
                            <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                                v-model="resepDokter[k]['ard_item_id']" :rules="{required : 1}" />

                            <div v-if="resepDokter[k]['ard_item_id'] == 99999">
                                <b-form-input type="text" v-model="resepDokter[k]['ard_item_lainnya']"
                                class="form-control" id="itemCount" />
                                <VValidate :name="'Item Lainnya '+(k+1)"
                                message="Nama Item Lainnya Harus Diisi"
                                v-model="resepDokter[k]['ard_item_lainnya']" :rules="{required : 1}" />
                            </div>

                            <a v-if="resepDokter[k].ard_jenis == 3 && resepDokter[k]['ard_item_id']"
                                href="javascript:;" @click="toRacikan(v,true)" data-popup="tooltip" title=""
                                data-toggle="modal" data-target="#modalRacikanDetail"
                                class="font-weight-semibold border-bottom"
                                data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                            <a v-if="resepDokter[k].ard_jenis == 4 && resepDokter[k]['ard_item_id']"
                                href="javascript:;" @click="toPaketAlkes(v,true)" data-popup="tooltip" title=""
                                data-toggle="modal" data-target="#modalRacikanDetail"
                                class="font-weight-semibold border-bottom"
                                data-original-title="Lihat Detail Alkes">Lihat Detail Alkes</a>

                            <td>
                            <div class="input-group">
                                <b-form-input type="text" v-model="resepDokter[k]['ard_jumlah']"
                                :formatter="$parent.number" class="form-control" id="itemCount" />
                                <div class="input-group-append">
                                <span class="input-group-text">{{resepDokter[k]['ard_satuan']}}</span>
                                </div>
                            </div>

                            <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                                v-model="resepDokter[k]['ard_jumlah']" :rules="{required : 1}" />
                            </td>
                            <td>
                            <span class="d-flex">
                                <v-select style="flex: 1;" class="med-selection"
                                placeholder="Pilih Frekuensi" v-model="resepDokter[k].ard_frekuensi"
                                :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                                </v-select>
                                <a href="javascript:;" @click="resepDokter[k]['ard_frekuensi'] = 99999"
                                v-b-tooltip.hover="'Pilih Lainnya'"
                                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                    class="icon-plus-circle2"></i></a>
                            </span>
                            <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                                v-model="resepDokter[k]['ard_frekuensi']" :rules="{required : 1}" />

                            <div v-if="resepDokter[k]['ard_frekuensi'] == 99999">
                                <b-form-input type="text"
                                v-model="resepDokter[k]['ard_frekuensi_lainnya']" class="form-control"
                                id="itemCount" />
                                <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                                message="Frekuensi Lainnya Harus Diisi"
                                v-model="resepDokter[k]['ard_frekuensi_lainnya']"
                                :rules="{required : 1}" />
                            </div>
                            </td>
                            <td>
                            <b-textarea v-model="resepDokter[k].ard_keterangan" name="itemNotes"
                                id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                                class="form-control"></b-textarea>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot v-if="canEditFarmasi">
                        <tr>
                            <td class="text-center" colspan="99">
                            <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                                data-toggle="modal" data-target="#modalResep">
                                <i class="icon-plus2 mr-1 align-middle"></i>
                                <span class="align-middle">Tambah Resep</span>
                            </a>
                            
                            <a href="javascript:;" @click="openTemplateResep" v-if="row.is_have_template_resep" class="btn alpha-warning ml-1"
                                data-toggle="modal" data-target="#modalResep">
                                <i class="fas fa-receipt mr-1 align-middle"></i>
                                <span class="align-middle">Ambil Dari Template</span>
                            </a>

                            <a href="javascript:;" v-if="riwayatObat.length"
                                @click="openRiwayatObat = true" class="btn alpha-orange ml-1"
                                data-toggle="modal" data-target="#modalResepRiwayat">
                                <i class="icon-plus2 mr-1 align-middle"></i>
                                <span class="align-middle">Pilih dari Riwayat</span>
                            </a>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    
                    </div>
                </div>


                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Dirujuk</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div>
                            <div >
                                <b-form-radio-group
                                    :options="Config.mr.statusDirujuk"
                                    v-model="row['apdf_dirujuk']"
                                />
                                <VValidate 
                                    name="Dirujuk" 
                                    v-model="row.apdf_dirujuk" 
                                    :rules="toValidate(mrValidation.apdf_dirujuk)"
                                />
                                <div class="ml-2 mt-2" v-if="row.apdf_dirujuk == 'L'" >
                                    <b-form-input v-model="row.apdf_dirujuk_lainnya" type="text" name="perKeluhan" id="perKeluhan" class="form-control"
                                    placeholder="Sebutkan" />
                                    
                                    <VValidate 
                                        name="Gizi Lainnya" 
                                        v-model="row.apdf_dirujuk_lainnya" 
                                        :rules="toValidate(mrValidation.apdf_dirujuk_lainnya)"
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>

                <div class="col-md-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-4">
                        <div class="wrap_line">
                            <h3>Elektroterapi</h3>
                            <div class="form-row">
                                <b-form-checkbox-group 
                                    v-model="row.apdf_rencana_tindakan_elektroterapi"
                                    name="Elektroterapi"
                                    :options="statusElektroterapi"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-4">
                        <div class="wrap_line">
                            <h3>Mekanoterapi</h3>
                            <div class="form-row">
                                <b-form-checkbox-group 
                                    v-model="row.apdf_rencana_tindakan_mekanoterapi"
                                    name="Mekanoterapi"
                                    :options="statusMekanoTerapi"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-4">
                        <div class="wrap_line">
                            <h3>Latihan - latihan</h3>
                            <div class="form-row">
                                <b-form-checkbox-group 
                                    v-model="row.apdf_rencana_tindakan_latihan"
                                    name="Latihan - latihan"
                                    :options="statusLatihan"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                <div class="card border shadow-0">
                    <div class="bg-light card-header py-2">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                        <a href="javascript:;" v-if="riwayatICD9.length" @click="openriwayatICD9 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                        <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                        </a>
                    </div>
                    </div>
                    <div class="card-body">
                    <div class="form-group mb-0">
                        <div class="input-group">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                v-model="inputICD9"
                                @search="filterICD9($event,0)"
                                @input="selectICD9(inputICD9)"
                                :options="mICD9" label="text"
                                :clearable="true" :reduce="v=>v.value">
                                
                                <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="inputICD9Name">
                                    {{inputICD9Name||"-"}}
                                </span>
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                </template>
                                <template #list-footer v-if="(mICD9||[]).length >= 1">
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mICD9[0].currentPage}} dari
                                        {{ceilData(mICD9[0].totalRows/5)}}</small>
                                    <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                                </template>
                            </v-select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                    <thead>
                        <tr>
                        <th>Item ICD-9 dipilih</th>
                        <th width="64">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                        <td><span>{{v.mi9_name||"-"}}<span class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                        <td>
                            <a href="javascript:;" @click="removedataICD9(v.api9_id, k, v.api9_informed_consent)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                            </a>

                            <a href="javascript:;" @click="openInformConsent(v)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo"  v-b-tooltip.hover :title="v.api9_informed_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                            <i class="icon-file-check2"></i>
                            </a>

                            <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.api9_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                            <i class="icon-file-eye2"></i>
                            </a>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <div class="card-body py-2" v-else>
                    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                    </div>
                    
                    <table class="table table-bordered table-sm">
                    <tbody>
                    <tr>
                        <td style="width:95%;">
                        <div class="form-group mb-0">
                            <label for="inputManualDiagnose">
                            Tindakan Lainnya {{(tindakanLainnya||[]).length ? '#1' : ''}}
                            <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                            </label>
                            <b-textarea v-model="row.apdf_tindakan" name=""
                            id="" rows="3" class="form-control"></b-textarea>
                        </div>
                        </td>
                        <td>
                        
                        <a href="javascript:;" v-b-tooltip.hover :title="'Hapus Inform Consent'" v-if="row.apdf_inform_consent_id" @click="removeICDForm()" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger"><i class="icon-trash-alt"></i> 
                        <!--
                        Hapus Inform Consent
                        -->
                        </a>

                        <a href="javascript:;" v-b-tooltip.hover :title="row.apdf_inform_consent_id ? 'Edit Informed Consent' : 'Tambah Informed Consent'" v-if="row.apdf_tindakan" @click="openInformConsentForm"  class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> 
                        <!--
                        Edit Inform Consent
                        -->
                        </a>
                        
                        <a href="javascript:;" v-b-tooltip.hover :title="'Lihat Inform Consent'" @click="openInformConsentDetailForm" v-if="row.apdf_inform_consent_id" class="btn btn-sm btn-icon alpha-success text-success-800 border-success"><i class="icon-file-eye2"></i> 
                        <!--
                        Lihat Inform Consent
                        -->
                        </a>

                        </td>
                    </tr>
                    <tr v-for="(v,k) in (tindakanLainnya||[])" :key="k">
                        <td style="width:95%;">
                        <div class="form-group mb-0">
                            <label for="inputManualDiagnose">
                            Tindakan Lainnya #{{((k+1)+1)}}
                            <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                            </label>
                            <b-textarea v-model="tindakanLainnya[k]['artla_tindakan']" name=""
                            id="" rows="3" class="form-control"></b-textarea>
                        </div>
                        </td>
                        <td>
                        <a href="javascript:;" v-b-tooltip.hover :title="'Hapus Inform Consent'" @click="removeICDLainnya(v,k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger"><i class="icon-trash-alt"></i> 
                        <!--
                        Hapus Inform Consent
                        -->
                        </a>

                        <a href="javascript:;" v-b-tooltip.hover :title="tindakanLainnya[k]['artla_inform_consent'] ? 'Edit Informed Consent' : 'Tambah Informed Consent'" v-if="tindakanLainnya[k]['artla_tindakan']" @click="openInformConsentFormV2(v,k)"  class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> 
                        <!--
                        Edit Inform Consent
                        -->
                        </a>
                        
                        <a href="javascript:;" v-b-tooltip.hover :title="'Lihat Inform Consent'" @click="openInformConsentDetailFormV2(v,k)" v-if="tindakanLainnya[k]['artla_inform_consent']" class="btn btn-sm btn-icon alpha-success text-success-800 border-success"><i class="icon-file-eye2"></i> 
                        <!--
                        Lihat Inform Consent
                        -->
                        </a>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="text-center">
                        <a @click="addTindakanLainnya" href="javascript:;" class="btn btn-sm alpha-primary border-primary text-primary-800"><i class="icon-add mr-2 align-middle"></i><span class="align-middle">Tambah Inform Consent</span></a>
                        </td>
                    </tr>
                    </tbody>
                    </table>

                    <!--
                    <div class="card-body pt-2">
                    <div class="form-group mb-0">
                        <label for="inputManualDiagnose">
                        Tindakan Lainnya
                        <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                        </label>
                        <b-textarea v-model="row.apdf_tindakan" name=""
                        id="" rows="3" class="form-control"></b-textarea>
                    </div>
                    </div>
                    -->
                </div>
                </div>
            </div>
            </div>
            

            <div class="col-12">
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                </div>
                
                <div class="card-body p-3">
                    

                    <div class="row g-3">
                    <div class="col-2">
                        <div class="form-check align-middle">
                        <label class="form-check-label">
                            <b-form-checkbox v-model="row.apdf_is_edukasi" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0" name="edukasi">
                            Edukasi
                            </b-form-checkbox>
                        </label>
                        </div>
                    </div>
                    
                    <div class="col-2">
                        <div class="form-check align-middle">
                        <label class="form-check-label">
                            <b-form-checkbox v-model="row.apdf_is_done" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0"
                            name="tidakKontrol">
                            Selesai
                            </b-form-checkbox>
                        </label>
                        </div>
                    </div>
                    
                    <div class="col-2">
                        <div class="form-check align-middle">
                        <label class="form-check-label">
                            <b-form-checkbox v-model="row.apdf_is_dalam_perawatan" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0"
                            name="tidakKontrol">
                            Dalam Perawatan
                            </b-form-checkbox>
                        </label>
                        </div>
                    </div>

                    <div class="col-2">
                        <div class="form-check align-middle">
                        <label class="form-check-label">
                            <b-form-checkbox v-model="row.apdf_is_rawat_inap" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0"
                            name="rawatInap">
                            Rawat Inap
                            </b-form-checkbox>
                        </label>
                        </div>
                    </div>

                    </div>

                    
                    <template v-if="row.apdf_is_dalam_perawatan == 'Y' || row.apdf_is_done == 'Y'">
                    <div class="row g-3 mt-2" v-if="row.apdf_is_dalam_perawatan == 'Y'">
                        <div class="col-md-12">
                        <b-form-group>
                            <label>Pilihan Perawatan?<span
                                class="text-danger mr5">*</span></label>
                            <b-form-radio-group @input="changeRujuk($event)" :options="Config.mr.KontrolRujuk"
                            v-model="rowReg.ar_is_rujukan" />
                        </b-form-group>
                        </div>
                    </div>

                    <template v-if="rowReg.ar_is_rujukan == 'Y'">                                      
                        <div class="row" v-if="$parent.isRegistration && (rowReg.ar_mcp_id == 137 || rowReg.ar_mcp_id == 138)">
                        <div class="col-4">
                            <label for="">Nomor Rujukan</label>
                            <b-form-input disabled v-model="row.absd_no_rujuk" type="text" class="form-control" />
                        </div>

                        <div class="col-4">
                            <label for="">No SEP</label>
                            <b-form-input disabled v-model="row.absd_no_sep" type="text" class="form-control" />
                        </div>
                        </div>

                        
                        <div class="row mt-3" v-if="rowReg.ar_is_rujukan == 'Y'">
                        <div class="col-12">
                            <label for=""><strong>Rujuk Pasien</strong></label>
                            <b-form-group>
                            <b-form-radio-group
                                id="radio-group-2"
                                v-model="row.artlr_type"
                                name="rujukanRad"
                            >
                                <b-form-radio v-model="row.artlr_type" v-if="row.apdf_is_dalam_perawatan == 'Y'" value="RI">Rujuk Internal</b-form-radio>
                                
                                <b-form-radio v-model="row.artlr_type" v-if="row.apdf_is_dalam_perawatan == 'Y'" value="RP">Rujuk Parsial</b-form-radio>
                                
                                <b-form-radio v-model="row.artlr_type" v-if="row.apdf_is_done == 'Y'" value="RK">Rujuk Kasus
                                </b-form-radio>

                                <b-form-radio v-model="row.artlr_type" v-if="row.apdf_is_done == 'Y'" value="RB">Rujuk Balik (PRB)</b-form-radio>

                                <b-form-radio v-model="row.artlr_type" v-if="row.apdf_is_done == 'Y'" value="RBN">Rujuk Balik (NON PRB)</b-form-radio>
                            </b-form-radio-group>
                            </b-form-group>

                            <VValidate :name="'Rujuk Pasien'"
                            v-model="row.artlr_type"
                            :rules="{required : 1}" />
                        </div>
                        </div>
                    </template>
                    </template>
                    
                    <div class="row mt-2" v-if="row.apdf_is_dalam_perawatan == 'Y'">
                    <!--
                    <div class="col-2">
                        <div class="form-check align-middle">
                        <label class="form-check-label">
                            <b-form-checkbox @input="changeKontrol($event)" v-model="row.apdf_is_kontrol" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0" name="kontrol">
                            Kontrol
                            </b-form-checkbox>
                        </label>
                        </div>
                    </div>
                    -->
                    </div>

                    <template v-if="rowReg.ar_is_rujukan == 'Y'">
                    <div class="row mt-3" v-if="row.artlr_type == 'RI'" id="formRujukInternal">
                        <div class="col-md-4">
                        <div class="form-group">
                            <label for="poliSelection">Pilih Poli</label>

                            <v-select placeholder="Pilih Poli"
                            v-model="row.artlr_poli" @input="selectDokter($event)"
                            :options="mPoli" label="text" :clearable="true"
                            :reduce="v=>v.value"></v-select>
                            
                            <VValidate :name="'Poli'"
                            v-model="row.artlr_poli"
                            :rules="{required : 1}" />
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group">
                            <label for="docSelection">Pilih Dokter</label>
                            <v-select placeholder="Pilih Dokter" v-model="row.artlr_dokter"
                            :options="mDokter" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Dokter'"
                            v-model="row.artlr_dokter"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-8">
                        <div class="form-group">
                            <label> Catatan<small class="txt_mandatory">*</small></label>
                            <textarea v-model="row.artlr_notes" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                            <VValidate :name="'Catatan'"
                            v-model="row.artlr_notes"
                            :rules="{required : 1, min: 2}" />
                        </div>
                        </div>
                    </div>

                    <div class="row mt-3" v-else-if="row.artlr_type == 'RK' || row.artlr_type == 'RP'" id="formRujukKasus">
                        <div class="col-md-3">
                        <div class="form-group">
                            <label>Alasan Merujuk<small class="txt_mandatory">*</small></label>
                            <v-select placeholder="Alasan Rujuk" v-model="row.artlr_alasan_rujuk"
                            :options="Config.mr.alasanRujuk" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Alasan Rujuk'"
                            v-model="row.artlr_alasan_rujuk"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-3" v-if="row.artlr_alasan_rujuk">
                        <div class="form-group">
                            <label>{{getConfigDynamic(Config.mr.alasanRujuk, row.artlr_alasan_rujuk)||"-"}}<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            <b-form-input v-model="row.artlr_alasan_rujuk_text" type="text" class="form-control" />

                            <VValidate :name="'Alasan DiRujuk'"
                            v-model="row.artlr_alasan_rujuk_text"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="poliSelection">Pilih Fakes Rujukan</label>

                            <v-select placeholder="Pilih Fakes Rujukan"
                            @input="selectPoliRujukan($event)" 
                            v-model="row.artlr_faskes_rujuk"
                            :options="mFaskes" label="text" :clearable="true"
                            :reduce="v=>v.value"></v-select>

                            <button type="button" class="btn btn-light mr-3 mt-2" @click="openFaskes = true">Tambah Faskes Rujukan</button>
                            
                            <VValidate :name="'Faskes'"
                            v-model="row.artlr_faskes_rujuk"
                            :rules="{required : 1}" />

                            
                        </div>
                        </div>
                        
                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="docSelection">Pilih Poli</label>
                            <v-select placeholder="Pilih Poli"
                            @input="selectDokterRujukan($event)" v-model="row.artlr_poli_rujuk"
                            :options="mPoliRujukan" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Poli'"
                            v-model="row.artlr_poli_rujuk"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="docSelection">Nama Dokter</label>
                            <b-form-input v-model="row.artlr_nama_dokter_rujuk" type="text" class="form-control" />
                        </div>
                        </div>
                    
                    <div class="col-md-12">
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-row">
                            <div class="form-group col-md-5">
                                <label>Peralatan Media yang Terpasang<small class="txt_mandatory">*</small></label>
                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                v-model="row.artlr_is_media" />
                                <VValidate :name="'Peralatan Media yang Terpasang'"
                                v-model="row.artlr_is_media"
                                :rules="{required : 1}" />
                            </div>
                            <div class="form-group col-md-4 mt-3" v-if="row.artlr_is_media == 'Y'">
                                <v-select placeholder="Pilih Media" v-model="row.artlr_media"
                                :options="Config.mr.mediaTerpasang" label="text" :clearable="true"
                                :reduce="v=>v.value">
                                </v-select>
                                <VValidate :name="'Media'"
                                v-model="row.artlr_media"
                                :rules="{required : 1}" />
                            </div>
                            </div>

                        </div>

                        </div>
                    </div>
                    
                    <div class="col-md-8">
                        <div class="form-group">
                        <label> Perawatan Pasien yang dibutuhkan<small class="txt_mandatory">*</small></label>
                        <textarea v-model="row.artlr_perawatan_pasien" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                        
                        <VValidate :name="'Perawatan Pasien yang dibutuhkan'"
                        v-model="row.artlr_perawatan_pasien"
                        :rules="{required: 1, min: 3, max: 128}" />
                        </div>
                    </div>

                    </div>

                    <div class="row mt-3" v-else id="formRujukBalik">
                        <div class="col-md-4">
                        <div class="form-group">
                            <label>Nomor Rujukan<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            <input class="form-control" v-model="row.absd_no_rujuk" readonly placeholder="No Rujukan Faskes I">
                        </div>
                        </div>

                        <div class="col-md-4" v-if="row.artlr_type == 'RB'">
                        <div class="form-group">
                            <label>Diagnosa Rujuk Balik<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            
                            <v-select placeholder="Diagnosa Rujuk Balik" v-model="row.artlr_diagnosa_rujuk_balik"
                            :options="Config.mr.diagnosaRujukBalik" label="text" :clearable="true"
                            :reduce="v=>v.value"></v-select>
                            <VValidate 
                                name="Diagnosa Rujuk Balik" 
                                v-model="row.artlr_diagnosa_rujuk_balik" 
                                :rules="{required : 1}"
                            />
                        </div>
                        </div>

                        <div class="col-md-8">
                        <div class="form-group">
                            <label> Catatan<small class="txt_mandatory">*</small></label>
                            <textarea v-model="row.artlr_notes" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                            <VValidate :name="'Catatan'"
                            v-model="row.artlr_notes"
                            :rules="{required : 1, min: 2}" />
                        </div>
                        </div>
                    </div>
                    </template>


                    <div class="row">
                    <div class="col-md-4 col-lg-4 mt-3" v-if="row['apdf_is_edukasi'] == 'Y'">
                        <div>
                        <label for="stepNotes">Catatan (Edukasi)</label>
                        <b-textarea v-model="row['apdf_notes_edukasi']" name="stepNotes"
                            id="stepNotes" rows="2" class="form-control">
                        </b-textarea>
                        </div>
                    </div>

                    <template v-if="row['apdf_is_kontrol'] == 'Y'">
                        <div class="col-md-3 col-lg-3 mt-3" >
                        <div>
                            <label for="stepNotes">Tanggal Kontrol <span
                                class="text-danger mr5">*</span></label>
                            <div class="input-group">
                            <div class="input-group mb-3">
                                <datepicker :disabledDates="disabledDates"
                                input-class="form-control transparent"
                                placeholder="Tanggal Kontrol" class="my-datepicker"
                                calendar-class="my-datepicker_calendar"
                                v-model="row.apdf_notes_kontrol"
                                @changedMonth="checkDateIsLibur($event)"
                                >
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                            </div>
                            <VValidate :name="`Tanggal Kontrol`" 
                            v-if="row.apdf_is_kontrol=='Y'" v-model="row.apdf_notes_kontrol"
                            :rules="{required: 1}" />
                        </div>
                        </div>

                        <div class="col-md-3 col-lg-3 mt-3" >
                        <div>
                            <label for="stepNotes">Catatan Kontrol</label>
                            <b-textarea v-model="row['apdf_catatan_kontrol']" name="stepNotes"
                            id="stepNotes" rows="2" class="form-control">
                        </b-textarea>
                        </div>
                        </div>
                        
                        <template v-if="$parent.isRegistration && (rowReg.ar_mcp_id == 137 || rowReg.ar_mcp_id == 138)">
                        <div class="col-md-3 col-lg-3 mt-3">
                            <label for="">Nomor Rujukan</label>
                            <b-form-input v-model="row.absd_no_rujuk" disabled type="text" class="form-control" />
                        </div>

                        <div class="col-md-3 col-lg-3 mt-3">
                            <label for="">No SEP</label>
                            <b-form-input v-model="row.absd_no_sep" disabled type="text" class="form-control" />
                        </div>
                        
                        <div class="col-md-3 col-lg-3 mt-3">
                            <label for="">Rujukan Berakhir</label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="row.absd_tanggal_berlaku_rujukan">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                                    
                                            
                        </template>


                    </template>

                    <template v-if="row['apdf_is_rawat_inap'] == 'Y'">
                        <div class="col-md-3 col-lg-3 mt-3">
                        <div>
                            <label for="stepNotes">Ruangan</label>
                            <v-select placeholder="Pilih Ruangan" v-model="row.apdf_ruangan"
                            :options="mRuangan" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                        </div>
                        </div>
                        
                        <div class="col-md-3 col-lg-3 mt-3">
                            <div>
                                <label for="stepNotes">Dokter<span
                                    class="text-danger mr5">*</span></label>
                                <v-select placeholder="Pilih Dokter" v-model="row.apdf_dokter_ranap"
                                :options="mDokterRanap" label="text" :clearable="true"
                                :reduce="v=>v.value">
                                </v-select>
                            </div>
                            
                            <VValidate :name="`Dokter Rawat Inap`" v-model="row.apdf_dokter_ranap"
                                :rules="{required: 1}" />
                        </div>

                        <div class="col-md-4 col-lg-4 mt-3">
                        <div>
                            <label for="stepNotes">Catatan (Rawat Inap)</label>
                            <b-textarea v-model="row['apdf_notes_rawat_inap']" name="stepNotes"
                            id="stepNotes" rows="2" class="form-control">
                            </b-textarea>
                        </div>
                        </div>
                        
                        <div class="col-md-3 col-lg-3 mt-3">
                            <label for="">Tanggal Rawat Inap</label>
                            <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="row.apdf_tanggal_ranap">
                            </datepicker>
                            <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                            </div>
                            </div>

                            
                            <VValidate :name="`Tanggal Rawat Inap`" v-model="row.apdf_tanggal_ranap"
                                :rules="{required: 1}" />
                        </div>
                    </template>
                    </div>

                    <div class="row mt-3">
                    <div class="col-md-12">
                        <b-form-group>
                            <label>Cara Pulang Pasien?<span
                            class="text-danger mr5">*</span></label>
                            <b-form-checkbox-group
                                v-model="row.apdf_cara_pulang"
                                :options="Config.mr.mCaraPulang"
                                name="cara_pulang"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    </div>
                </div>
                </div>
            </div>
                                
            <div class="card border shadow-0 mt-3" v-if="row.isEdit">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                </div>
                <div class="card-body">
                    <div class="form-group">
                    <label for="addInfoTindakan">Keterangan Perubahan</label>
                    <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                    </b-textarea>

                        
                    <VValidate 
                        :name="`Keterangan Perubahan`" 
                        v-model="row.arm_notes" 
                        :rules="{required: 1, min: 2, max:512}"
                    />
                    </div>
                </div>
            </div>
            <div class="card-footer">
            <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
            </div>
        </div>
        </b-form>
        </validation-observer>
        
        <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
            <div class="modal-body p-0">
            <div class="mb-3">
                <div class="row">
                <div class="col-md-5 col-lg-4">
                    <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                        <input v-model="searchICD10" @input="searchICD10Data" class="form-control" placeholder="Ketik Nama Dokter / No. Registrasi" />
                        <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                        </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                    </div>
                </div>
                </div>
            </div>
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD10</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD10">
                    <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose" :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>
                    
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}} <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD10||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="modal-footer p-0 mt-3">
            <div class="text-right">
                <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
                <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10" class="btn btn-success btn-labeled btn-labeled-left"><b><i
                    class="icon-checkmark"></i></b>Simpan Pilihan</button>
            </div>
            </div>
        </b-modal>

        <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
            <div class="modal-body p-0">
            <div class="mb-3">
                <div class="row">
                <div class="col-md-5 col-lg-4">
                    <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                        <input v-model="searchICD9" @input="searchICD9Data" class="form-control" placeholder="Ketik Nama Dokter / No. Registrasi" />
                        <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                        </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                    </div>
                </div>
                </div>
            </div>
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD9</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD9">
                
                    <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose" :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>
                    
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}} <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD9||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="modal-footer p-0 mt-3">
            <div class="text-right">
                <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
                <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9" class="btn btn-success btn-labeled btn-labeled-left"><b><i
                    class="icon-checkmark"></i></b>Simpan Pilihan</button>
            </div>
            </div>
        </b-modal>

        
        <b-modal v-model="openFaskes" :title="'Tambah Faskes Tujuan'" size="sm" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                
                <div class="col-md-12">
                    <label for="perGravid">Cari Nama Faskes<strong
                    class="text-danger">*</strong></label>
                    <div class="input-group">
                    <b-form-input v-model="faskesSearch"
                        type="text" class="form-control" />
                        <div class="input-group-append">
                        <button @click="searchFaskesData" type="button"
                            class="btn btn-info btn-labeled btn-labeled-left">
                            <b><i class="icon-search4"></i></b>
                            Cari
                        </button>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" v-for="(v,k) in (listFaskesBPJS||[])" :key="k+'radiox'">
                <div class="selection-control">
                    <b-form-checkbox v-model="v['selected']">{{v.kode}} - {{v.nama}}
                    </b-form-checkbox>
                </div>
                </div>

            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white" v-if="(listFaskesBPJS||[]).length">
            <div class="text-right">
            <button @click="addFaskes" data-dismiss="modal" class="btn">Selesai</button>
            </div>
        </div>
        </b-modal>

            
        <b-modal v-model="openPaketAlkes" :title="'Komposisi Paket Alkes'" size="md" hide-footer>
            <div class="modal-body p-0 table-responsive">
                <p>Paket Alkes : {{rowPaketAlkes.mra_nama_racikan||"-"}} terdiri
                dari:</p>
                <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                    <tr>
                    <th width="40">#</th>
                    <th>Nama Alkes</th>
                    <th>Qty</th>
                    <th>Frekuensi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in rowPaketAlkes.ard_paket_alkes" :key="k+'rowracik'">
                    <td>{{k+1}}</td>
                    <td>
                        <span>{{ getConfigDynamic(mAlatKesehatan,v.value)||"-" }}</span>
                    </td>
                    <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                    <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="modal-footer p-0 mt-3">

                <a href="javascript:;" v-if="isEditAlkes" @click="doOpenFormAlkes()" data-toggle="modal" data-target="#updateRacikanFarmasi"
                class="btn btn-link" data-dismiss="modal">
                <div class="icon-pencil7"></div>
                Ubah Komposisi Paket Alkes
                </a>
                <button type="button" @click="openPaketAlkes = false" class="btn btn-success btn-labeled btn-labeled-left"
                data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
            </div>
        </b-modal>

        <validation-observer ref="VFormPaketAlkes">
            <b-form @submit.prevent="doSubmitPaketAlkes">
                <b-modal v-model="openFormPaketAlkes" :title="'Ubah Komposisi Alat Kesehatan'" size="lg" hide-footer>
                <div class="modal-body p-0">
                    <div class="alert alert-info">
                    <span>Saat ini Anda sedang melakukan perubahan komposisi alat kesehatan
                        <strong>{{rowPaketAlkes.mra_nama_racikan||"-"}}</strong> milik
                        <strong>{{rowPaketAlkes.bu_full_name||"-"}}</strong></span>
                    </div>
                    <div class="form-group">
                    <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                            <th width="48">#</th>
                            <th width="33%">Nama Alkes</th>
                            <th width="33%">Dosis</th>
                            <th width="33%">Jumlah</th>
                            <th width="33%">Aksi</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in rowPaketAlkes.ard_paket_alkes" :key="k">
                            <td>{{k + 1}}</td>
                            <td>
                            <v-select placeholder="Pilih Alkes" @input="selectAlkes($event,k)"
                                v-model="rowPaketAlkes.ard_paket_alkes[k]['value']" :options="mAlatKesehatan"
                                label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                            <VValidate :name="'Paket Alkes #'+(k+1)" message="Paket Alkes Harus Diisi"
                                v-model="rowPaketAlkes.ard_paket_alkes[k]['value']" :rules="{required : 1}" />
                            </td>
                            <td>
                            <div class="input-group">
                                <input v-model="rowPaketAlkes.ard_paket_alkes[k]['dosis']" type="text" class="form-control"
                                placeholder="cth. 120">
                                <div class="input-group-append"><input style="width: 90px;"
                                    v-model="rowPaketAlkes.ard_paket_alkes[k]['satuan_dosis']" type="text" class="form-control"
                                    placeholder="cth. Kaplet"></div>
                            </div>
                            </td>
                            <td>
                            <div class="input-group">
                                <b-form-input type="text" v-model="rowPaketAlkes.ard_paket_alkes[k]['jumlah']"
                                :formatter="$parent.number" class="form-control" placeholder="cth. 5" />
                                <div class="input-group-append"><span
                                    class="input-group-text">{{rowPaketAlkes.ard_paket_alkes[k]['satuan_jumlah']||"-"}}</span></div>
                            </div>

                            <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                                v-model="rowPaketAlkes.ard_paket_alkes[k]['jumlah']" :rules="{required: 1}" />
                            </td>
                            <td>
                            <a href="javascript:;" @click="rowPaketAlkes.ard_paket_alkes.splice(k,1)"
                                class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                                data-popup="tooltip" title="Hapus Alkes"><i class="icon-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="9" class="text-center">
                            <a href="javascript:;" @click="addAlkes" class="btn btn-outline-info">
                                <i class="icon-plus2 align-middle mr-1"></i>
                                <span class="align-middle">Tambah Alkes</span>
                            </a>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                    <div class="w-100"></div>
                </div>
                <div class="modal-footer p-0 mt-3">
                    <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
                    class="btn btn-link" data-dismiss="modal">
                    Batal
                    </a>
                    <button type="submit" @click="doSubmitAlkes" class="btn btn-success btn-labeled btn-labeled-left"
                    data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
                </div>
                </b-modal>
            </b-form>
        </validation-observer>

            
        <!-- RESEP -->    
        <validation-observer ref="VFormTemplate">
            <b-modal v-model="openTemplate" @ok.prevent="submitTemplate" :title="'Template Resep'" size="md" ok-title="Pilih Template">
            <div class="row">
                <div class="col-md-12">
                <template v-if="templateResep">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                    <thead>
                    <tr>
                        <th width="20"></th>
                        <th>Nama</th>
                        <th>Rincian</th>
                        <th width="80">Aksi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v,k) in (templateResep||[])" :key="k">
                        <td>
                        <b-form-checkbox v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                        </td>
                        <td>{{v.mtr_name}}</td>
                        <td>
                        <span class="d-block font-weight-bold" v-if="v.mtr_total_obat">Total Obat : {{v.mtr_total_obat}}</span>
                        <span class="d-block font-weight-bold" v-if="v.mtr_total_alkes">Total Alkes : {{v.mtr_total_alkes}}</span>
                        <span class="d-block font-weight-bold" v-if="v.mtr_total_infus">Total Infus : {{v.mtr_total_infus}}</span>
                        </td>
                        <td>
                            <a @click="openDetail(v.data)"
                                                href="javascript:;"
                                                title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
                                                ><i class="icon-eye"></i></a>
                        </td>
                    </tr>
                    <tr v-if="!(templateResep||[]).length">
                        <td colspan="4" class="text-center">Tidak ada data</td>
                    </tr>
                    </tbody>
                    </table>
                </template>
                <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
                </div>
            </div>
            </b-modal>
        </validation-observer>
        <b-modal v-model="openDetailIsi" :title="'Detail Resep'" size="lg" ok-title="Ok" ok-only>
            <div class="row">
                <div class="col-md-12">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                        <th>Jenis</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                        <th>Frekuensi</th>
                        <th>Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataDetail||[])" :key="k">
                        <td>{{getConfigDynamic(Config.mr.configResep,v.ard_jenis)||"-"}}</td>
                        <td>{{v.ard_nama||"-"}}</td>
                        <td>{{v.ard_jumlah||"-"}} {{v.ard_satuan}}</td>
                        <td>{{v.mdo_name||v.ard_frekuensi_lainnya||"-"}}</td>
                        <td>{{v.ard_keterangan||"-"}}</td>
                        </tr>
                        <tr v-if="!(dataDetail||[]).length">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </b-modal>
            
        <!-- INFORM CONSENT -->
    
        <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aric_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aric_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aric_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aric_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aric_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
        </validation-observer>

        <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
            <table class="table table-bordered table-sm table-input">
            <thead>
                <tr>
                <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan / ICD-9-CM</h4>
                        <p>{{rowIC.mi9_name}} <span class="font-weight-semibold" v-if="rowIC.mi9_code">[{{rowIC.mi9_code}}]</span></p>
                    </div>
                </td>
                </tr>
                <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td>
                {{rowIC.aric_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td>
                {{rowIC.aric_dasar_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td>
                {{rowIC.aric_tindakan_dokter||"-"}}
                </td>
                </tr>
                <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td>
                {{rowIC.aric_indikasi_tindakan||"-"}}
                </td>
                </tr>
                <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td>
                {{rowIC.aric_tata_cara||"-"}}
                </td>
                </tr>
                <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td>
                {{rowIC.aric_tujuan||"-"}}
                </td>
                </tr>
                <tr>
                <td>7</td>
                <td>Risiko</td>
                <td>
                {{rowIC.aric_risiko||"-"}}
                </td>
                </tr>
                <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td>
                {{rowIC.aric_kompilasi||"-"}}
                </td>
                </tr>
                <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td>
                {{rowIC.aric_prognosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td>
                {{rowIC.aric_alternatif||"-"}}
                </td>
                </tr>
                <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td>
                {{rowIC.aric_lainnya||"-"}}
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div class="result_tab">
                    <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                    <p v-if="rowIC.aric_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                    <p v-else>Tindakan ditolak</p>
                    </div>
                </td>
                <td width="50%">
                    <div class="result_tab">
                    <h4>Persetujuan tindakan diberikan kepada</h4>
                    <p v-if="rowIC.aric_persetujuan_tindakan == 'P'">Pasien</p>
                    <p v-else>Penanggung Jawab</p>
                    </div>
                </td>
                </tr>
            </tfoot>
            </table>
        </b-modal>

        <!-- INFORM CONSENT -->
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

// import GlobalVue from '@/libs/Global.vue'

export default{
    // extends: GlobalVue,
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        statusElektroterapi: Array,
        statusMekanoTerapi: Array,
        statusLatihan: Array,
        
        dataICD9: Array,
        dataICD10: Array,
        riwayatICD9: Array,
        riwayatICD10: Array,
        mICD9: Array,
        mICD10: Array,
        
        mPoli: Array,
        mDokter: Array,
    
        mFaskes: Array,
        mDokterRujukan: Array,
        mPoliRujukan: Array,
    
        resepDokter: Array,
        resepPerawatData: Array,

        mDosis: Array,
        mObatPreferensi: Array,
        mAlatKesehatan: Array,
        mAlatInfus: Array,
        mRacikan: Array,
        mObat: Array,
        mTypeSatuan: Array,
        riwayatObat: Array,
        mHariLibur: Array,
        
        mRuangan: Array,
        mDokterRanap: Array,

        mPaketAlkes: Array,
        tindakanLainnya: Array,
    },
    computed: {
      isPoliAdj(){
        return this.$parent.isPoliAdj
      },
      canEditFarmasi() {
        return true
      },
      disabledDates() {
        let datesDisabled = []
        for (let i = 0; i < this.mHariLibur.length; i++) {
          datesDisabled.push(new Date(moment(this.mHariLibur[i]['mhl_date'])))
        }
        return {
          to: new Date(moment().format('YYYY-MM-DD')),
          dates: datesDisabled,
          days: [0],

        }
      },
      selectedFaskesInput() {
        let data = []
        for (let i = 0; i < (this.listFaskesBPJS || []).length; i++) {
          if(this.listFaskesBPJS[i]['selected']){
            data.push(this.listFaskesBPJS[i])
          }
        }
        return data
      },
      selectedTemplate(){
        let selected = []
        for (let i = 0; i < (this.templateResep||[]).length; i++) {
          if(this.templateResep[i]['checked']){
          for (let j = 0; j < (this.templateResep[i]['data']||[]).length; j++) {
            let dataSelected = this.templateResep[i]['data'][j]
            dataSelected.ard_code = null
            selected.push(dataSelected)
        }
          }
        }
        return selected
      },
    },
    data(){
        return {
            openriwayatICD10 : false,
            openriwayatICD9 : false,
            selectedICD9: '',
            selectedICD10: '',
            searchICD10: '',
            searchICD9: '',
    
            inputICD9: null,
            inputICD10: null,
            
            inputICD9Name: null,
            inputICD10Name: null,
        
            searchICD10Name: null,
            searchICD9Name: null,
                

            openRacikan: false,
            rowRacikan: {},
            openAddObat: false,
            selectedObat: '',
            searchObat: '',
            
            openFormRacikan: false,
            obatLen: 0,
            currentPage: 1,
                
            openFaskes : false,
            listFaskesBPJS: [],
            faskesSearch: null,
                
            rowPaketAlkes: {},
            openPaketAlkes: false,
            openFormPaketAlkes: false,
            isEditRacikan: false,
            isEditAlkes: false,

                
            templateResep: false,
            openTemplate: false,
            openTemplateDetail: false,
            dataDetail: [],
            openDetailIsi: false,

                
            rowIC : {
                aric_is_pasien_setuju: null,
                aric_persetujuan_tindakan: null
            },

            openModalIC : false,
            openModalICDetail : false,
        }
    },
    components: {
      Datepicker
    },
    mounted() {
      document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.setPosisionNyeri()
            this.row.arm_notes = null
            console.log(this.mPaketAlkes)
        },1500)
    },
    methods: {
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
                text = master[index]['text']
            }
            }
            return text
        },
        getResepPerawat(){
            this.$parent.loadingOverlay = true
            let data = {
                id: this.row.arm_ar_id,
                arm_id: this.row.arm_id,
                type: 'get-resep-perawat'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.$parent.loadingOverlay = false
            this.$parent.resepPerawatData = res.data.resepPerawatData
            })
        },
        changeKontrol(e){
            if(e == 'Y'){
                this.rowReg.ar_is_rujukan = "N"
                this.row.artlr_type = null
            }
        },
        
        addFaskes(){
            this.$parent.loadingOverlay = true
            let data = {
                faskesData: this.selectedFaskesInput,
                type: 'submit-faskes-data'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.$parent.loadingOverlay = false
                this.$swal({
                    icon: 'success',
                    title: 'Data Faskes berhasil ditambahkan',
                })
                this.openFaskes = false
            })
        },
        
        searchFaskesData(){
            this.$parent.loadingOverlay = true
            let data = {
                search: this.faskesSearch,
                type: 'search-faskes-data'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.$parent.loadingOverlay = false
                this.listFaskesBPJS = res.data
                console.log(this.listFaskesBPJS)
            })
        },
        checkDateIsLibur(e){
            let year = moment(e, "YYYY").format('YYYY')
            let data = {
                year: year,
                type: 'get-holiday-config'
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
            ).then(res => {
                this.mHariLibur = res.data.mHariLibur
            })
        },
        changeRujuk(e){
            if(e == "Y"){
                this.row.apdf_is_edukasi = "N"
                this.row.apdf_is_kontrol = "N"
                this.row.apdf_is_rawat_inap = "N"
                // this.row.apdf_is_done = "N"
            }else{
                this.row.apdf_is_kontrol = "Y"
            }
        },
        selectDokter(e) {
            let data = {
            id: e,
            type: 'get-by-poli'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.mDokter = res.data.mDokter
            })
        },
        
         
        selectPoliRujukan(e){
            let data = {
            type: 'get-by-poli-fakes',
            id: e
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.mPoliRujukan = res.data.mPoliRujukan
            })
        },

        selectDokterRujukan(e) {
            let data = {
            id: this.row.artlr_faskes_rujuk,
            type: 'get-by-faskes',
            poli: e
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.mDokterRujukan = res.data.mDokterRujukan
            })
        },

        ceilData(value){
          return Math.ceil(value)
        },
            
        selectICD9(e) {
            let index = this.mICD9.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD9Name =  this.mICD9[index]['text']
                this.searchICD9Name = null
            }else{
                this.inputICD9Name =  null
            }
            this.addNewICD9()
        },
        selectICD10(e) {
            let index = this.mICD10.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD10Name =  this.mICD10[index]['text']
                this.searchICD10Name = null
        
            }else{
                this.inputICD10Name =  null
            
            }
            this.addNewICD10()
        },

        filterICD10: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD10 || []).length; i++) {
            if (this.dataICD10[i]['api10_icd_10']) {
                id.push(this.dataICD10[i]['api10_icd_10'])
            }
            }
            this.searchICD10Name = e
            Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
                type: 'select-icd-10',
                search: e,
                id: id
            }
            }, 'POST').then(res => {
            this.$parent.mICD10 = res.data.data
            })
        }, 10),

        changePageICD10(page, k, act, col) {
            if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
            } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
            }

            let data = {
            type: 'select-paging-icd-10',
            page: page,
            search: this.searchICD10Name
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.$parent.mICD10 = res.data.data
            this.getmICD10(this.dataICD10, k)
            })
        },

        filterICD9: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD9 || []).length; i++) {
            if (this.dataICD9[i]['api9_icd_9']) {
                id.push(this.dataICD9[i]['api9_icd_9'])
            }
            }

            this.searchICD9Name = e

            Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
                type: 'select-icd-9',
                search: e,
                id: id
            }
            }, 'POST').then(res => {
            this.$parent.mICD9 = res.data.data
            })
        }, 10),

        changePageICD9(page, k, act, col) {
            if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
            } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
            }
            
            let data = {
            type: 'select-paging-icd-9',
            page: page,
            search: this.searchICD9Name
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.$parent.mICD9 = res.data.data
            this.getmICD9(this.dataICD9, k)
            })
        },

        addNewICD9() {
            if(this.inputICD9){
            // if (!this.row.isEdit) {
                let data = {
                api9_arm_id: this.row.arm_id,
                api9_icd_9: this.inputICD9,
                type: 'add-icd-9'
                }
                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
                ).then(res => {
                let resp = res.data
                this.dataICD9.push(resp.row)
                this.inputICD9 = null
                })
            // } else {
            //     let data = {
            //     api9_arm_id: this.row.arm_id,
            //     api9_id: null
            //     }
            //     this.dataICD9.push(data)
            // }
            }
        },
        removedataICD9(id, k, ic) {
            // if (!this.row.isEdit) {
            let data = {
                id: id,
                type: 'remove-icd-9',
                ic : ic
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                data: data
                },
                "POST"
            ).then(() => {
                this.dataICD9.splice(k, 1)
            })
            // } else {
            // this.dataICD9.splice(k, 1)
            // }
        },

        addNewICD10() {
            if(this.inputICD10){
            // if (!this.row.isEdit) {
                let data = {
                api10_arm_id: this.row.arm_id,
                api10_icd_10: this.inputICD10,
                type: 'add-icd-10'
                }
                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
                ).then(res => {
                let resp = res.data
                this.dataICD10.push(resp.row)
                this.inputICD10 = null
                })
            // } else {
            //     let data = {
            //     api10_arm_id: this.row.arm_id,
            //     api10_id: null
            //     }
            //     this.dataICD10.push(data)
            // }
            }
        },
        removedataICD10(id, k) {
            // if (!this.row.isEdit) {
            let data = {
                id: id,
                type: 'remove-icd-10'
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                data: data
                },
                "POST"
            ).then(() => {
                this.dataICD10.splice(k, 1)
            })
            // } else {
            // this.dataICD10.splice(k, 1)
            // }
        },
        
        getmICD9(diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.api9_icd_9
            })
            return _.filter(this.mICD9, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },
        
        getmICD10(diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.api10_icd_10
            })
            return _.filter(this.mICD10, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },
        
        back(){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{}) 
        },
        setPosisionNyeri() {
            let posX = false
            let posY = false
            let self = this

            $("body").mousemove(function (e) {
                if (!$("body").hasClass("active")) $("body").addClass("active")
                if($("#anatomiNyeri").length){
                  posX = parseInt(e.pageX - ($("#anatomiNyeri").offset().left + 8))
                  posY = parseInt(e.pageY - ($("#anatomiNyeri").offset().top + 52))
                }
            })

            $('#anatomiNyeri').on('click', function (e) {
                e.stopPropagation()
                let index = self.row.apdf_lokasi_nyeri.findIndex(x => x.isOpen == true)
                if(index !== -1){
                  self.row.apdf_lokasi_nyeri.splice(index,1)
                }else{
                  self.row.apdf_lokasi_nyeri.push({
                      x: (posX / $(this).width())*100,
                      y: (posY / $(this).height())*100,
                      value: '',
                      isOpen: true,
                  })
                }
            })
        },
        removeAnat(k){
            this.row.apdf_lokasi_nyeri.splice(k,1)
        },
        closeAnat(k){
            this.row.apdf_lokasi_nyeri[k]['isOpen'] = false
            this.row.apdf_lokasi_nyeri[k]['y'] += (42 / $('#anatomiNyeri').height())*100
        },
        openAnat(k){
            this.row.apdf_lokasi_nyeri[k]['y'] -= (42 / $('#anatomiNyeri').height())*100
            this.row.apdf_lokasi_nyeri[k]['isOpen'] = true
        },
        doSubmitDokterTerapi(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   

            this.$refs['VFormDokterTerapi'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }


                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data-dokter-terapi'
                            data.dataICD9 = this.dataICD9
                            data.dataICD10 = this.dataICD10
                            data.tindakanLainnya = this.tindakanLainnya
                            data.ar_is_rujukan = this.rowReg.ar_is_rujukan
                            data.resepDokter = this.resepDokter

                            
                            if(data.artlr_type == 'RI'){
                                data.ar_rujukan_to = this.row.artlr_dokter
                                data.ar_rujukan_poli_to = this.row.artlr_poli
                            }else if(data.artlr_type == 'RK' || data.artlr_type == 'RP'){
                                data.ar_rujukan_poli_to = this.row.artlr_poli_rujuk
                            }

                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.$parent.user.levelId == 1){
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uDokterFisio : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{})
                                        }  
                                                            
                                        this.$socket.emit('refresh_data', {to : this.$parent.uRadiologi})
                                        this.$socket.emit('refresh_data', {to : this.$parent.uLab})
                                        this.$socket.emit('refresh_data', {to : this.$parent.uDokterGizi})
                                        this.$socket.emit('refresh_data', {to : this.$parent.uFisio})

                                        this.$socket.emit('refresh_data', {to : 'ALLPJ'})
                                        this.$socket.emit('refresh_data', {to : 'Farmasi'})
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }
            })
        },
        
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save-dokter-terapi'
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        autoSaveICD9: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-icd-9',
                data : data
            }  
            if(!this.row.isEdit){
              Gen.apiRest(
                  "/do/"+'RekamMedis',
                  {data:dataPost}, 
                  "POST"
              )
            }
        },1000),  
        autoSaveICD10: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-icd-10',
                data : data
            }  
            if(!this.row.isEdit){
              Gen.apiRest(
                  "/do/"+'RekamMedis',
                  {data:dataPost}, 
                  "POST"
              )
            }
        },1000),
        
        autoSaveResepObat: _.debounce(function (data) {
            let dataPost = {
            type: 'auto-save-resep-obat',
            data: data
            }
            if (!this.row.isEdit) {
                Gen.apiRest(
                    "/do/" + 'RekamMedis', {
                    data: dataPost
                    },
                    "POST"
                )
            }
        }, 1000),

        toValidate(val){
            return {...val}
        },

        changePageObat(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
                    page += 1
                    this[col][k]['currentPage'] += 1
                }
            }

            let data = {
                type: 'select-paging-obat',
                page: page,
                idDokter: this.rowReg.ar_dokter_id,
                search: this[col][k]['search']
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.$parent.mObatPreferensi = res.data.data
            })
        },

      
        openObat(v = null) {
            let data = {
                ard_ar_id: this.row.arm_ar_id,
                ard_arm_id: this.row.arm_id,
                ard_jenis: 1,
                ard_item_id: null,
                ard_nama: null,
                ard_jumlah: null,
                ard_satuan: null,
                ard_frekuensi: null,
                ard_frekuensi_lainnya: null,
                ard_keterangan: null,
                id: null,
                currentPage: 1,
                totalRows: 1227,
                search: ''
            }
            if (!this.row.isEdit) {
                data.type = 'add-resep-dokter'
                Gen.apiRest(
                    "/do/" + 'RekamMedis', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.resepDokter.push(resp.row)
                })
            } else {
                this.resepDokter.push(data)
            }

        },

        searchICD10Data: _.debounce(function () {
            let data = {
                search: this.searchICD10,
                ar_ap_id: this.rowReg.ar_ap_id,
                arm_id: this.row.arm_id,
                type: 'search-icd-10'
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.$parent.riwayatICD10 = resp.data
            })
        }, 500),

        searchICD9Data: _.debounce(function () {
            let data = {
                search: this.searchICD9,
                ar_ap_id: this.rowReg.ar_ap_id,
                arm_id: this.row.arm_id,
                type: 'search-icd-9'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.$parent.riwayatICD9 = resp.data
            })
        }, 500),


        saveICD10() {
            if (this.selectedICD10) {
                this.$parent.loadingOverlay = true
                let data = {
                    id: this.selectedICD10,
                    arm_id: this.row.arm_id,
                    type: 'save-riwayat-icd-10'
                }
                Gen.apiRest(
                    "/do/" + 'RekamMedis', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.openriwayatICD10 = false
                    this.$parent.dataICD10 = res.data.dataICD10
                    this.$parent.loadingOverlay = false
                })
            }
        },

        saveICD9() {
            if (this.selectedICD9) {
                this.$parent.loadingOverlay = true
                let data = {
                    id: this.selectedICD9,
                    arm_id: this.row.arm_id,
                    type: 'save-riwayat-icd-9'
                }
                Gen.apiRest(
                    "/do/" + 'RekamMedis', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.openriwayatICD9 = false
                    this.$parent.dataICD9 = res.data.dataICD9
                    this.$parent.loadingOverlay = false 
                })
            }
        },

        searchObatData: _.debounce(function () {
            let data = {
                search: this.searchObat,
                ar_ap_id: this.rowReg.ar_ap_id,
                arm_id: this.row.arm_id,
                type: 'search-obat'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.$parent.riwayatObat = resp.data
            })
        }, 500),

        saveObat() {
            if (this.selectedObat) {
                this.$parent.loadingOverlay = true
                let data = {
                    id: this.selectedObat,
                    arm_id: this.row.arm_id,
                    ar_id: this.rowReg.ar_id,
                    type: 'save-riwayat-obat'
                }
                Gen.apiRest(
                    "/do/" + 'RekamMedis', {
                    data: data
                    },
                    "POST"
                ).then(res=> {
                    this.openRiwayatObat = false
                    this.$parent.loadingOverlay = false
                    this.$parent.resepDokter = res.data.resepDokter
                })
            }
        },


        resetData() {
            this.searchObat = ''
            this.searchICD10 = '',
            this.searchICD9 = '',
            this.openFormRacikan = false
            this.openFormPaketAlkes = false
            this.$parent.apiGet()
        },

        getObat(val) {
            let index = this.mObat.findIndex(x => x.value == val)
            if (index !== -1) {
            return this.mObat[index]['text']
            } else {
            return null
            }
        },
    
        countLen() {
            setTimeout(() => {
            this.obatLen = $('.sp-col ul li').length
            }, 100)
        },

        resetObat() {
            this.$parent.loadingOverlay = true
            let data = {
                id: this.row.arm_id,
                type: 'get-resep-dokter'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.$parent.resepDokter = resp.data
                this.$parent.loadingOverlay = false
            })
        },

        doSubmitRacikan() {
            this.$refs['VFormRacikan'].validate().then(success => {
                if (success) {
                    let data = this.rowRacikan
                    let idx = this.resepDokter.findIndex(x => x.ard_id == this.rowRacikan.ard_id)
                    this.resepDokter[idx]['ard_satuan'] = this.rowRacikan.ard_satuan
                    this.resepDokter[idx]['ard_jumlah'] = this.rowRacikan.ard_jumlah
                    this.resepDokter[idx]['ard_racikan'] = this.rowRacikan.ard_racikan
                    this.openFormRacikan = false
                } else {
                    setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                    }, 500)
                }
            })
        },

        getMasterObat(data, k) {
            const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
                return k2 != k
            }), v2 => {
                return v2.value
            })
                return _.filter(this.mObat, v2 => {
                return sldMenu.indexOf(v2.value) <= -1
            })
        },
        selectObat(e, k) {
            let index = this.mObat.findIndex(x => x.value == e)
            if (index !== -1) {
                let data = this.mObat[index]
                this.rowRacikan.ard_racikan[k]['dosis'] = data.dosis
                this.rowRacikan.ard_racikan[k]['satuan_dosis'] = data.satuan_dosis
                this.rowRacikan.ard_racikan[k]['satuan_jumlah'] = data.satuan
            }
        },

        filterObat: _.debounce(function (e, k) {
            if (e) {
                this.resepDokter[k]['search'] = e
                let id = []
                for (let i = 0; i < (this.resepDokter || []).length; i++) {
                    if (this.resepDokter[i]['ard_item_id'] && this.resepDokter[i]['ard_jenis'] == 1) {
                    id.push(this.resepDokter[i]['ard_item_id'])
                    }
                }
                Gen.apiRest('/do/' + 'RekamMedis', {
                    data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                    }
                }, 'POST').then(res => {
                    this.$parent.mObatPreferensi = res.data.data
                    this.resepDokter[k]['currentPage'] = 1
                    this.resepDokter[k]['totalRows'] = res.data.totalRows
                })
            }
        }, 10),


        
        selectJumlahSatuan(e, k) {
            let data = {
                id: e,
                type: 'get-by-obat'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.resepDokter[k].ard_satuan = res.data.satuan
                this.resepDokter[k].ard_nama = res.data.nama
                this.resepDokter[k].ard_racikan = null

                this.resepDokter[k].ard_dosis = res.data.dosis
                this.resepDokter[k].ard_satuan_dosis = res.data.satuan_dosis
                this.resepDokter[k].ard_item_lainnya = null
            })
        },
        selectJumlahSatuanAlkes(e, k) {
            let data = {
                id: e,
                type: 'get-by-alkes'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.resepDokter[k].ard_satuan = res.data.satuan
                this.resepDokter[k].ard_nama = res.data.nama
                this.resepDokter[k].ard_racikan = null

                this.resepDokter[k].ard_dosis = null
                this.resepDokter[k].ard_satuan_dosis = null
                this.resepDokter[k].ard_frekuensi = null
                this.resepDokter[k].ard_frekuensi_lainnya = null
                this.resepDokter[k].ard_item_lainnya = null
            })
        },
        selectJumlahSatuanRacikan(e, k) {
            let data = {
                id: e,
                type: 'get-by-racikan'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.resepDokter[k].ard_satuan = res.data.satuan
                this.resepDokter[k].ard_nama = res.data.nama
                this.resepDokter[k].ard_racikan = res.data.data

                this.resepDokter[k].ard_dosis = null
                this.resepDokter[k].ard_satuan_dosis = null
                this.resepDokter[k].ard_frekuensi = null
                this.resepDokter[k].ard_frekuensi_lainnya = null
                this.resepDokter[k].ard_item_lainnya = null
            })
        },
        changeJenis(e, k) {
            this.resepDokter[k].ard_item_id = null
            this.resepDokter[k].ard_frekuensi = null
            this.resepDokter[k].ard_frekuensi_lainnya = null
        },
        toRacikan(v,isEdit=false) {
            this.$parent.loadingOverlay = true
            let data = {
                id: v.ard_item_id,
                type: 'get-racikan-data'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.$parent.loadingOverlay = false
                this.isEditRacikan = isEdit
                this.rowRacikan = {
                    ...v,
                    ...res.data.row
                }
                this.mObat = res.data.mObat
                this.openRacikan = true
            })
        },
        addObat() {
            this.rowRacikan.ard_racikan.push({
                value: null,
                dosis: null,
                satuan_dosis: null,
                jumlah: null,
                satuan_jumlah: null,
            })
        },
        removeObat(v, k) {
            if (!this.row.isEdit) {
                let data = {
                    type: 'remove-resep-dokter',
                    id: v.ard_id
                }
                Gen.apiRest(
                    "/do/" + 'RekamMedis', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.resepDokter.splice(k, 1)
                })
                } else {
                this.resepDokter.splice(k, 1)
            }
        },

            
        // POLI ADJSTMENT //
        selectJumlahSatuanPaketAlkes(e, k) {
            let data = {
                id: e,
                type: 'get-by-paket-alkes'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.resepDokter[k].ard_satuan = res.data.satuan
                this.resepDokter[k].ard_nama = res.data.nama
                this.resepDokter[k].ard_paket_alkes = res.data.data

                this.resepDokter[k].ard_dosis = null
                this.resepDokter[k].ard_satuan_dosis = null
                this.resepDokter[k].ard_frekuensi = null
                this.resepDokter[k].ard_frekuensi_lainnya = null
                this.resepDokter[k].ard_item_lainnya = null
            })
        },

        toPaketAlkes(v,isEdit=false) {
            this.loadingOverlay = true
            let data = {
                id: v.ard_item_id,
                type: 'get-paket-alkes-data'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isEditAlkes = isEdit
                this.loadingOverlay = false
                this.rowPaketAlkes = {
                    ...v,
                    ...res.data.row
                }
                this.openPaketAlkes = true
            })
        },
        
        doOpenFormAlkes(){
            this.openFormPaketAlkes = true
            this.openPaketAlkes = false
        },
        
        selectAlkes(e, k) {
            let index = this.mAlatKesehatan.findIndex(x => x.value == e)
            if (index !== -1) {
                let data = this.mAlatKesehatan[index]
                console.log(data)
                this.rowPaketAlkes.ard_paket_alkes[k]['dosis'] = data.dosis
                this.rowPaketAlkes.ard_paket_alkes[k]['satuan_dosis'] = data.satuan_dosis
                this.rowPaketAlkes.ard_paket_alkes[k]['satuan_jumlah'] = data.satuan
            }
        },
        
        doSubmitAlkes() {
            this.$refs['VFormPaketAlkes'].validate().then(success => {
                if (success) {
                    let data = this.rowPaketAlkes
                    let idx = this.resepDokter.findIndex(x => x.ard_id == this.rowPaketAlkes.ard_id)
                    this.resepDokter[idx]['ard_paket_alkes'] = this.rowPaketAlkes.ard_paket_alkes
                    this.openFormPaketAlkes = false
                } else {
                    setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                    }, 500)
                }
            })
        },
        addAlkes(){
            this.rowPaketAlkes.ard_paket_alkes.push({
            value: null,
            dosis: null,
            satuan_dosis: null,
            jumlah: null,
            satuan_jumlah: null,
            })
        },
        // POLI ADJSTMENT //

        
      // resep
      openTemplateResep(){
        let idRefTemplate = this.rowReg.ar_dokter_id
        this.templateResep = false
        let data = {
            id_dokter: idRefTemplate,
            type: 'get-template-resep'
        }
        Gen.apiRest(
        "/do/" + 'RekamMedis', {
            data: data
        },"POST").then(res => {
            this.openTemplate = true
            this.templateResep = res.data.data
        })
      // kalo super admin biar bisa ngetrack pake template dpjp
      },
      openDetail(data){
        this.dataDetail = data
        this.openDetailIsi = true
      },
      submitTemplate(){
        if(!(this.selectedTemplate||[]).length){
            return this.$swal({
            icon: 'error',
            title: 'Wajib Memilih Satu Item Template'
            })
        }
        let dataPost = this.selectedTemplate
        for (let i = 0; i < (dataPost||[]).length; i++) {
            dataPost[i]['ard_code'] = null
            dataPost[i]['ard_ar_id'] = this.row.arm_ar_id
            dataPost[i]['ard_arm_id'] = this.row.arm_id

            dataPost[i]['ard_is_alergi'] = null
            dataPost[i]['ard_signa1'] = null
            dataPost[i]['ard_signa2'] = null
        }
        this.$swal({
            html: 'Menyalin resep...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                this.$swal.showLoading()
            }
        })
        
        if (!this.row.isEdit) {
            let data = {
            dataPost : dataPost,
            arm_id : this.row.arm_id,
            type : 'replace-resep-from-template'
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                data: data
                },
                "POST"
            ).then(res => {
                this.$parent.resepDokter = res.data.data
                this.$nextTick(() => {
                this.$swal().close()
                })
                this.openTemplate = false
            })
        } else {
            this.$parent.resepDokter = dataPost
            this.$nextTick(() => {
            this.$swal().close()
            })
            this.openTemplate = false
        }
      },

      
      // icd 9 inform consent
      removeICDForm(){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menghapus data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {  
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'RekamMedis', {
                data: {
                  type: 'remove-ic',
                  id: this.row.apdf_inform_consent_id
                }
            }, 'POST').then(res => {
                this.row.apdf_inform_consent_id = null
                this.loadingOverlay = false
            })
          })
      },
      openInformConsentDetail(v){
          Gen.apiRest('/do/' + 'RekamMedis', {
              data: {
                  type: 'get-data-ic',
                  id: v.api9_informed_consent,
              }
          }, 'POST').then(res => {
              this.rowIC = res.data.row 
              this.rowIC.mi9_name = v.mi9_name
              this.rowIC.mi9_code = v.mi9_code
              this.rowIC.id_icd = v.api9_id
              this.rowIC.id_assesment = null
              this.openModalICDetail = true                   
          })
      },
      openInformConsent(v){
          console.log(v)
          if(!v.api9_informed_consent){              
              this.rowIC.aric_persetujuan_tindakan = null
              this.rowIC.aric_is_pasien_setuju = null

              if(v.mi9_informed_consent){
                  Gen.apiRest('/do/' + 'RekamMedis', {
                      data: {
                          type: 'autofill-ic',
                          id: v.mi9_informed_consent,
                      }
                  }, 'POST').then(res => {
                      this.rowIC = res.data.row
                      this.rowIC.mi9_name = v.mi9_name
                      this.rowIC.mi9_code = v.mi9_code
                      this.rowIC.id_icd = v.api9_id
                      this.rowIC.aric_id = null
                      this.rowIC.id_assesment = null
                  })
              }else{
                this.rowIC.aric_diagnosis = null
                this.rowIC.aric_id = null
                this.rowIC.aric_dasar_diagnosis = null
                this.rowIC.aric_tindakan_dokter = null
                this.rowIC.aric_indikasi_tindakan = null
                this.rowIC.aric_tata_cara = null
                this.rowIC.aric_tujuan = null
                this.rowIC.aric_risiko = null
                this.rowIC.aric_kompilasi = null
                this.rowIC.aric_prognosis = null
                this.rowIC.aric_alternatif = null
                this.rowIC.aric_lainnya = null
                this.rowIC.id_assesment = null
              }

          }else{  
              Gen.apiRest('/do/' + 'RekamMedis', {
                  data: {
                      type: 'get-data-ic',
                      id: v.api9_informed_consent,
                  }
              }, 'POST').then(res => {
                  this.rowIC = res.data.row 
                  this.rowIC.mi9_name = v.mi9_name
                  this.rowIC.mi9_code = v.mi9_code
                  this.rowIC.id_icd = v.api9_id  
                  this.rowIC.aric_created_on = "Tindakan"  
                  this.rowIC.aric_id = this.rowIC.aric_id||null
                  this.rowIC.id_assesment = null
              })
              // console.log(edit)
          }

          this.rowIC.mi9_name = v.mi9_name
          this.rowIC.mi9_code = v.mi9_code
          this.rowIC.id_icd = v.api9_id

          this.openModalIC = true
      },
      openInformConsentDetailForm(v){
          Gen.apiRest('/do/' + 'RekamMedis', {
              data: {
                  type: 'get-data-ic',
                  id: this.row.apdf_inform_consent_id,
              }
          }, 'POST').then(res => {
              this.rowIC = res.data.row 
              this.rowIC.mi9_name = this.row.apdf_tindakan
              this.rowIC.mi9_code = ''
              this.rowIC.id_assesment = this.row.apdf_id
              this.openModalICDetail = true                   
          })
      },
      openInformConsentForm(){
        if(!this.row.apdf_inform_consent_id){
            this.rowIC.aric_diagnosis = null
            this.rowIC.aric_id = null
            this.rowIC.aric_dasar_diagnosis = null
            this.rowIC.aric_tindakan_dokter = null
            this.rowIC.aric_indikasi_tindakan = null
            this.rowIC.aric_tata_cara = null
            this.rowIC.aric_tujuan = null
            this.rowIC.aric_risiko = null
            this.rowIC.aric_kompilasi = null
            this.rowIC.aric_prognosis = null
            this.rowIC.aric_alternatif = null
            this.rowIC.aric_lainnya = null
            this.rowIC.mi9_name = this.row.apdf_tindakan
            this.rowIC.mi9_code = ''
            this.rowIC.id_assesment = this.row.apdf_id
        } else{
            Gen.apiRest('/do/' + 'RekamMedis', {
                data: {
                    type: 'get-data-ic',
                    id: this.row.apdf_inform_consent_id,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = this.row.apdf_tindakan   
                this.rowIC.id_assesment = this.row.apdf_id
            })
        }

        this.openModalIC = true
      },
      submitInformConcent(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false

                let data = this.rowIC
                data.type = 'submit-data-inform-consent'
                data.inForm = 'TERAPI'
                data.aric_ar_id = this.rowReg.ar_id
                data.arm_id = this.row.arm_id,
                data.aric_name = this.rowIC.mi9_name + (this.rowIC.mi9_code ? " ("+this.rowIC.mi9_code+")" : "")
                
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
                ).then(res => {
                    // this.$parent.apiGet()  
// 
                    this.$parent.dataICD9 = res.data.dataICD9
                    this.$parent.tindakanLainnya = res.data.tindakanLainnya
                    this.row.apdf_inform_consent_id = res.data.ic
                    this.row.apdf_tindakan = res.data.tindakan
                    
                    this.rowIC.aric_id = null

                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
      },
      addTindakanLainnya(){
        let data = {
          artla_arm_id: this.row.arm_id,
          artla_inform_consent: null,
          type: 'add-tindakan-lainnya',
        }
        Gen.apiRest(
            "/do/" + 'RekamMedis', {
            data: data
            },
            "POST"
        ).then(res => {
          this.$parent.tindakanLainnya.push(res.data.data)
        }) 
      },
      removeICDLainnya(row,k){
        let data = row
        data.type = 'delete-tindakan-lainnya'
        Gen.apiRest(
            "/do/" + 'RekamMedis', {
            data: data
            },
            "POST"
        ).then(res => {
          this.$parent.tindakanLainnya.splice(k,1)
        }) 
      },

      openInformConsentDetailFormV2(v,k){
          Gen.apiRest('/do/' + 'RekamMedis', {
              data: {
                  type: 'get-data-ic',
                  id: v.artla_inform_consent,
              }
          }, 'POST').then(res => {
              this.rowIC = res.data.row 
              this.rowIC.mi9_name = v.artla_tindakan
              this.rowIC.mi9_code = ''
              this.rowIC.id_lainnya = v.artla_id
              this.rowIC.id_assesment = null

              this.openModalICDetail = true                   
          })
      },
      openInformConsentFormV2(v,k){
        console.log(v)
          if(!v.artla_inform_consent){
            this.rowIC.aric_diagnosis = null
            this.rowIC.aric_id = null
            this.rowIC.aric_dasar_diagnosis = null
            this.rowIC.aric_tindakan_dokter = null
            this.rowIC.aric_indikasi_tindakan = null
            this.rowIC.aric_tata_cara = null
            this.rowIC.aric_tujuan = null
            this.rowIC.aric_risiko = null
            this.rowIC.aric_kompilasi = null
            this.rowIC.aric_prognosis = null
            this.rowIC.aric_alternatif = null
            this.rowIC.aric_lainnya = null
            this.rowIC.mi9_name =  v.artla_tindakan
            this.rowIC.mi9_code = ''
            this.rowIC.id_lainnya = v.artla_id
            this.rowIC.id_assesment = null

        } else{
            Gen.apiRest('/do/' + 'RekamMedis', {
                data: {
                    type: 'get-data-ic',
                    id: v.artla_inform_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name =  v.artla_tindakan   
                this.rowIC.id_assesment = null
            })
        }

        this.openModalIC = true
      },
    
      autoSaveTindakanLainnya: _.debounce(function (data) {
          let dataPost = {
              type: 'auto-save-tindakan-lainnya',
              data: data
          }
          Gen.apiRest(
              "/do/" + 'RekamMedis', {
              data: dataPost
              },
              "POST"
          )
      }, 1000),

      // icd 9

    },
    watch:{
        'row.apdf_is_edukasi'(v){
            if(v == "Y"){
            this.row.apdf_cara_pulang.push('P')
            }else{
            let index = this.row.apdf_cara_pulang.findIndex(x => x == 'P')
            if(index != -1){
                this.row.apdf_cara_pulang.splice(index,1)
            }
            }
        },
        
        'row.apdf_is_rawat_inap'(v){
            if(v == "Y"){
            this.row.apdf_cara_pulang.push('RI')
            }else{
            let index = this.row.apdf_cara_pulang.findIndex(x => x == 'RI')
            if(index != -1){
                this.row.apdf_cara_pulang.splice(index,1)
            }
            }
        },

        'row.apdf_is_dalam_perawatan'(v){
            if(v != "Y" && this.row.apdf_is_done != "Y"){
                this.row.apdf_is_kontrol = "N"
                this.rowReg.ar_is_rujukan = "N"
            }
            
            if(v != "Y" && (this.row.artlr_type == 'RI' || this.row.artlr_type == 'RP')){
                this.row.apdf_is_kontrol = "N"
                this.rowReg.ar_is_rujukan = "N"
            }

            if(v == "Y"){
                this.row.apdf_cara_pulang.push('K')
                this.row.apdf_is_kontrol = "Y"
            }else{
                let index = this.row.apdf_cara_pulang.findIndex(x => x == 'K')
                if(index != -1){
                    this.row.apdf_cara_pulang.splice(index,1)
                }
            }
        },
        
        'row.apdf_is_done'(v){
            if(v != "Y" && this.row.apdf_is_dalam_perawatan != "Y"){
                this.row.apdf_is_kontrol = "N"
                this.rowReg.ar_is_rujukan = "N"
            }
            
            if(v == "Y"){
                this.rowReg.ar_is_rujukan = "Y"
            }

            if(v != "Y" && (this.row.artlr_type == 'RK' || this.row.artlr_type == 'RB' || this.row.artlr_type == 'RBN')){
                this.row.apdf_is_kontrol = "N"
                this.rowReg.ar_is_rujukan = "N"
            }

            if(v == "Y"){   
                this.row.apdf_cara_pulang.push('RU')
            }else{
                let index = this.row.apdf_cara_pulang.findIndex(x => x == 'RU')
                if(index != -1){
                    this.row.apdf_cara_pulang.splice(index,1)
                }
            }
        },

        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        dataICD9: {
          handler(v) {
              this.autoSaveICD9(v)
          },
          deep: true
        },
        dataICD10: {
          handler(v) {
              this.autoSaveICD10(v)
          },
          deep: true
        },
        resepDokter: {
            handler(v) {
                this.autoSaveResepObat(v)
            },
            deep: true
        },

        openAddObat(v) {
            if (!v) {
                this.resetObat()
            }
        },
        
        tindakanLainnya: {
            handler(vNew, vOld) {
            //if (JSON.stringify(vNew) != JSON.stringify(vOld)) {
            this.autoSaveTindakanLainnya(vNew)
            },
            deep: true
        },
    }
}

</script>
