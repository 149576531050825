<template>
  <div>
    <validation-observer ref="VFormCPPT">
      <b-form @submit.prevent="doSubmit">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Nama Petugas yang Mengisi <strong class="text-danger">*</strong></label>
                  <b-form-input v-model="rowSBAR.arcps_nama_petugas" type="text" class="form-control" placeholder="Nama Petugas yang Mengisi"/>
                  <VValidate name="Nama Petugas yang Mengisi" v-model="rowSBAR.arcps_nama_petugas"
                    :rules="toValidate(mrValidationSBAR.arcps_nama_petugas)" />

                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Tanggal <strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <datepicker v-model="rowSBAR.arcps_tanggal" input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                    </div>
                  </div>
                  <VValidate name="Tanggal" v-model="rowSBAR.arcps_tanggal"
                    :rules="toValidate(mrValidationSBAR.arcps_tanggal)" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Jam <strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowSBAR.arcps_jam">
                    </vue-timepicker>
                  </div>
                  <VValidate name="Jam" v-model="rowSBAR.arcps_jam"
                    :rules="toValidate(mrValidationSBAR.arcps_jam)" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <b-form-checkbox v-model="rowSBAR.arcps_laporan_nilai_kritis" value="Y" unchecked-value="N">Pelaporan Nilai Kritis
              </b-form-checkbox>
              <b-form-checkbox v-model="rowSBAR.arcps_laporan_kondisi_pasien" value="Y" unchecked-value="N">Pelaporan Kondisi Pasien
              </b-form-checkbox>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="wrap_line p-0">
                  <h3 class="px-3 py-2">Isi Laporan SBAR</h3>
                  <table class="table table-sm border-bottom">
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="cppt-report-content">
                          <div>Melaporkan pasien dengan identitas</div>
                          <ul class="mb-0 pl-3">
                            <li>Nama: {{isParent.row.ap_fullname||"-"}}</li>
                            <li>Tgl. Lahir/Usia: {{isParent.row.ap_dob | moment('DD MMMM YYYY')}} / {{isParent.row.ap_usia_with_ket||"-"}}</li>
                            <li>No. RM: {{isParent.row.ap_code||"-"}}</li>
                          </ul>
                          <div class="mb-1 mt-1">Dengan keluhan utama <strong class="text-danger">*</strong>
                            <b-form-textarea v-model="rowSBAR.arcps_keluhan_utama" id="Keluhan Utama" rows="3" class="form-control"></b-form-textarea>
                            <VValidate name="Keluhan Utama" v-model="rowSBAR.arcps_keluhan_utama"
                              :rules="toValidate(mrValidationSBAR.arcps_keluhan_utama)" />
                          </div>
                          <div class="mb-1 mt-1">Diagnosa Medis<strong class="text-danger">*</strong>
                            <b-form-textarea v-model="rowSBAR.arcps_diagnosa_medis" id="Diagnosa Medis" rows="3" class="form-control"></b-form-textarea>
                            <VValidate name="Diagnosa Medis" v-model="rowSBAR.arcps_diagnosa_medis"
                              :rules="toValidate(mrValidationSBAR.arcps_diagnosa_medis)" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>B</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="cppt-report-content">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-2">
                                <div class="font-weight-semibold">Riwayat Alergi:</div>
                                <table class="table table-sm table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="33%">Jenis</th>
                                      <th>Informasi Alergi</th>
                                      <th width="64"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(v1,k1) in (rowSBAR['arcps_riwayat_alergi']||[])" :key="k1+'aler'">
                                        <td>
                                            <v-select placeholder="Pilih Jenis"
                                            v-model="v1['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </td>
                                        <td>
                                            <vue-typeahead-bootstrap
                                            v-model="v1['name']"
                                            :data="mAlergi" @input="searchAlergi(v1['name'])" placeholder="Pilih Alergi"
                                            />
                                        </td>
                                        <td>
                                        <a href="javascript:;" @click="removeAlergi(k,k1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                        </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="3" class="text-center">
                                      <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-2">
                                <label for="sbarTindakanInvasif">Tindakan Invasif</label>
                                <ol class="mb-0" style="margin-left: -35px;" v-if="(rowSBAR.arcps_tindakan_invasif||[]).length">
                                    <li v-for="(v,k) in (rowSBAR.arcps_tindakan_invasif||[])" :key="k" class="mt-1">
                                    <div class="input-group">
                                        <input v-model="rowSBAR.arcps_tindakan_invasif[k]" type="text" class="form-control form-control-sm">
                                        <div class="input-group-append">
                                        <a href="javascript:;" 
                                        @click="(rowSBAR.arcps_tindakan_invasif||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                            <i class="icon-trash-alt"></i>
                                        </a>
                                        <a href="javascript:;" v-if="k == ((rowSBAR.arcps_tindakan_invasif||[]).length - 1)" @click="rowSBAR.arcps_tindakan_invasif.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                            <i class="icon-plus2"></i>
                                        </a>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="rowSBAR.arcps_tindakan_invasif[k]" :rules="{required:1}" />
                                    </li>
                                </ol>
                                <div class="" v-else>
                                    <button type="button" class="btn btn-light mr-3" @click="rowSBAR.arcps_tindakan_invasif.push('')">Tambah Tindakan</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-2">
                                <label for="sbarKeluhanSaatIni">Keluhan Saat Ini <strong class="text-danger">*</strong></label>
                                <b-form-textarea v-model="rowSBAR.arcps_keluhan_saat_ini" rows="3" class="form-control"></b-form-textarea>
                                <VValidate name="Keluhan Saat Ini" v-model="rowSBAR.arcps_keluhan_saat_ini"
                                  :rules="toValidate(mrValidationSBAR.arcps_keluhan_saat_ini)" />
                              </div>
                              <div>
                                <label for="sbarKeluhanSaatIni">Pengobatan yang telah dilakukan:</label>
                                <b-form-textarea v-model="rowSBAR.arcps_pengobatan_yang_dilakukan" rows="3" class="form-control"></b-form-textarea>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-2">
                                <label for="sbarRiwayatPembedahan">Riwayat Pembedahan</label>
                                <b-form-textarea v-model="rowSBAR.arcps_riwayat_pembedahan" rows="3" class="form-control"></b-form-textarea>
                              </div>
                              
                              <div class="mb-2">
                                <label> Pasien memiliki riwayat penyakit terdahulu: <strong class="text-danger">*</strong> </label>
                                <b-form-textarea v-model="rowSBAR.arcps_riwayat_penyakit" id="Riwayat Penyakit" rows="3" class="form-control"></b-form-textarea>
                                <VValidate name="Riwayat Penyakit" v-model="rowSBAR.arcps_riwayat_penyakit"
                                  :rules="toValidate(mrValidationSBAR.arcps_riwayat_penyakit)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="cppt-report-content">
                          <div class="mb-2">
                            <div class="font-weight-semibold">Tanda-tanda Vital:</div>
                            <div class="row">
                              <div class="col-md-3">
                                <div class="mb-2">
                                  <label>Tekanan Darah</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_tekanan_darah_min" type="text" name="name"
                                      class="form-control" placeholder="Systole" />

                                      <div class="input-group-append input-group-prepend">
                                        <span class="input-group-text">/</span>
                                      </div>

                                      <b-form-input :formatter="number" placeholder="Diastole"
                                      v-model="rowSBAR.arcps_ttv_tekanan_darah_max" type="text"
                                      class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                      </div>
                                  </div>     
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>Nadi</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_nadi" type="text" name="name"
                                      class="form-control" placeholder="Nadi" />

                                      <div class="input-group-append input-group-prepend">
                                      <span class="input-group-text">x/mnt</span>
                                      </div>

                                      <div class="input-group-append">
                                          <div style="width: 150px;">
                                              <v-select placeholder="Pilih Label" v-model="rowSBAR.arcps_ttv_label"
                                              :options="Config.mr.StatusRegular" label="text"
                                              :clearable="true" :reduce="v=>v.value"></v-select>
                                          </div>
                                      </div>

                                  </div>   
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>GDA</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_gula_darah" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">mg/dL</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>Pernafasan</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_pernafasan" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">x/mnt</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>SPO2</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_spo2" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">%</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>Suhu</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_suhu" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">C</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>Berat Badan</label>
                                  <div class="input-group">
                                    <b-form-input @input="hitungBMI()" :formatter="number"
                                    v-model="rowSBAR.arcps_ttv_weight" type="text"
                                    class="form-control" />
                                    <div class="input-group-append">
                                    <span class="input-group-text">kg</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <div class="mb-2">
                                  <label>Tinggi</label>
                                  <div class="input-group">
                                      <b-form-input @input="hitungBMI()" :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_height" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">cm</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">       
                                  <label>BMI</label>
                                  <div class="input-group">
                                      <b-form-input disabled v-model="rowSBAR.arcps_ttv_bmi" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">m2</span>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-2">
                                <div class="mb-2">
                                  <label>Lingkar Kepala</label>
                                  <div class="input-group">
                                      <b-form-input @input="hitungBMI()" :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_lingkar_kepala" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">cm</span>
                                      </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                                <div class="mb-2">
                                  <label>Luas Permukaan Tubuh Anak</label>
                                  <div class="input-group">
                                      <b-form-input :formatter="number"
                                      v-model="rowSBAR.arcps_ttv_luas_permukaan_anak" type="text"
                                      class="form-control" />
                                      <div class="input-group-append">
                                      <span class="input-group-text">cm</span>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5">
                              <div class="mb-2">
                                <label for="sbarPemeriksaanFisik">Tingkat Kesadaran</label>
                                <b-form-input :formatter="number"
                                  v-model="rowSBAR.arcps_ttv_kesadaran" type="text"
                                  class="form-control" />
                              </div>
                              <div class="mb-2">
                                <div class="form-row">
                                  <div class="col-md-4">
                                    <div><label for="tksE">E</label>
                                    <b-form-input @input="countTK()" :formatter="number"
                                    v-model="rowSBAR.arcps_ttv_kesadaran_e" type="text"
                                    class="form-control" />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div><label for="tksM">M</label>
                                    <b-form-input @input="countTK()" :formatter="number"
                                    v-model="rowSBAR.arcps_ttv_kesadaran_m" type="text"
                                    class="form-control" />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div><label for="tksV">V</label>
                                    <b-form-input @input="countTK()" :formatter="number"
                                    v-model="rowSBAR.arcps_ttv_kesadaran_v" type="text"
                                    class="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="mb-2">
                                <label for="sbarPemeriksaanFisik">Pemeriksaan Fisik</label>
                                <textarea v-model="rowSBAR.arcps_pemeriksaan_fisik" name="sbarPemeriksaanFisik" id="sbarPemeriksaanFisik" rows="2" class="form-control form-control-sm"></textarea>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="mb-2">
                                <label for="sbarHasilLab">Tindakan Lab</label>
                                <ol class="mb-0" style="margin-left: -35px;" v-if="(rowSBAR.arcps_hasil_lab||[]).length">
                                    <li v-for="(v,k) in (rowSBAR.arcps_hasil_lab||[])" :key="k" class="mt-1">
                                    <div class="input-group">
                                        <input v-model="rowSBAR.arcps_hasil_lab[k]['value']" type="text" class="form-control form-control-sm">
                                        <div class="input-group-append">
                                        <a href="javascript:;" 
                                        @click="(rowSBAR.arcps_hasil_lab||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                            <i class="icon-trash-alt"></i>
                                        </a>
                                        <a href="javascript:;" v-if="k == ((rowSBAR.arcps_hasil_lab||[]).length - 1)" @click="rowSBAR.arcps_hasil_lab.push({value:''})" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                            <i class="icon-plus2"></i>
                                        </a>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tindakan Lab `+(k+1)" v-model="rowSBAR.arcps_hasil_lab[k]['value']" :rules="{required:1}" />
                                    </li>
                                </ol>
                                <div class="" v-else>
                                    <button type="button" class="btn btn-light mr-3" @click="rowSBAR.arcps_hasil_lab.push({value:''})">Tambah Tindakan</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="mb-2">
                                <label for="sbarHasilRadio">Tindakan Radiologi</label>
                                <ol class="mb-0" style="margin-left: -35px;" v-if="(rowSBAR.arcps_hasil_radiologi||[]).length">
                                    <li v-for="(v,k) in (rowSBAR.arcps_hasil_radiologi||[])" :key="k" class="mt-1">
                                    <div class="input-group">
                                        <input v-model="rowSBAR.arcps_hasil_radiologi[k]['value']" type="text" class="form-control form-control-sm">
                                        <div class="input-group-append">
                                        <a href="javascript:;" 
                                        @click="(rowSBAR.arcps_hasil_radiologi||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                            <i class="icon-trash-alt"></i>
                                        </a>
                                        <a href="javascript:;" v-if="k == ((rowSBAR.arcps_hasil_radiologi||[]).length - 1)" @click="rowSBAR.arcps_hasil_radiologi.push({value:''})" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                            <i class="icon-plus2"></i>
                                        </a>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tindakan Radiologi `+(k+1)" v-model="rowSBAR.arcps_hasil_radiologi[k]['value']" :rules="{required:1}" />
                                    </li>
                                </ol>
                                <div class="" v-else>
                                    <button type="button" class="btn btn-light mr-3" @click="rowSBAR.arcps_hasil_radiologi.push({value:''})">Tambah Tindakan</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="40">
                        <div class="label_code label_code_sm m-0">
                          <h2>R</h2>
                        </div>
                      </td>
                      <td class="align-top pl-0">
                        <div class="row">
                          <div class="col-md-5">
                            <div class="mb-2">
                              <label for="sbarHasilLab">Usulan Kolaborasi</label>
                              <ol class="mb-1" style="margin-left: -35px;" v-if="(rowSBAR.arcps_usulan_kolaborasi||[]).length">
                                <li v-for="(v6,k6) in (rowSBAR.arcps_usulan_kolaborasi||[])" :key="k6" class="mt-1">
                                  <div class="input-group">
                                      <input v-model="rowSBAR.arcps_usulan_kolaborasi[k6]" type="text" class="form-control form-control-sm">
                                      <div class="input-group-append">
                                      <a href="javascript:;"
                                      @click="(rowSBAR.arcps_usulan_kolaborasi||[]).splice(k6,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                          <i class="icon-trash-alt"></i>
                                      </a>
                                      <a href="javascript:;" v-if="k6 == ((rowSBAR.arcps_usulan_kolaborasi||[]).length - 1)" @click="rowSBAR.arcps_usulan_kolaborasi.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                          <i class="icon-plus2"></i>
                                      </a>
                                      </div>
                                  </div>
                                  <VValidate :name="`Usulan Kolaborasi `+(k6)" v-model="rowSBAR.arcps_usulan_kolaborasi[k6]" :rules="{required:1}" />
                                </li>
                              </ol>
                              <div class="">
                                <button type="button" class="btn btn-light mr-3" @click="rowSBAR.arcps_usulan_kolaborasi.push('')">Tambah Tindakan</button>
                              </div>
                              
                              <div class="mb-2"></div>
                                <b-form-checkbox v-model="rowSBAR.arcps_tindak_lanjut_laporan_nilai_kritis" value="Y" unchecked-value="N">
                                Tindak Lanjut Pelaporan Nilai Kritis
                                </b-form-checkbox>
                                <b-form-checkbox v-model="rowSBAR.arcps_mohon_advis_selanjutnya" value="Y" unchecked-value="N">
                                Mohon Advis Selanjutnya
                                </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div class="wrap_line p-0">
                  <div class="px-3 pt-3">
                    <h3>Perintah Dokter</h3>
                  </div>
                  <div v-for="(v,k) in (advisData||[])" :key="k">
                    <div class="p-3 border-top">
                      <div>
                        <label for="advisDpjp2">Advis {{k+1}}</label>
                        <b-form-textarea v-model="advisData[k]['arcsa_advis_text']" rows="2" class="form-control"></b-form-textarea>
                        <VValidate :name="'Advis #'+(k+1)" v-model="advisData[k]['arcsa_advis_text']"
                          :rules="{required: 1, min:3, max: 256}" />
                      </div>

                      <div class="mt-2" id="advis2DpjpOpt">
                        <label for="advis2DpjpSelect">Pilih DPJP <strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih DPJP" v-model="advisData[k].arcsa_dpjp"
                        :options="mDPJP" label="text" :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <VValidate :name="'DPJP #'+(k+1)" v-model="advisData[k]['arcsa_dpjp']"
                          :rules="{required: 1, max: 256}" />
                      </div>
                      <div class="mt-2">
                        <div class="d-flex align-items-center justify-content-between">
                          <b-form-checkbox v-model="advisData[k].aransca_is_confirm" value="Y" unchecked-value="N">
                          Lakukan Tulis, Baca Ulang dan Konfirmasi
                          </b-form-checkbox>
                          <a href="javascript:;" @click="removeAdvis(v,k)" class="btn btn-sm btn-icon btn-outline-danger">
                            <i class="icon-trash-alt"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="px-3 pb-3">
                    <a href="javascript:;" @click="addAdvis" class="btn btn-labeled btn-labeled-left btn-info btn-sm">
                      <b><i class="icon-plus2"></i></b>
                      <span>Tambah Advis</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>  
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import { stringSimilarity } from "string-similarity-js"
import Config from '@/libs/Config'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  extends: GlobalVue,
  data(){
    return {
      rowSBAR: {},
      advisData: [],
      mAlergi: [],
      mDPJP: [],
      mrValidationSBAR: {},
    }
  },
  components:{ Datepicker,VueTimepicker},
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  methods: {
    countTK(){
      this.row.arcps_ttv_kesadaran = parseInt(this.row.arcps_ttv_kesadaran_e||0) + parseInt(this.row.arcps_ttv_kesadaran_m||0) + parseInt(this.row.arcps_ttv_kesadaran_v||0)
    },
    toValidate(val){
      return {...val}
    },
    getData(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'RoCPPTSBAR', {
          data: {
            type: 'get-cppt-sbar',
            regId: this.isParent.regID
          }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay = false
      })
    },

    
    addAdvis(v = null) {
      let data = {
        arcsa_arcps_id: this.rowSBAR.arcps_id,
        arcsa_advis_text: null,
        arcsa_dpjp: this.isParent.rowReg.ar_dokter_id,
        id: null
      }
      
      data.type = 'add-advis'
      Gen.apiRest(
        "/do/" + 'RoCPPTSBAR', {
        data: data
        },
        "POST"
      ).then(res => {
        this.getData()
      })
    },
    
    removeAdvis(v, k) {
      let data = {
        type: 'remove-advis',
        id: v.arcsa_id
      }
      Gen.apiRest(
        "/do/" + 'RoCPPTSBAR', {
        data: data
        },
        "POST"
      ).then(res => {
        this.advisData.splice(k, 1)
      })
    }, 
    

    autoSaveAdvisData: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-advis',
        data: data
      }
      Gen.apiRest(
          "/do/" + 'RoCPPTSBAR', {
          data: dataPost
          },
          "POST"
      )
    }, 1000), 
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      Gen.apiRest(
          "/do/"+'RoCPPTSBAR',
          {data:data}, 
          "POST"
      )
    },1000),

    hitungBMI(){
      if(this.rowSBAR.arcps_ttv_weight && this.rowSBAR.arcps_ttv_height){
        this.rowSBAR.arcps_ttv_bmi = this.rowSBAR.arcps_ttv_weight / ((this.rowSBAR.arcps_ttv_height/100)*(this.rowSBAR.arcps_ttv_height/100))
        this.rowSBAR.arcps_ttv_bmi = this.rowSBAR.arcps_ttv_bmi.toFixed(2) 
      }else{
        this.rowSBAR.arcps_ttv_bmi = null
      }
    },
    addAlergi(){
        this.rowSBAR['arcps_riwayat_alergi'].push({
            name : '',
            jenis : null,
        })
    },
    removeAlergi(k,k2){
        this.rowSBAR['arcps_riwayat_alergi'].splice(k2,1)
    },
    searchAlergi: _.debounce(function (e) {
      let data = {
          name: e,
          type: 'get-alergi'
      }
      Gen.apiRest(
          "/do/"+'RoCPPTSBAR',
          {data:data}, 
          "POST"
      ).then(res=>{
          this.mAlergi = res.data.data            
      })
    }, 100),

    doSubmit(){

      this.$refs['VFormCPPT'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowSBAR
              data.type = 'submit-data-sbar'

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + 'RoCPPTSBAR', {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.isParent.openSBAR = false  
                      this.isParent.apiGet()
                  })
              })
            }
          })
        }
      })
    },


  },
  mounted() {
    this.getData()
  },
  watch: {
    rowSBAR: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    advisData: {
      handler(v) {
        this.autoSaveAdvisData(v)
      },
      deep: true
    }
  }
}

</script>