<template>
	<div class="row g-2">
		<div class="col-md-7 col-lg-6">
			<div>
				<label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
				<b-form-input v-model="isParent.row.aka_keluhan_utama" :formatter="normalText" type="text" name="perKeluhan"
					id="perKeluhan" class="form-control" placeholder="Keluhan dari pasien" />
			</div>
			<small class="text-info">*Mohon tuliskan informasi waktu mulai keluhan terjadi</small><br />
			<VValidate name="Keluhan Utama" v-model="isParent.row.aka_keluhan_utama"
				:rules="toValidate(isParent.mrValidation.aka_keluhan_utama)" />

		</div>
		<div class="col-md-9 col-lg-6">
			<div>
				<label for="perRiwayat">Riwayat Penyakit</label>
				<b-form-textarea v-model="isParent.row.aka_riwayat_penyakit" :formatter="normalText" name="perRiwayat"
					id="perRiwayat" rows="2" class="form-control" placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
			</div>

			<VValidate name="Riwayat Penyakit" v-model="isParent.row.aka_riwayat_penyakit"
				:rules="toValidate(isParent.mrValidation.aka_riwayat_penyakit)" />
		</div>
		<div class="col-12">
			<div class="row g-3">
				<div class="col-lg-7">
					<div class="head_panel_red">
						<div class="d-flex justify-content-between align-items-center">
							<h3>ALERGI </h3>
							<div>
								<b-form-radio-group @input="changeAlergi($event)" :options="isParent.Config.mr.yesNoOpt"
									v-model="isParent.row.aka_has_alergi" />
								<VValidate name="Alergi" v-model="isParent.row.aka_has_alergi"
									:rules="toValidate(isParent.mrValidation.aka_has_alergi)" />
							</div>
						</div>
					</div>

					<template v-if="isParent.row.aka_has_alergi == 'Y'">
						<table class="table table-sm table-bordered">
							<thead>
								<tr>
									<th width="33%">Jenis</th>
									<th>Informasi Alergi</th>
									<th width="64"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in (isParent.row.aka_alergi||[])" :key="k+'aler'">
									<td>
										<v-select placeholder="Pilih Jenis" v-model="isParent.row.aka_alergi[k]['jenis']"
											:options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
										<VValidate :name="'Obat '+(k+1)" v-model="isParent.row.aka_alergi[k]['jenis']"
											:rules="{required:1}" />
									</td>
									<td>
										<vue-typeahead-bootstrap v-model="isParent.row.aka_alergi[k]['name']" :data="(isParent.mAlergi||[])"
											@input="searchAlergi(isParent.row.aka_alergi[k]['name'])" placeholder="Pilih Alergi" />

										<VValidate :name="'Obat '+(k+1)" v-model="isParent.row.aka_alergi[k]['name']"
											:rules="{required:1}" />
									</td>
									<td>
										<a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus"
											class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i
												class="icon-trash"></i></a>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="3" class="text-center">
										<a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i
												class="icon-plus2 mr-1"></i>Tambah</a>
									</td>
								</tr>
							</tfoot>
						</table>
					</template>
					<template v-else>
						<table class="table table-sm table-bordered">
							<thead>
								<tr>
									<th width="33%">Jenis</th>
									<th>Informasi Alergi</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text"
											:clearable="true" :reduce="v=>v.value"></v-select>
									</td>
									<td>
										<input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
									</td>
								</tr>
							</tbody>
						</table>
					</template>
				</div>

				<div class="col-lg-5">
					<div class="head_panel_blue">
						<div class="d-flex justify-content-between align-items-center">
							<h3>OBAT RUTIN </h3>
							<div>
								<b-form-radio-group :options="isParent.Config.mr.yesNoOpt" v-model="isParent.row.aka_has_obat_rutin" />
								<VValidate name="Alergi" v-model="isParent.row.aka_has_obat_rutin"
									:rules="toValidate(isParent.mrValidation.aka_has_obat_rutin)" />
							</div>
						</div>
					</div>

					<div class="row mt-2" v-if="isParent.row.aka_has_obat_rutin == 'Y'">
						<div class="col-md-12">
							<div class="form-group">
								<label>Keterangan </label>
								<b-form-textarea v-model="isParent.row.aka_keterangan_obat_rutin" rows="5" cols="3" class="form-control"
									placeholder="" spellcheck="false"></b-form-textarea>
							</div>

							<VValidate name="Keterangan" v-model="isParent.row.aka_keterangan_obat_rutin"
								:rules="toValidate(isParent.mrValidation.aka_keterangan_obat_rutin)" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card mb-0">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
				</div>
				<div class="card-body p-3">
					<div class="row">
						<div class="col-md-6 col-lg-4">
							<div class="form-group">
								<label>Tekanan Darah</label>
								<div class="input-group">
									<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_tekanan_darah_min" type="text"
										name="name" class="form-control" placeholder="Systole" />

									<div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

									<b-form-input :formatter="number" placeholder="Diastole"
										v-model="isParent.row.aka_ttv_tekanan_darah_max" type="text" class="form-control" />
									<div class="input-group-append"><span class="input-group-text">mmHG</span></div>
								</div>
								<VValidate name="Tekanan Darah Min" v-model="isParent.row.aka_ttv_tekanan_darah_min"
									:rules="toValidate(isParent.mrValidation.aka_ttv_tekanan_darah_min)" />
								<VValidate name="Tekanan Darah Max" v-model="isParent.row.aka_ttv_tekanan_darah_max"
									:rules="toValidate(isParent.mrValidation.aka_ttv_tekanan_darah_max)" />
							</div>
						</div>

						<div class="col-md-6 col-lg-5">
							<div class="form-group">
								<label>Nadi</label>
								<div class="form-row">
									<div class="col-md-12">
										<div class="input-group">
											<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_nadi" type="text"
												class="form-control" />
											<div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
											<div class="input-group-append">
												<div style="width: 140px;">
													<v-select placeholder="Pilih Label" v-model="isParent.row.aka_ttv_label"
														:options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value">
													</v-select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<VValidate name="Nadi" v-model="isParent.row.aka_ttv_nadi"
									:rules="toValidate(isParent.mrValidation.aka_ttv_nadi)" />
								<VValidate name="Label" v-model="isParent.row.aka_ttv_label"
									:rules="toValidate(isParent.mrValidation.aka_ttv_label)" />
							</div>
						</div>

						<div class="col-md-4 col-lg-3">
							<div class="form-group">
								<label>Gula Darah</label>
								<div class="form-row">
									<div class="col-md-12">
										<div class="input-group">
											<b-form-input :formatter="alphanum" v-model="isParent.row.aka_ttv_gula_darah" type="text"
												class="form-control" />
											<div class="input-group-append"><span class="input-group-text">mg/dL</span>
											</div>
										</div>
									</div>
								</div>

								<VValidate name="Gula Darah" v-model="isParent.row.aka_ttv_gula_darah"
									:rules="toValidate(isParent.mrValidation.aka_ttv_gula_darah)" />
							</div>
						</div>

						<div class="col-md-4">
							<div class="form-group">
								<label>Pernafasan</label>
								<div class="input-group">
									<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_pernafasan" type="text"
										class="form-control" />
									<div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
								</div>
							</div>

							<VValidate name="Pernafasan" v-model="isParent.row.aka_ttv_pernafasan"
								:rules="toValidate(isParent.mrValidation.aka_ttv_pernafasan)" />
						</div>

						<div class="col-md-3">
							<div class="form-group">
								<label>SPO2</label>
								<div class="form-row">
									<div class="col-md-12">
										<div class="input-group">
											<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_spo2" type="text"
												class="form-control" />
											<div class="input-group-append"><span class="input-group-text">%</span>
											</div>
										</div>
									</div>
								</div>

								<VValidate name="SPO2" v-model="isParent.row.aka_ttv_spo2"
									:rules="toValidate(isParent.mrValidation.aka_ttv_spo2)" />
							</div>
						</div>

						<div class="col-md-3 col-lg-2">
							<div class="form-group">
								<label>Suhu</label>
								<div class="form-row">

									<div class="col-md-12">
										<div class="input-group">
											<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_suhu" type="text"
												class="form-control" />
											<div class="input-group-append"><span class="input-group-text">C</span>
											</div>
										</div>
									</div>
								</div>
								<VValidate name="Suhu" v-model="isParent.row.aka_ttv_suhu"
									:rules="toValidate(isParent.mrValidation.aka_ttv_suhu)" />
							</div>
						</div>

						<div class="col-md-3 col-lg-2">
							<div class="form-group">
								<label>Berat Badan</label>
								<div class="form-row">

									<div class="col-md-12">
										<div class="input-group">
											<b-form-input @input="hitungBMI()" :formatter="number" v-model="isParent.row.aka_ttv_weight"
												type="text" class="form-control" />
											<div class="input-group-append"><span class="input-group-text">kg</span>
											</div>
										</div>
									</div>
								</div>
								<VValidate name="Berat Badan" v-model="isParent.row.aka_ttv_weight"
									:rules="toValidate(isParent.mrValidation.aka_ttv_weight)" />
							</div>
						</div>


						<div class="col-md-3 col-lg-2">
							<div class="form-group">
								<label>Tinggi</label>
								<div class="form-row">

									<div class="col-md-12">
										<div class="input-group">
											<b-form-input @input="hitungBMI()" :formatter="number" v-model="isParent.row.aka_ttv_height"
												type="text" class="form-control" />
											<div class="input-group-append"><span class="input-group-text">cm</span>
											</div>
										</div>
									</div>
								</div>

								<VValidate name="Tinggi Badan" v-model="isParent.row.aka_ttv_height"
									:rules="toValidate(isParent.mrValidation.aka_ttv_height)" />
							</div>
						</div>

						<div class="col-md-3">
							<div class="form-group">
								<label>Lingkar Kepala</label>
								<div class="form-row">

									<div class="col-md-12">
										<div class="input-group">
											<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_lingkar_kepala" type="text"
												class="form-control" />
											<div class="input-group-append"><span class="input-group-text">cm</span>
											</div>
										</div>
									</div>
								</div>
								<VValidate name="Lingkar Kepala" v-model="isParent.row.aka_ttv_lingkar_kepala"
									:rules="toValidate(isParent.mrValidation.aka_ttv_lingkar_kepala)" />
							</div>
						</div>

						<div class="col-md-3" v-if="isParent.row.ap_usia >= 1">
							<div class="form-group">
								<label>BMI</label>
								<div class="form-row">
									<div class="col-md-12">
										<div class="input-group">
											<b-form-input disabled v-model="isParent.row.aka_ttv_bmi" type="text" class="form-control" />
											<div class="input-group-append"><span class="input-group-text">m2</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 col-lg-3" v-if="isParent.row.ap_usia <= 15">
							<div class="form-group">
								<label>Luas Permukaan Tubuh Anak</label>
								<div class="input-group">
									<b-form-input :formatter="number" v-model="isParent.row.aka_ttv_luas_permukaan_anak" type="text"
										class="form-control" />
									<div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
								</div>
							</div>
						</div>

						<div class="col-md-5 col-lg-3">
							<div class="form-group">
								<label>Kesadaran</label>
								<div class="form-row">
									<div class="col-md-12">
										<v-select placeholder="Pilih Kesadaran" v-model="isParent.row.aka_ttv_kesadaran"
											:options="isParent.mKesadaran" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
									</div>
								</div>
								<VValidate name="Kesadaran" v-model="isParent.row.aka_ttv_kesadaran"
									:rules="toValidate(isParent.mrValidation.aka_ttv_kesadaran)" />
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- Status Kehamilan, muncul hanya jika jenis kelamin adalah perempuan -->
		<div class="col-12"
			v-if="(isParent.row.ap_gender == 2 || isParent.row.ap_gender == 0) && isParent.row.ap_usia >= 12 && isParent.row.ap_usia <= 60">
			<div class="card mb-0">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
				</div>
				<div class="card-body p-3">
					<div class="row g-3 gx-lg-6">
						<div class="col-lg-4 col-xl-3">
							<div class="d-flex d-lg-block">
								<div>
									<label for="perHamil">Pasien Sedang Hamil?<strong class="text-danger">*</strong></label>
	
									<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2" v-model="isParent.row.aka_is_hamil" />
									<VValidate name="Pasien Hamil" v-model="isParent.row.aka_is_hamil"
										:rules="toValidate(isParent.mrValidation.aka_is_hamil)" />
	
								</div>
								<div class="ml-3 mt-lg-3 ml-lg-0">
									<label for="perMenyusui">Pasien Menyusui?<strong class="text-danger">*</strong></label>
									<div>
										<b-form-radio-group :options="isParent.Config.mr.yesNoOptV2" v-model="isParent.row.aka_is_menyusui" />
										<VValidate name="Pasien Menyusui" v-model="isParent.row.aka_is_menyusui"
											:rules="toValidate(isParent.mrValidation.aka_is_menyusui)" />
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg border-left" v-if="isParent.row.aka_is_hamil == 'Y'">
							<div class="row g-2 mb-2">
								<div class="col-md-8">
									<label for="perRiwayat">Tinggi Fundus Uteri</label>
									<b-form-textarea v-model="isParent.row.aka_tinggi_fungus" :formatter="normalText" name="perRiwayat"
										id="perRiwayat" rows="2" class="form-control" placeholder="Tinggi Fundus Uteri"></b-form-textarea>
								</div>
							</div>

							<div class="row g-2">
								<div class="col-md-4">
									<div>
										<label for="perGravid">Gravid<strong class="text-danger">*</strong></label>
										<b-form-input :formatter="number" v-model="isParent.row.aka_gravid" type="text"
											class="form-control" />
										<VValidate name="Gravid" v-model="isParent.row.aka_gravid"
											:rules="toValidate(isParent.mrValidation.aka_gravid)" />
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<label for="perPara">Para<strong class="text-danger">*</strong></label>
										<b-form-input :formatter="number" v-model="isParent.row.aka_para" type="text"
											class="form-control" />
										<VValidate name="Para" v-model="isParent.row.aka_para"
											:rules="toValidate(isParent.mrValidation.aka_para)" />
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<label for="perAbortus">Abortus<strong class="text-danger">*</strong></label>
										<b-form-input :formatter="number" v-model="isParent.row.aka_abortus" type="text"
											class="form-control" />
										<VValidate name="Para" v-model="isParent.row.aka_abortus"
											:rules="toValidate(isParent.mrValidation.aka_abortus)" />
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<label for="perHpht">HPHT</label>
										<div class="input-group mb-3">
											<datepicker input-class="form-control transparent" placeholder="Pilih Tanggal" @input="changeHPL"
												class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.aka_hpht">
											</datepicker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<label for="perHpl">HPL</label>
										<div class="input-group mb-3">
											<datepicker input-class="form-control transparent" placeholder="Tanggal HPL" disabled
												class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.aka_hpl">
											</datepicker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
									<VValidate name="HPL" v-model="isParent.row.aka_hpl"
										:rules="toValidate(isParent.mrValidation.aka_hpl)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card mb-0">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Status Ekonomi</h6>
				</div>
				<div class="card-body p-3">
					<div class="row">
						<div class="col-md-6 col-lg-4">
							<div>
								<label for="perHamil">Pekerjaan Pasien<strong class="text-danger">*</strong></label>
								<v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.aka_pekerjaan"
									:options="isParent.mPekerjaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
							</div>

							<VValidate name="Pekerjaan Pasien" v-model="isParent.row.aka_pekerjaan" :rules="{required: 1}" />
						</div>
						<div class="col-lg-4" v-if="isParent.row.aka_pekerjaan == 99999">
							<label for="perHamil">Pekerjaan Lainnya<strong class="text-danger">*</strong></label>
							<b-form-input :formatter="normalText" v-model="isParent.row.aka_pekerjaan_lainnya" type="text"
								class="form-control" placeholder="Isi Pekerjaan Lainnya" />
							<VValidate name="Isi Pekerjaan Lainnya" v-model="isParent.row.aka_pekerjaan_lainnya"
								:rules="{required: 1, min: 2}" />
						</div>
						<div class="col-md">
							<div>
								<label for="perHub">Hubungan dengan Keluarga<strong class="text-danger">*</strong></label>
								<div>
									<b-form-radio-group :options="isParent.Config.mr.baikNoOpt"
										v-model="isParent.row.aka_hubungan_keluarga" />
									<VValidate name="Hubungan dengan Keluarga" v-model="isParent.row.aka_hubungan_keluarga"
										:rules="toValidate(isParent.mrValidation.aka_hubungan_keluarga)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="card mb-0">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Status Psikologi</h6>
				</div>
				<div class="card-body p-3">
					<div>
						<label for="perHub">Status Psikologis Pasien<strong class="text-danger">*</strong></label>
						<div>
							<b-form-radio v-for="v in isParent.Config.mr.psikologiPot" inline
								v-model="isParent.row.aka_status_psikologi" :key="v.value" :value="v.value"
								@input="inputPsikolog($event)">{{ v.text }}</b-form-radio>
							<div class="align-middle d-inline-block mt-2 mt-lg-0" v-if="isParent.row.aka_status_psikologi == 6">
								<b-form-input :formatter="normalText" v-model="isParent.row.aka_status_psikologi_text" type="text"
									class="form-control" placeholder="Isi psikologis pasien" />
								<VValidate name="Psikologi Pasien Lainnya" v-model="isParent.row.aka_status_psikologi_text"
									:rules="toValidate(isParent.mrValidation.aka_status_psikologi_text)" />
							</div>

							<VValidate name="Psikologi Pasien" v-model="isParent.row.aka_status_psikologi"
								:rules="toValidate(isParent.mrValidation.aka_status_psikologi)" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<div class="card mb-0">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Status Fungsional</h6>
				</div>
				<div class="card-body p-3">
					<div class="row gx-5">
						<div class="col-md-4 col-lg-auto">
							<div>
								<label for="perHub">Alat Bantu<strong class="text-danger">*</strong></label>
								<div>
									<b-form-radio-group :options="Config.mr.yesNoOptV2" v-model="isParent.row.aka_is_alat_bantu" />

									<v-select class="mt-3" v-if="isParent.row.aka_is_alat_bantu == 'Y'" placeholder="Pilih Alat Bantu"
										v-model="isParent.row.aka_alat_bantu" :options="isParent.mAlatBantu" label="text" :clearable="true"
										:reduce="v=>v.value"></v-select>

									<VValidate v-if="isParent.row.aka_is_alat_bantu == 'Y'" name="Nama Alat Bantu"
										v-model="isParent.row.aka_alat_bantu" :rules="toValidate(isParent.mrValidation.aka_alat_bantu)" />
								</div>
								<VValidate name="Alat Bantu" v-model="isParent.row.aka_is_alat_bantu"
									:rules="toValidate(isParent.mrValidation.aka_is_alat_bantu)" />
							</div>
						</div>
						<div class="col-md-auto border-left">
							<div>
								<label for="perHub">ADL (Aktifitas Sehari-hari)<strong class="text-danger">*</strong></label>
								<div>
									<b-form-radio-group :options="Config.mr.adlStatus" v-model="isParent.row.aka_adl" />
								</div>
							</div>

							<VValidate name="Aktifitas Sehari-hari" v-model="isParent.row.aka_adl"
								:rules="toValidate(isParent.mrValidation.aka_adl)" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card mb-0">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Kajian TBC</h6>
				</div>
				<div class="card-body p-3">
					<div class="form-group">
						<label for="tbcCheck">Pasien Mengalami TBC?<strong class="text-danger">*</strong></label>
						<div>
							<b-form-radio-group :options="isParent.Config.mr.yesNoOpt" v-model="isParent.row.aka_is_tbc" />
							<VValidate name="TBC" v-model="isParent.row.aka_is_tbc" :rules="{required: 1}" />
						</div>
					</div>
					<div id="tbcFormCheck" v-if="isParent.row.aka_is_tbc == 'Y'">
						<div class="row">
							<div class="col-md-4 col-lg-2">
								<div class="form-group">
									<label for="tbcUmum">Kondisi Umum <strong class="text-danger">*</strong></label>
									<v-select v-model="isParent.row.aka_tb_kondisi_umum" placeholder="Pilih Kondisi Umum"
										:options="Config.mr.configStatusKondisi" label="text" :clearable="true" :reduce="v=>v.value">
									</v-select>
								</div>
								<VValidate name="Kondisi Umum" v-model="isParent.row.aka_tb_kondisi_umum" :rules="{required: 1}" />
							</div>
							<div class="col-lg-8">
								<div class="form-group">
									<label for="">Glasgow Coma Scale (GSC) <strong class="text-danger">*</strong></label>
									<div class="form-row">
										<div class="col-md-12 mb-1">
											<div class="input-group">
												<div class="input-group-prepend"><span class="input-group-text">E</span>
												</div>
												<div class="form-control p-0 border-0">
													<v-select v-model="isParent.row.aka_tb_gsc_e" placeholder="Pilih Glasgow Coma Scale (GSC)E"
														:options="Config.mr.configStatusGSCE" label="text" :clearable="true" :reduce="v=>v.value">

														<template slot="selected-option" slot-scope="option">
															<span v-b-tooltip.hover.right>
																{{option.text||"-"}}
															</span>
														</template>

														<template slot="option" slot-scope="option">
															<span v-b-tooltip.hover.bottom>{{ option.text }}</span>
														</template>
													</v-select>
												</div>
											</div>
											<VValidate name="E" v-model="isParent.row.aka_tb_gsc_e" :rules="{required: 1}" />
										</div>
										<div class="col-md-12 mb-1">
											<div class="input-group">
												<div class="input-group-prepend"><span class="input-group-text">V</span>
												</div>
												<div class="form-control p-0 border-0">
													<v-select v-model="isParent.row.aka_tb_gsc_v" placeholder="Pilih Glasgow Coma Scale (GSC)V"
														:options="Config.mr.configStatusGSCV" label="text" :clearable="true" :reduce="v=>v.value">

														<template slot="selected-option" slot-scope="option">
															<span v-b-tooltip.hover.right>
																{{option.text||"-"}}
															</span>
														</template>

														<template slot="option" slot-scope="option">
															<span v-b-tooltip.hover.bottom>{{ option.text }}</span>
														</template>
													</v-select>
												</div>
											</div>
											<VValidate name="V" v-model="isParent.row.aka_tb_gsc_v" :rules="{required: 1}" />
										</div>
										<div class="col-md-12 mb-1">
											<div class="input-group">
												<div class="input-group-prepend"><span class="input-group-text">M</span>
												</div>
												<div class="form-control p-0 border-0">
													<v-select v-model="isParent.row.aka_tb_gsc_m" placeholder="Pilih Glasgow Coma Scale (GSC)M"
														:options="Config.mr.configStatusGSCM" label="text" :clearable="true" :reduce="v=>v.value">

														<template slot="selected-option" slot-scope="option">
															<span v-b-tooltip.hover.right>
																{{option.text||"-"}}
															</span>
														</template>

														<template slot="option" slot-scope="option">
															<span v-b-tooltip.hover.bottom>{{ option.text }}</span>
														</template>
													</v-select>
												</div>
											</div>
											<VValidate name="M" v-model="isParent.row.aka_tb_gsc_m" :rules="{required: 1}" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="wrap_line">
							<h3>Kepala</h3>
							<div class="row g-2">
								<div class="col-md-6 col-lg-3 col-xl-2">
									<div>
										<label for="headKonjungtiva">Konjungtiva</label>
										<v-select v-model="isParent.row.aka_tb_kep_konjungtiva" placeholder="Pilih Konjungtiva"
											:options="Config.mr.configStatusKonjungtiva" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
										<VValidate name="Konjungtiva" v-model="isParent.row.aka_tb_kep_konjungtiva"
											:rules="{required: 1}" />
									</div>
								</div>
								<div class="col-md-6 col-lg-3 col-xl-2">
									<div>
										<label for="Sianosis">Sianosis</label>

										<v-select v-model="isParent.row.aka_tb_kep_sianosis" placeholder="Pilih Sianosis"
											:options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
										<VValidate name="Sianosis" v-model="isParent.row.aka_tb_kep_sianosis" :rules="{required: 1}" />

									</div>
								</div>
								<div class="col-md-6 col-lg-3 col-xl-2">
									<div>
										<label for="Sklera">Sklera</label>
										<v-select v-model="isParent.row.aka_tb_kep_sklera" placeholder="Pilih Sklera"
											:options="Config.mr.configStatusSklera" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
										<VValidate name="Sklera" v-model="isParent.row.aka_tb_kep_sklera" :rules="{required: 1}" />
									</div>
								</div>
								<div class="col-md-6 col-lg-3">
									<div>
										<label for="Hidung">Hidung</label>
										<v-select v-model="isParent.row.aka_tb_kep_hidung" placeholder="Pilih Hidung"
											:options="Config.mr.configStatusHidung" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
										<VValidate name="Hidung" v-model="isParent.row.aka_tb_kep_hidung" :rules="{required: 1}" />
									</div>
								</div>
							</div>
						</div>
						<div class="wrap_line">
							<h3>Thorax / Dada</h3>
							<div class="row g-2">
								<div class="col-md-6 col-lg-3">
									<div>
										<label for="thoraxParu">Paru</label>
										<v-select v-model="isParent.row.aka_tb_trx_paru" placeholder="Pilih Dada"
											:options="Config.mr.configStatusParu" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
										<div class="mt-1" v-if="isParent.row.aka_tb_trx_paru == 'Lain-lain'">
											<input type="text" :formatter="normalText" v-model="isParent.row.aka_tb_trx_paru_lainnya"
												class="form-control">

											<VValidate name="Paru Lainnya" v-model="isParent.row.aka_tb_trx_paru_lainnya"
												:rules="{required: 1, min :3}" />
										</div>
									</div>
									<VValidate name="Paru" v-model="isParent.row.aka_tb_trx_paru" :rules="{required: 1}" />
								</div>

								<div class="col-md-6 col-lg-3 col-xl-2">
									<div>
										<label for="thoraxRespirasi">Respirasi</label>
										<v-select v-model="isParent.row.aka_tb_trx_respirasi" placeholder="Pilih Respirasi"
											:options="Config.mr.configStatusRespirasi" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
									</div>

									<VValidate name="Respirasi" v-model="isParent.row.aka_tb_trx_respirasi" :rules="{required: 1}" />
								</div>
								<div class="col-md-6 col-lg-3 col-xl-2">
									<div>
										<label for="thoraxAlatBantuNafas">Alat Bantu Nafas</label>
										<v-select v-model="isParent.row.aka_tb_trx_alat_bantu_nafas" placeholder="Pilih Alat Bantu Nafas"
											:options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
									</div>

									<VValidate name="Alat Bantu Nafas" v-model="isParent.row.aka_tb_trx_alat_bantu_nafas"
										:rules="{required: 1}" />
								</div>
								<div class="col-md-6 col-lg-3">
									<div>
										<label for="thoraxJantung">Jantung</label>

										<v-select v-model="isParent.row.aka_tb_trx_jantung" placeholder="Pilih Jantung"
											:options="Config.mr.configStatusJantung" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>

										<div class="mt-1" v-if="isParent.row.aka_tb_trx_jantung=='Lain-lain'">
											<input type="text" v-model="isParent.row.aka_tb_trx_jantung_lainnya" class="form-control">

											<VValidate name="Jantung Lainnya" v-model="isParent.row.aka_tb_trx_jantung_lainnya"
												:rules="{required: 1, min: 3}" />
										</div>
									</div>

									<VValidate name="Jantung" v-model="isParent.row.aka_tb_trx_jantung" :rules="{required: 1}" />
								</div>
							</div>
						</div>
						<div class="wrap_line">
							<h3>Abdomen</h3>
							<div class="row">
								<div class="col-md-4 col-lg-3 col-xl-2">
									<div class="form-group">
										<label for="abdNyeriTekanParu">Nyeri Tekan</label>
										<v-select v-model="isParent.row.aka_tb_abd_nyeri" placeholder="Pilih Nyeri"
											:options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
									</div>
									<VValidate name="Nyeri" v-model="isParent.row.aka_tb_abd_nyeri" :rules="{required: 1}" />
								</div>
								<div class="col-md-4 col-lg-3 col-xl-2">
									<div class="form-group">
										<label for="abdBisingUsus">Bising Usus</label>
										<v-select v-model="isParent.row.aka_tb_abd_bising_usus" placeholder="Pilih Nyeri"
											:options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>
									</div>
									<VValidate name="Bising Usus" v-model="isParent.row.aka_tb_abd_bising_usus" :rules="{required: 1}" />
								</div>

								<div class="col-md-8 col-lg-6">
									<div>
										<label for="abdKonAbdomen">Kondisi Abdomen</label>
										<div class="form-row">
											<div class="col-md-6">
												<v-select v-model="isParent.row.aka_tb_abd_kondisi" placeholder="Pilih Kondii Abdomen"
													:options="Config.mr.configKondisiAbdomen" label="text" :clearable="true" :reduce="v=>v.value">
												</v-select>
											</div>
											<div class="col-md-6" v-if="isParent.row.aka_tb_abd_kondisi=='Lain-lain'">
												<input type="text" v-model="isParent.row.aka_tb_abd_kondisi_lainnya" class="form-control">

												<VValidate name="Kondisi Lainnya" v-model="isParent.row.aka_tb_abd_kondisi_lainnya"
													:rules="{required: 1, min: 3}" />
											</div>

											<VValidate name="Kondisi Abdomen" v-model="isParent.row.aka_tb_abd_kondisi"
												:rules="{required: 1}" />

										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="wrap_line">
							<h3>Ekstremitas</h3>
							<div class="row">
								<div class="col-md-4 col-lg-3 col-xl-2">
									<div>
										<label for="ekstremitasEdema">Edema</label>
										<v-select v-model="isParent.row.aka_tb_eks_edema" placeholder="Pilih Edema"
											:options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>

										<VValidate name="Edema" v-model="isParent.row.aka_tb_eks_edema" :rules="{required: 1}" />
									</div>
								</div>
								<div class="col-md-4 col-lg-3 col-xl-2">
									<div>
										<label for="ekstremitasAkaral">Akaral</label>
										<v-select v-model="isParent.row.aka_tb_eks_akaral" placeholder="Pilih Akaral"
											:options="Config.mr.configAkaral" label="text" :clearable="true" :reduce="v=>v.value">
										</v-select>

										<VValidate name="Akaral" v-model="isParent.row.aka_tb_eks_akaral" :rules="{required: 1}" />
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label for="pemeriksaanTCM">Hasil Pemeriksaan TCM</label>
									<b-form-textarea v-model="isParent.row.aka_hasil_tcm" :formatter="normalText" name="perRiwayat"
										id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan TCM"></b-form-textarea>
									<VValidate name="Hasil Pemeriksaan TCM" v-model="isParent.row.aka_hasil_tcm"
										:rules="{required: 1, min: 3, max: 128}" />
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="pemeriksaanTCM">Hasil Pemeriksaan BTA</label>
									<b-form-textarea v-model="isParent.row.aka_hasil_bta" :formatter="normalText" name="perRiwayat"
										id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan BTA"></b-form-textarea>
									<VValidate name="Hasil Pemeriksaan BTA" v-model="isParent.row.aka_hasil_bta"
										:rules="{required: 1, min: 3, max: 128}" />
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="pemeriksaanTCM">Hasil Pemeriksaan Rontgen</label>
									<b-form-textarea v-model="isParent.row.aka_hasil_rontgen" :formatter="normalText" name="perRiwayat"
										id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan Rontgen">
									</b-form-textarea>
									<VValidate name="Hasil Pemeriksaan Rontgen" v-model="isParent.row.aka_hasil_rontgen"
										:rules="{required: 1, min: 3, max: 128}" />
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="pemeriksaanTCM">Hasil Pemeriksaan PA / Mantoux Test</label>
									<b-form-textarea v-model="isParent.row.aka_hasil_test" :formatter="normalText" name="perRiwayat"
										id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan PA / Mantoux Test">
									</b-form-textarea>
									<VValidate name="Hasil Pemeriksaan PA / Mantoux Test" v-model="isParent.row.aka_hasil_test"
										:rules="{required: 1, min: 3, max: 128}" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

export default{
    extends: GlobalVue,
    components:{ 
        Datepicker
    },
    methods: {
        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+'RekamMedis',
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.mAlergi = res.data.data            
          })
        }, 100),

        inputPsikolog(e){
            if(this.isParent.row.aka_status_psikologi == 6){
                this.isParent.row.aka_status_psikologi_text = ''
            }
        },
        changeAlergi(e){
            if(!(this.isParent.row.aka_alergi||[]).length){
                this.addAlergi()
            }
        },
        hitungBMI(){
            if(this.isParent.row.aka_ttv_weight && this.isParent.row.aka_ttv_height){
                this.isParent.row.aka_ttv_bmi = this.isParent.row.aka_ttv_weight / ((this.isParent.row.aka_ttv_height/100)*(this.isParent.row.aka_ttv_height/100))
                this.isParent.row.aka_ttv_bmi = this.isParent.row.aka_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.aka_ttv_bmi = null 
            }
        },
        toValidate(val){
            return {...val}
        },
        addAlergi(){
            this.isParent.row.aka_alergi.push({
                name : '',
                jenis : null,
            })
            this.addMasterAlergi()
        },
        addMasterAlergi(){
            for(let i = 0; i < (this.isParent.row.aka_alergi||[]).length; i++){
                let newData = this.isParent.row.aka_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+'RekamMedis',
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        removeAlergi(k){
            this.isParent.row.aka_alergi.splice(k,1)
        },
        changeHPL(){
            let dateHPL = moment(this.isParent.row.aka_hpht, "DD-MM-YYYY").format('YYYY-M-D')
            let splDate = dateHPL.split("-")
            let day = splDate[2]
            let month = splDate[1]
            let year = splDate[0]

            day = +day + 7
            if(month == 1){
                month = 10
            }else if(month == 2){
                month = 11            
            }else if(month == 3){
                month = 12
            }else{
                month = +month - 3
            }
            year = +year + 1
            
            let daysOfMonth = moment(+year+"-"+month+"-01").endOf("month").format("D")
            daysOfMonth = +daysOfMonth
            
            let newDay
            
            if(day > daysOfMonth){
                newDay = day - daysOfMonth
                month = month + 1
            }else{
                newDay = day
            }

            let newdateHPL = moment((newDay+'-'+month+'-'+year), "DD-MM-YYYY").format('YYYY-MM-DD')
          
            this.isParent.row.aka_hpl = newdateHPL
        }
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    },
}
</script>
